<template>
    <div class="relative">
        <button class="relative p-1" @click.stop="active = !active">
            <div
                :class="hasUnreadMessages ? 'bg-green-500' : 'bg-red-500'"
                class="w-1.5 h-1.5 absolute top-0.5 right-1 rounded-full"
            ></div>
            <custom-icon :h="21" :w="21" icon="notifications"/>
        </button>

        <div
            v-show="active"
            v-click-outside="() => active && closeDropdown()"
            class="absolute top-full pt-3 left-1/2 transform -translate-x-1/2 rounded-xl z-20"
        >
            <div
                class="py-6 px-4 shadow border-gray-200 text-sm flex flex-col bg-white rounded-lg w-screen max-w-md"
            >
                <div class="whitespace-nowrap font-bold text-lg">
                    Wiadomości
                </div>

                <div class="flex mt-3 gap-4 border-b border-gray-200">
                    <!-- temporary hidden -->
                    <!-- <button
                        @click="type = 'mail'"
                        class="relative pb-3"
                        :class="
                            type === 'mail' ? 'text-blue-600' : 'text-gray-400'
                        "
                    >
                        Mail
                        <div
                            class="absolute -bottom-px rounded left-0 w-full h-0.5 bg-blue-600 transition-opacity"
                            :class="
                                type === 'mail' ? 'opacity-100' : 'opacity-0'
                            "
                        ></div>
                    </button> -->

                    <button
                        :class="
                            type === 'sms' ? 'text-blue-600' : 'text-gray-400'
                        "
                        class="relative pb-3"
                        @click="type = 'sms'"
                    >
                        SMS
                        <div
                            :class="
                                type === 'sms' ? 'opacity-100' : 'opacity-0'
                            "
                            class="absolute -bottom-px rounded left-0 w-full h-0.5 bg-blue-600 transition-opacity"
                        ></div>
                    </button>
                </div>

                <div>
                    <!-- temporary hidden -->
                    <!-- <notifications-mail v-if="type === 'mail'" /> -->
                    <notifications-sms v-if="type === 'sms'"/>
                </div>
            </div>
        </div>

        <div
            v-show="newChatFrom"
            class="absolute -bottom-16 left-1/2 transform -translate-x-1/2 p-2 whitespace-nowrap pr-6 shadow-lg border bg-white border-gray-300 flex gap-2 rounded-xl z-30"
        >
            <div
                class="min-w-[40px] h-10 flex justify-center items-center border rounded-lg border-blue-500"
            >
                <custom-icon icon="envelope"></custom-icon>
            </div>

            <div class="text-xs">
                <h5>Otrzymano nową wiadomość od:</h5>
                <p class="text-base font-bold">{{ newChatFrom }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import vClickOutside from "click-outside-vue3"

import CustomIcon from "@/elements/v1/CustomIcon";
import NotificationsMail from "@/components/v1/Navigation/NotificationsMail";
import NotificationsSms from "@/components/v1/Navigation/NotificationsSms";
import BroadcastingService from "../../../services/BroadcastingService";

export default {
    mixins: [
        {
            name: "NotificationsDropdown",
            data: () => ({
                active: false,
                type: "sms",
                newChatFrom: null,
            }),
            components: {
                CustomIcon,
                NotificationsMail,
                NotificationsSms,
            },
            directives: {
                clickOutside: vClickOutside.directive
            },
            created() {
                if (!this.unreadCount) {
                    this.getUnreadCount();
                }
            },
            mounted() {
                this.getSmses();
                this.watchNotifications();
            },
            computed: {
                ...mapGetters("sms", ["chats", "unreadCount"]),
                hasUnreadMessages() {
                    return this.unreadCount;
                },
            },
            beforeUnmount() {
                BroadcastingService.getBroadcaster()
                    ?.private(`smsChannel.${window.tenant?.id}`)
                    .stopListening("SmsIncoming");
                    BroadcastingService.getBroadcaster().leaveChannel(`private:smsChannel.${window.tenant?.id}`);
            },
            methods: {
                ...mapActions("sms", ["getSmses", "getUnreadCount"]),
                closeDropdown() {
                    this.active = false;
                    this.type = "sms";
                },
                watchNotifications() {
                    BroadcastingService.getBroadcaster()
                        ?.private(`smsChannel.${window.tenant?.id}`)
                        .listen("SmsIncoming", async (event) => {
                            console.log("SmsIncoming", event);
                            this.getUnreadCount();
                            const newChat = this.chats.get(1)?.[0];

                            if (!this.newChatFrom && (newChat || event.from)) {
                                this.newChatFrom =
                                    newChat?.patient?.name || event.from;

                                setTimeout(() => {
                                    this.newChatFrom = null;
                                }, 4000);
                            }
                        });
                },
            },
        },
    ],
};
</script>
