<template>
    <router-link :type="typeNative" :to="to" :class="classObject" v-if="to">
        <slot></slot>
    </router-link>
    <button :type="typeNative" :class="classObject" v-else>
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "CustomButton",
    props: {
        type: {
            type: String,
            require: false,
            default: "empty",
        },
        typeNative: {
            type: String,
            require: false,
            default: "button",
        },
        to: {
            type: String,
            require: false,
        },
    },

    computed: {
        classObject() {
            return `btn btn--${this.type}`;
        },
    },
};
</script>
