<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.336"
        height="15.336"
        viewBox="0 0 15.336 15.336"
        class="opacity-80"
    >
        <path
            d="M14.273.497a1.693 1.693 0 0 0-2.4 0l-.911.908 2.963 2.963.911-.911a1.693 1.693 0 0 0 0-2.4ZM5.218 7.155a1.672 1.672 0 0 0-.409.663l-.9 2.687a.73.73 0 0 0 .176.744.721.721 0 0 0 .744.176l2.687-.9a1.752 1.752 0 0 0 .663-.409l5.063-5.066-2.961-2.961ZM2.905 1.777A2.906 2.906 0 0 0 0 4.683v7.748a2.906 2.906 0 0 0 2.905 2.905h7.748a2.906 2.906 0 0 0 2.905-2.905V9.525a.969.969 0 0 0-1.937 0v2.905a.967.967 0 0 1-.968.968H2.905a.967.967 0 0 1-.968-.968V4.683a.967.967 0 0 1 .968-.968h2.906a.969.969 0 1 0 0-1.937Z"
            fill="currentColor"
        />
    </svg>
</template>
