<template>
    <p class="text-xs sm:text-sm font-semibold mb-1.5 sm:mb-3 text-blue-500">
        <slot></slot>
    </p>
</template>

<script>
export default {
    name: "CustomLabel",
};
</script>
