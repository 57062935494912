<template>
    <div class="mt-4 space-y-2.5 max-h-[60vh] overflow-y-auto">
        <div
            v-for="(message, key) in messages"
            :key="key"
            @click="markAsRead(message.id)"
        >
            <router-link
                :to="`/messages/sms/?message_phone=${message.phone}`"
                class="cursor-pointer p-2 pr-3 rounded-2xl group flex items-center w-full justify-between gap-2"
                :class="
                    !message.isRead
                        ? 'bg-blue-600 border border-transparent text-white'
                        : 'bg-gray-100'
                "
            >
                <div class="flex items-center gap-2">
                    <div
                        class="min-w-[36px] h-9 rounded-xl flex justify-center items-center"
                        :class="
                            !message.isRead
                                ? 'bg-white/10 text-white'
                                : 'bg-gray-300 text-blue-600 bg-opacity-50'
                        "
                    >
                        <custom-icon icon="envelope"></custom-icon>
                    </div>
                    <div>
                        <h5 class="text-xs truncate">
                            Otrzymano nową wiadomość od
                        </h5>
                        <strong class="font-bold">{{
                            message.patient
                                ? message.patient.name
                                : message.phone
                        }}</strong>
                    </div>
                </div>

                <div class="flex items-center gap-3">
                    <time class="font-light text-sm text-gray-500">
                        {{ formatDate(message.updatedAt) }}
                    </time>

                    <div
                        class="w-1.5 h-1.5 bg-red-500 rounded-full"
                        :class="!message.isRead ? '' : 'opacity-0'"
                    ></div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import NotificationsSms from "@/components/v1/Navigation/NotificationsSms";

import CustomIcon from "@/elements/v2/CustomIcon";

export default {
    mixins: NotificationsSms.mixins,
};
</script>
