<template>
    <div class="flex items-center border border-gray rounded-lg sm:rounded-2xl">
        <div
            v-if="showDatepicker"
            class="datepicker relative flex items-center px-3 md:pr-3 lg:pr-5 sm:px-5 h-10 sm:h-12"
            @click="() => toggle('dateActive', true)"
            v-click-outside="() => toggle('dateActive', false)"
        >
            <div
                class="flex items-center gap-1 cursor-pointer text-xs sm:text-base"
            >
                <span
                    class="text-gray-500 flex items-center gap-1.5 leading-none"
                >
                    <icon
                        class="transform -translate-y-[1px] sm:translate-y-0"
                        icon="calendar"
                    />
                    Data:
                </span>
                <div class="w-20">{{ date || currentDate }}</div>
            </div>

            <date-picker
                class="absolute top-10 sm:top-[46px] left-0 shadow-xl rounded-2xl border border-gray overflow-hidden z-10"
                v-show="dateActive"
                v-model="date"
                :single-date="true"
                @onChange="changeDate"
            />
        </div>
        <template v-if="showTimepicker">
            <div class="h-4 sm:h-7 w-[1px] bg-blue-500 bg-opacity-10"></div>
            <div
                class="timepicker relative flex items-center px-3 md:pl-3 lg:pl-5 sm:px-5 h-10 sm:h-12"
                @click="() => toggle('timeActive', true)"
                v-click-outside="() => toggle('timeActive', false)"
            >
                <div
                    class="flex items-center gap-1 cursor-pointer text-xs sm:text-base leading-none"
                >
                    <span
                        class="text-gray-500 flex items-center gap-1.5 leading-none"
                    >
                        <icon
                            class="transform -translate-y-[1px] sm:translate-y-0"
                            icon="clock"
                        />
                        Godzina:
                    </span>
                    <span
                        class="whitespace-nowrap"
                        v-show="
                            time.HH ||
                            (currentTime.HH && time.mm) ||
                            currentTime.mm
                        "
                    >
                        {{ time.HH || currentTime.HH }}:{{
                            time.mm || currentTime.mm
                        }}
                    </span>
                </div>

                <div
                    class="absolute top-10 sm:top-12 left-0 shadow-xl rounded-2xl border border-gray overflow-hidden"
                >
                    <time-picker
                        v-show="timeActive"
                        v-model="time"
                        format="HH:mm"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import "@lazy-copilot/datetimepicker/dist/datetimepicker.css";
import {
    DatePicker,
    TimePicker,
} from "@lazy-copilot/datetimepicker/dist/datetimepicker.common.js";

import dayjs from "dayjs";
import Icon from "@/elements/v1/CustomIcon";
import vClickOutside from "click-outside-vue3";

export default {
    mixins: [
        {
            name: "CustomDatepicker",
            components: {
                DatePicker,
                TimePicker,
                Icon,
            },
            data: () => ({
                time: {
                    HH: "",
                    mm: "",
                },
                timeActive: false,
                date: null,
                isoDate: null,
                dateActive: false,
                startDate: "",
                currentTime: {
                    HH: "",
                    mm: "",
                },
                currentDateSendFormat: null,
                currentDate: null,
            }),
            directives: {
                clickOutside: vClickOutside.directive
            },

            props: {
                name: {
                    type: String,
                    default: "date",
                },
                showTimepicker: {
                    type: Boolean,
                    default: true,
                },
                showDatepicker: {
                    type: Boolean,
                    default: true,
                },
                form: Object,
                changeOnInit: {
                    type: Boolean,
                    default: true,
                },
                timeInput: Object,
                dateInput: [String, Date],
                mode: String,
            },
            beforeUpdate() {
                if (this.mode === "edit" && this.timeInput && this.dateInput) {
                    const time = this.timeInput;
                    this.currentTime = { HH: time?.HH, mm: time?.mm };
                    const date = this.dateInput
                        ? new Date(this.dateInput)
                        : new Date();
                    this.currentDate = dayjs(date).format("DD/MM/YYYY");
                    this.currentDateSendFormat =
                        dayjs(date).format("YYYY-MM-DD");
                    this.$emit("update-full-date", {
                        key: this.name,
                        value: `${this.currentDateSendFormat} ${this.currentTime.HH}:${this.currentTime.mm}`,
                    });
                }
            },
            mounted() {
                if (this.changeOnInit) {
                    const today = new Date();
                    this.date = this.convertDateToDisplay(today);
                    this.isoDate = today.toISOString();
                    this.change();
                }
            },
            computed: {
                mutatedDate() {
                    return this.dateInput
                        ? dayjs(this.dateInput).format("DD/MM/YYYY")
                        : null;
                },
                mutatedDateCalendar() {
                    return this.dateInput
                        ? new Date(dayjs(this.dateInput).format("YYYY-MM-DD"))
                        : null;
                },
            },
            methods: {
                toggle(field, bool) {
                    this[field] = bool;
                },
                changeDate(data) {
                    const date = new Date(data.selectedDay);
                    this.date = this.convertDateToDisplay(date);
                    this.isoDate = date.toISOString();
                    this.change();
                },
                convertDateToDisplay(date) {
                    const day = date.getDate();
                    const month = date.getMonth() + 1;
                    const year = date.getFullYear();
                    const fixedMonth = month < 10 ? `0${month}` : month;
                    return `${day}/${fixedMonth}/${year}`;
                },
                change() {
                    this.$emit("update-date", {
                        key: this.name,
                        value: this.isoDate,
                    });
                },
            },
            watch: {
                time(val) {
                    if (val.HH && val.mm) {
                        this.$emit(
                            this.name === "startTime"
                                ? "update-time"
                                : "update-date",
                            {
                                key: this.name,
                                value: `${val.HH}:${val.mm}`,
                            }
                        );
                    }
                },
            },
        },
    ],
};
</script>

<style>
.vue .display-time {
    opacity: 0;
    height: 0px;
}

.time .time-picker {
    height: auto;
}

.time .time-picker .dropdown {
    position: relative;
    display: block !important;
    margin-top: -22px;
}
.datepicker .calendar-header h3 {
    width: 200px;
    text-align: center;
    max-width: 43vw;
}

.datepicker .calendar-header,
.datepicker .datePicker_wrap {
    background-color: white;
}

.datepicker ul.calendar {
    padding-bottom: 12px;
}

.datepicker ul.calendar li.day span.innerStartDate:hover,
.datepicker ul.calendar li.day span.innerStartDate:hover,
.datepicker ul.calendar li.day span.innerStartDate {
    background-color: #0e073d;
}

.datepicker ul.calendar li.day span.today {
    box-shadow: inset 0 0 0 2px #0e073d;
}

.datepicker ul.calendar li.day span:hover {
    background-color: rgb(188, 188, 188);
}

.datepicker .datePicker_wrap .calendar-header span.arrow:hover {
    background-color: #0e073d;
}

.timepicker .display-time {
    opacity: 0;
    height: 0px;
    visibility: hidden;
}

.timepicker .time-picker {
    height: auto;
}

.timepicker .time-picker .dropdown {
    position: relative;
    display: block !important;
    margin-top: -22px;
}

.timepicker .time-picker .hint,
.timepicker .time-picker-overlay {
    display: none;
}

.timepicker .time-picker .dropdown .select-list ul li.active:hover,
.timepicker .time-picker .dropdown .select-list ul li.active {
    background: #0e073d;
    color: white;
}
</style>
