<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11.54"
        height="13.188"
        viewBox="0 0 11.54 13.188"
    >
        <path
            d="M7.418.824v.824h-3.3V.824a.824.824 0 1 0-1.649 0v.824H1.236A1.237 1.237 0 0 0 0 2.885v1.236h11.54V2.885a1.236 1.236 0 0 0-1.24-1.236H9.067V.824a.824.824 0 0 0-1.649 0Zm4.122 4.122H0v7.006a1.237 1.237 0 0 0 1.236 1.236H10.3a1.237 1.237 0 0 0 1.236-1.236ZM9.891 7.83a.413.413 0 0 1-.412.412h-.824a.413.413 0 0 1-.412-.412v-.824a.413.413 0 0 1 .412-.412h.824a.413.413 0 0 1 .412.412Zm-3.3 0a.413.413 0 0 1-.412.412h-.821a.413.413 0 0 1-.412-.412v-.824a.413.413 0 0 1 .412-.412h.824a.413.413 0 0 1 .412.412ZM2.885 6.594a.413.413 0 0 1 .412.412v.824a.413.413 0 0 1-.412.412h-.824a.413.413 0 0 1-.412-.412v-.824a.413.413 0 0 1 .412-.412Zm7.006 4.533a.413.413 0 0 1-.412.412h-.824a.413.413 0 0 1-.412-.412V10.3a.413.413 0 0 1 .412-.412h.824a.413.413 0 0 1 .412.412ZM6.182 9.891a.413.413 0 0 1 .412.412v.824a.413.413 0 0 1-.412.412h-.824a.413.413 0 0 1-.412-.412V10.3a.413.413 0 0 1 .412-.412ZM3.3 11.127a.413.413 0 0 1-.412.412h-.827a.413.413 0 0 1-.412-.412V10.3a.413.413 0 0 1 .412-.412h.824a.413.413 0 0 1 .415.412Z"
            fill="currentColor"
        />
    </svg>
</template>
