import * as api from "@/api";
import { callStatus } from "@/lib/constants";
import { debug } from "../../lib/utils";

export const VoipActions = {
    GET_CALLS: "getCalls",
    GET_CALL: "getCall",
    SET_CURRENT_CALL: "setCurrentCall",
    SET_ONGOING_CALL: "setOngoingCall",
    CHANGE_PAGE: "changePage",
    UPDATE_CALL: "updateCall",
    CLEAN_CALLS: "cleanCalls",
    REFETCH_CALLS: "refetchCalls",
    ADD_FILTER: "addFilter",
    REMOVE_FILTER: "removeFilter",
    CLEAN_FILTERS: "cleanFilters",
    SEARCH_CALLS: "searchCalls",
    CHECK_PARAMS: "checkParams",
    CALL_IS_ANSWERED: "callIsAnswered",
    CALL_REJECTED: "callIncomingRejected",
    CALL_NOT_SELECTED_REJECTED: "callIncomingNotSelectedRejected"
};

const VoipMutations = {
    SET_CALLS: "setCalls",
    REMOVE_CALL: "removeCall",
    UPDATE_NOTE_CALL: "updateNoteCall",
    SET_CURRENT_CALL: "setCurrentCall",
    SET_ONGOING_CALL: "setOngoingCall",
    CLEAN_CALLS: "cleanCalls",
    ADD_FILTER: "addFilter",
    REMOVE_FILTER: "removeFilter",
    CLEAN_FILTERS: "cleanFilters",
    SET_NEEDLE: "setNeedle",
    SET_CHECK_PARAMS: "setCheckParams",
    SET_CALL_IS_ANSWERED: "setCallIsAnswered",
    SET_CALL_REJECTED: "setCallRejected",
    SET_CALL_NOT_SELECTED_REJECTED: "setCallIncomingNotSelectedRejected"
};

const actions = {
    [VoipActions.GET_CALLS]: async (
        { commit, rootState, state },
        { status, page }
    ) => {
        debug("GET_CALLS", state);
        const queues = rootState?.voip?.preferedQueue;
        let department = rootState?.voip?.department;

        try {
            const filters = state.filters.filter(
                (filter) => filter.handling_status === status
            );

            const res = await api.getCalls(
                status,
                queues,
                page,
                filters,
                state.searchNeedle,
                department
            );

            const { data, nextPageUrl } = res.data.data;

            commit(VoipMutations.SET_CALLS, {
                data,
                page,
                status,
            });

            // if (!state.currentCall && data && data[0]) {
            //     commit(VoipMutations.SET_CURRENT_CALL, {
            //         status,
            //         page,
            //         id: data[0].id,
            //     });
            // }

            return { isLast: !nextPageUrl, calls: data };
        } catch (e) {
            commit(VoipMutations.SET_CALLS, { data: [], page, status });
            throw e;
        }
    },
    [VoipActions.GET_CALL]: async ({ commit }, id) => {
        const currentTextarea = document.querySelector(
            '.actions-box textarea[name="note"]'
        );
        const prevNoteVal = currentTextarea?.value;
        try {
            const res = await api.getCall(id);

            const { item } = res.data.data;

            if (item) {
                commit(VoipMutations.SET_CURRENT_CALL, {
                    call: item,
                });
            }
        } finally {
            setTimeout(() => {
                if(currentTextarea) {
                    currentTextarea.value = prevNoteVal;
                    const event = new Event("input", { bubbles: true });
                    currentTextarea.dispatchEvent(event);
                }
            }, 1000);
        }
    },
    [VoipActions.REFETCH_CALLS]: async ({ dispatch }, payload) => {
        debug("REFETCH_CALLS", payload);
        let getCalls, getProcessingCalls, getCompletedCalls;
        const { exclude = [], completedPage } = payload;

        if (!exclude.includes("pending")) {
            getCalls = dispatch(VoipActions.GET_CALLS, {
                status: callStatus.PENDING,
                page: 1,
            });
        }

        if (!exclude.includes("processing")) {
            getProcessingCalls = dispatch(VoipActions.GET_CALLS, {
                status: callStatus.PROCESSING,
                page: payload.processingPage,
            });
        }

        if (!exclude.includes("completed")) {
            getCompletedCalls = dispatch(VoipActions.GET_CALLS, {
                status: callStatus.COMPLETED,
                page: completedPage,
            });
        }

        await getCalls;
        await getProcessingCalls;
        await getCompletedCalls;
    },
    [VoipActions.SEARCH_CALLS]: async ({ commit, dispatch, state }, needle) => {
        commit(VoipMutations.SET_NEEDLE, !needle ? "" : needle);
        const currentPages = {
            processingPage: state.processingCalls.size,
            completedPage: state.completedCalls.size,
            exclude: [],
        };
        await dispatch(VoipActions.REFETCH_CALLS, currentPages);
    },
    [VoipActions.CLEAN_CALLS]: async ({ commit }, include) => {
        commit(VoipMutations.CLEAN_CALLS, include);
    },
    [VoipActions.CHANGE_PAGE]: async ({ commit }, payload) => {
        commit(VoipMutations.CHANGE_PAGE, payload);
    },
    [VoipActions.SET_CURRENT_CALL]: async ({ commit }, payload) => {
        commit(VoipMutations.SET_CURRENT_CALL, payload);
    },
    [VoipActions.SET_ONGOING_CALL]: async ({ commit }, payload) => {
        commit(VoipMutations.SET_ONGOING_CALL, payload);
    },
    [VoipActions.UPDATE_CALL]: async ({ commit, dispatch, state }, payload) => {
        debug("UPDATE_CALL", payload);
        const action = payload.isGroup ? api.updateCallsGroup : api.updateCall;
        const { data } = await action(payload);
        const currentPages = {
            processingPage: state.processingCalls.size,
            completedPage: state.completedCalls.size,
            exclude: [],
        };
        if (!payload.blockReFetchCalls){
            // dispatch(VoipActions.CLEAN_CALLS);
            await dispatch(VoipActions.REFETCH_CALLS, currentPages);
        }

        // commit(VoipMutations.SET_CURRENT_CALL, {
        //     status: data.status || data.handling_status || data.data.item.handlingStatus?.value || payload.prevStatus,
        //     page: payload.page,
        //     id: payload.callId ? payload.callId : payload.id,
        //     responseItem: data?.data?.item
        // });
    },
    [VoipActions.ADD_FILTER]: async ({ commit, dispatch, state }, filter) => {
        const filterToRemove = state.filters.find(
            ({ handling_status, type, end }) =>
                handling_status === filter.handling_status &&
                ((type && filter.type) || (end && filter.end))
        );
        if (filterToRemove) {
            commit(VoipMutations.REMOVE_FILTER, filterToRemove);
        }
        commit(VoipMutations.ADD_FILTER, filter);

        const include = { [filter.handling_status]: true };
        dispatch(VoipActions.CLEAN_CALLS, include);
    },
    [VoipActions.REMOVE_FILTER]: async ({ commit, dispatch }, filter) => {
        commit(VoipMutations.REMOVE_FILTER, filter);
        const include = { [filter.handling_status]: true };

        dispatch(VoipActions.CLEAN_CALLS, include);
    },
    [VoipActions.CLEAN_FILTERS]: async ({ commit }) => {
        commit(VoipMutations.CLEAN_FILTERS);
    },
    [VoipActions.CHECK_PARAMS]: async ({ commit }, payload) => {
        commit(VoipMutations.SET_CHECK_PARAMS, payload);
    },
    [VoipActions.CALL_IS_ANSWERED]: async ({ commit }, payload) => {
        commit(VoipMutations.SET_CALL_IS_ANSWERED, payload);
    },
    [VoipActions.CALL_REJECTED]: async ({ commit }, payload) => {
        commit(VoipMutations.SET_CALL_REJECTED, payload);
    },
    [VoipActions.CALL_NOT_SELECTED_REJECTED]: async ({ commit }, payload) => {
        commit(VoipMutations.SET_CALL_NOT_SELECTED_REJECTED, payload);
    },
};
const mutations = {
    [VoipMutations.SET_CALLS](state, { status, page, data }) {
        if (status === callStatus.PENDING) {
            state[`${status}Calls`] = data;
        } else {
            state[`${status}Calls`] = new Map();
            state[`${status}Calls`].set(page, data);
        }
    },
    [VoipMutations.CHANGE_PAGE](state, { status, page }) {
        state[`${status}Calls`] = { page };
    },
    [VoipMutations.SET_NEEDLE](state, needle) {
        state.searchNeedle = needle;
    },
    [VoipMutations.CLEAN_CALLS](
        state,
        include = { pending: true, processing: true, completed: true }
    ) {
        if (include?.pending) state.pendingCalls = [];
        if (include?.processing) state.processingCalls = new Map();
        if (include?.completed) state.completedCalls = new Map();
    },
    [VoipMutations.SET_CURRENT_CALL](state, { status, id, page = 1, call, callGroup, responseItem}) {
        debug('SET_CURRENT_CALL', { status: status, id: id, page: page, call: call, callGroup: callGroup, responseItem: responseItem});

        if (call) {
            state.currentCall = call;
        } else if(callGroup) {
             state.currentCall = { ...state.currentCall, group: callGroup, handlingStatus: callGroup.status };
        } else {
            const calls = state[`${status}Calls`];
            const callsArr =
                status === callStatus.PENDING ? calls : calls.get(page);
            if (!callsArr) return;
            const callObject = callsArr.find(
                (queryCall) => queryCall.id === id
            );
            state.currentCall = callObject ? { ...callObject, page } : null;
        }
    },
    [VoipMutations.SET_ONGOING_CALL](state, payload) {
        state.ongoingCall = payload;
    },
    [VoipMutations.ADD_FILTER]: async (state, filter) => {
        state.filters = state.checkParams
            ? [
                  ...state.filters.filter(
                      ({ type, status, start, end }) =>
                          type === filter.type ||
                          status === filter.status ||
                          (start !== filter.start && end !== filter.end)
                  ),
                  filter,
              ]
            : [
                  ...state.filters.filter(
                      ({ type, status, start, end }) =>
                          type !== filter.type ||
                          status !== filter.status ||
                          (start !== filter.start && end !== filter.end)
                  ),
                  filter,
              ];
    },
    [VoipMutations.REMOVE_FILTER]: async (state, { type, status }) => {
        state.filters = state.filters.filter(
            (filter) => filter.type !== type && filter.status !== status
        );
    },
    [VoipMutations.CLEAN_FILTERS]: async (state) => {
        state.filters = [];
    },
    [VoipMutations.SET_CHECK_PARAMS]: async (state, payload) => {
        state.checkParams = payload;
    },
    [VoipMutations.SET_CALL_IS_ANSWERED]: async (state, payload) => {
        state.callStatus = payload;
    },
    [VoipMutations.SET_CALL_REJECTED]: async (state, payload) => {
        state.callRejected = payload;
    },
    [VoipMutations.SET_CALL_NOT_SELECTED_REJECTED]: async (state, payload) => {
        state.callNotSelectedRejected = payload;
    },

};

const getters = {
    pendingCalls: (state) => state.pendingCalls,
    completedCalls: (state) => state.completedCalls,
    processingCalls: (state) => state.processingCalls,
    currentCall: (state) => state.currentCall,
    ongoingCall: (state) => state.ongoingCall,
    filters: (state) => (handlingStatus) =>
        handlingStatus
            ? state.filters.filter(
                  (filter) => filter.handling_status === handlingStatus
              )
            : state.filters,
    searchNeedle: (state) => state.searchNeedle,
    filtersLength: (state) => state.filtersLength,
    checkParams: (state) => state.checkParams,
    callStatus: (state) => state.callStatus,
    callRejected: (state) => state.callRejected,
    callNotSelectedRejected: (state) => state.callNotSelectedRejected,
};

const state = {
    pendingCalls: [],
    processingCalls: new Map(),
    completedCalls: new Map(),
    currentCall: null,
    testCall: null,
    ongoingCall: null,
    filters: [],
    filtersLength: 0,
    searchNeedle: "",
    checkParams: false,
    callStatus: false,
    callRejected: false,
    callNotSelectedRejected: false
};

export default {
    namespaced: true,
    getters,
    actions,
    mutations,
    state,
};
