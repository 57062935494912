<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12.118"
        height="16.193"
        viewBox="0 0 12.118 16.193"
    >
        <path
            d="M.001 8.082V2.206a2.042 2.042 0 0 1 2.18-2.2c1.535-.006 3.071 0 4.609 0a1.7 1.7 0 0 1 1.286.527 451.091 451.091 0 0 0 3.527 3.527 1.655 1.655 0 0 1 .514 1.248c0 2.9.006 5.806-.006 8.708a2.012 2.012 0 0 1-2.228 2.176h-7.7A2.016 2.016 0 0 1 0 14.002c-.002-1.972.001-3.948.001-5.92Zm11.127-2.027h-.482c-.986 0-1.973.01-2.962 0a1.5 1.5 0 0 1-1.583-1.6c0-1-.019-2 .006-3 .01-.386-.124-.472-.485-.466-1.184.022-2.368.006-3.553.01A.992.992 0 0 0 .943 2.1v12.011a1 1 0 0 0 1.124 1.111h7.91c.836 0 1.171-.335 1.171-1.178v-7.53a4.15 4.15 0 0 0-.02-.459Zm-.118-.98C9.692 3.744 8.383 2.43 7.071 1.101c0 1.152-.016 2.346.019 3.537a.609.609 0 0 0 .425.418c1.178.035 2.356.019 3.495.019Z"
            fill="currentColor"
        />
    </svg>
</template>
