/* eslint-disable */
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { plugin as FormKitPlugin, defaultConfig } from '@formkit/vue';
import { pl as formKitPl } from '@formkit/i18n';
import * as Sentry from "@sentry/vue";
import Notifications from '@kyvg/vue3-notification';
import PrimeVue from 'primevue/config';
import primeLocale from 'primelocale/pl.json';
import Tooltip from 'primevue/tooltip';
import Aura from '@primevue/themes/aura';
import axios from "axios";
import App from "./App.vue";
import AppV2 from "./AppV2.vue";
import store from './store';
import router from './router';

const configureApp = (app) => {
    app.use(PrimeVue, {
            locale: primeLocale.pl,
            theme: {
                preset: Aura,
                options: { darkModeSelector: '' }
            },
        })
        .directive('tooltip', Tooltip)
        .provide('tenant', window.tenant)
        .use(Notifications)
        .use(createPinia())
        .use(router)
        .use(store)
        .use(FormKitPlugin, defaultConfig({
            locales: { pl: formKitPl },
            locale: "pl",
            validationNameStrategy: ["validationName", "label", "name", "type"],
        }));
};

const configureAxios = () => {
    const token = localStorage.getItem("token");
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
};

const configureSentry = (app) => {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_KEY,
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: [
                    "localhost",
                    `${import.meta.env.VITE_APP_URL}/api/`,
                ],
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],
        tracesSampleRate: 0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
};

const init = () => {
    const VueApp = window.version === 2 ? AppV2 : App;
    const app = createApp(VueApp);

    configureApp(app);
    configureAxios();
    configureSentry(app);

    app.mount("#app");
};

init();
