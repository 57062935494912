<template>
    <div v-if="popupActive">
        <notes-form
            :callback="updateNoteCallback"
            :fields="{ note: '', id: currentCallId }"
            :force-active="noteFormActive"
            :overheading="{
                text: 'Notka',
                icon: 'menu',
            }"
            :pencil="false"
            class="z-[51] absolute"
            name="note"
        />

        <popup-wrapper :active="active" @close="active = false">
            <div v-if="ongoingCall && ongoingCall.handlingStatus">
                <custom-heading
                    :text="
                         translate(ongoingCall.handlingStatus.value, 'headings')
                    "
                    icon="phone"
                ></custom-heading>

                <h2 class="text-lg md:text-3xl mt-1 sm:mt-3 text-blue-500">
                    Połącznie od numeru {{ ongoingCall.phone }}
                </h2>

                <div v-if="ongoingCall.caller" class="mt-4">
                    Dzwoni <b>zarejestrowany</b> pacjent

                    <ul class="mt-4">
                        <li>
                            Imię i Nazwisko:
                            <b>{{ ongoingCall.caller.name }}</b>
                        </li>
                        <li>
                            Email:<b> {{ ongoingCall.caller.email }}</b>
                        </li>
                    </ul>
                </div>

                <div v-else class="mt-4">
                    Dzwoni <b>nie zarejestrowany</b> pacjent
                </div>

                <div class="flex justify-between mt-8 gap-4">
                    <custom-button
                        class="h-max"
                        type="empty"
                        @click="active = false"
                    >
                        ANULUJ
                    </custom-button>

                    <div class="flex flex-wrap justify-end gap-4">
                        <router-link :to="`/switchboard`">
                            <custom-button
                                type="empty"
                                @click="active = false"
                            >
                                Wyświetl centralkę
                            </custom-button>
                        </router-link>

                        <router-link
                            v-if="ongoingCall.caller"
                            :to="`/patients/${ongoingCall.caller.id}`"
                        >
                            <custom-button
                                type="empty"
                                @click="active = false"
                            >
                                Zobacz pacjenta
                            </custom-button>
                        </router-link>

                        <div v-else>
                            <custom-button
                                type="empty"
                                @click="
                                    patientFormActive = !patientFormActive
                                "
                            >
                                <custom-icon
                                    :h="12"
                                    :w="12"
                                    class="fill-black"
                                    icon="plus"
                                ></custom-icon>
                                DODAJ PACJENTA
                            </custom-button>
                            <patient-form
                                :fields="{
                                    phones: [
                                        {
                                            isMain: 1,
                                            phone: ongoingCall.phone,
                                        },
                                    ],
                                    source: 'Telefon',
                                }"
                                :force-active="patientFormActive"
                                mode="create"
                            ></patient-form>
                        </div>

                        <custom-button
                            class="whitespace-nowrap"
                            type="empty"
                            @click="addNote"
                        >
                            <custom-icon
                                :h="12"
                                :w="12"
                                class="fill-black"
                                icon="plus"
                            ></custom-icon>
                            DODAJ NOTKĘ
                        </custom-button>
                    </div>
                </div>
            </div>
        </popup-wrapper>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import NotesForm from "@/components/v1/NotesForm";
import CustomHeading from "@/elements/v1/CustomHeading";
import PopupWrapper from "./PopupWrapper";
import CustomButton from "@/elements/v1/CustomButton";
import CustomIcon from "@/elements/v1/CustomIcon";
import PatientForm from "@/components/v1/Patient/DetailsForm";
import {callStatus} from "@/lib/constants";
import {translate} from "@/lib/utils";

const ECHO_CHANNELS = {
    incoming: 'TelephoneExchange\\CallIncoming',
    outgoing: 'TelephoneExchange\\CallOutgoing',
    ended: 'TelephoneExchange\\CallEnded',
    ringing: 'TelephoneExchange\\PhoneRinging',
    answered: 'TelephoneExchange\\CallAnswered',
    callChangedStatus: 'TelephoneExchange\\CallGroupStatusChanged',

};

export default {
    methods: {translate},
    mixins: [
        {
            name: "SwitchboardPopup",
            components: {
                CustomHeading,
                PopupWrapper,
                CustomButton,
                CustomIcon,
                PatientForm,
                NotesForm,
            },
            computed: {
                ...mapGetters("patients", ["singlePatient"]),
                ...mapGetters("calls", ["pendingCalls", "ongoingCall"]),
                ...mapGetters("auth", ["user"]),
            },
            mounted() {
                this.listenTelephoneExchange();
            },
            data: () => ({
                popupActive: false,
                currentCallId: null,
                patientFormActive: false,
                active: false,
                callerNumber: null,
                patient: null,
                timeout: null,
                noteFormActive: false,
            }),
            methods: {
                ...mapActions("patients", ["fetchPatient"]),
                ...mapActions("calls", [
                    "setOngoingCall",
                    "addCall",
                    "getCalls",
                    "getCall",
                    "updateCall",
                ]),
                translate,
                addNote() {
                    this.noteFormActive = !this.noteFormActive;
                },
                updateNoteCallback(data) {
                    const succesMessage = this.translate(
                        "updated",
                        "store",
                        "note"
                    );
                    this.updateCall({
                        page: 1,
                        data,
                        id: data.id,
                    });

                    this.$notify({
                        group: "general",
                        type: "success",
                        text: succesMessage,
                    });

                    this.reFetchCalls();
                },
                reFetchCalls(
                    statuses = [callStatus.PENDING, callStatus.PROCESSING]
                ) {
                    statuses.forEach((status) => {
                        this.getCalls({status, page: 1});
                    });
                },
                listenTelephoneExchange() {
                    window.echo
                        ?.private(`tenant.${window.tenant?.id}`)
                        .listen('TelephoneExchange\\CallGroupStatusChanged', (event) => {
                            console.log("CallGroupStatusChanged", event);
                            this.reFetchCalls([
                                callStatus.PROCESSING,
                                callStatus.PENDING,
                                callStatus.COMPLETED,
                            ]);
                        })
                        .listen(ECHO_CHANNELS.incoming, (event) => {
                            console.log("CallIncoming", event);
                            this.$store.dispatch('calls/callIncomingRejected', false)
                            this.$store.dispatch('calls/callIsAnswered', true)
                            this.reFetchCalls([callStatus.PENDING]);
                        })
                        .listen(ECHO_CHANNELS.answered, (event) => {
                            console.log("CallAnswered", event);
                            this.getCalls({
                                status: callStatus.PROCESSING,
                                page: 1,
                            }).then(({call}) => {
                                if (event.call) this.getCall(call.id);
                            });

                            this.reFetchCalls([callStatus.PENDING]);
                        })
                        .listen(ECHO_CHANNELS.outgoing, (event) => {
                            console.log("CallOutgoing", event);
                            this.$store.dispatch('calls/callIncomingRejected', false)
                            this.reFetchCalls([callStatus.PENDING]);
                        })
                        .listen(ECHO_CHANNELS.ended, (event) => {
                            console.log("CallEnded", event);
                            this.$store.dispatch('calls/callIsAnswered', false)
                            if (event.call) this.getCall(event.call?.id);
                            if (event.call.status.value === 'not_answered' && event.call.type.value === 'incoming') {
                                this.$store.dispatch('calls/callIncomingRejected', true)
                                this.$store.dispatch('calls/callIncomingNotSelectedRejected', false)
                            }
                            if (event.call.status.value === 'pending' && event.call.type.value === 'incoming') {
                                this.$store.dispatch('calls/callIncomingNotSelectedRejected', true)
                            }
                            this.reFetchCalls([
                                callStatus.PROCESSING,
                                callStatus.PENDING,
                            ]);
                        });

                },
                beforeUnmount() {
                    if (this.timeout) {
                        clearTimeout(this.timeout);
                    }
                    for (const channel in ECHO_CHANNELS) {
                        window.echo
                            ?.private(`tenant.${window.tenant?.id}`)
                            .stopListening(ECHO_CHANNELS[channel]);
                    }
                    window.echo.leaveChannel(`tenant.${window.tenant?.id}`);
                },
            },
        },
    ],
};
</script>
