import axiosClient from "../apiClient";

export async function login(payload) {
    return await axiosClient.post("/login", payload);
}

export function logout() {
    return axiosClient.post("/logout");
}

export function authorize() {
    return axiosClient.get("/clinic/users/me");
}

export function sendEmailReset(payload) {
    return axiosClient.post("/password/email", payload);
}

export function resetPassword(payload) {
    return axiosClient.post("/password/reset", payload);
}
