<template>
    <div>
        <router-link :to="item.path">
            <span
                class="absolute top-4 sm:top-[22px] -left-[1.125rem] sm:-left-6 w-2 sm:w-3 h-2 sm:h-3 rounded-full bg-blue-500"
                v-show="$route.path == item.path"
            ></span>

            <div
                :class="{
                    'px-[1.125rem] py-3 sm:py-4 flex gap-1 sm:gap-5 items-center rounded-xl sm:rounded-2xl ': true,
                    'bg-blue-500 text-white shadow-xl': isActive,
                    'text-black bg-gray-100 bg-opacity-0 hover:bg-opacity-100 transition-colors':
                        !isActive,
                }"
            >
                <custom-icon v-if="item.icon" :icon="item.icon" />
                <span class="text-xs sm:text-base">{{ item.name }}</span>

                <div
                    :class="{
                        'ml-2.5 sm:-ml-1.5  transform transition-transform px-3 py-1': true,
                        'rotate-180 translate-y-[1px]': this.open,
                    }"
                    v-if="hasChildren"
                    @click="chevronClick"
                >
                    <custom-icon icon="chevron" :w="12" :h="12" />
                </div>
            </div>
        </router-link>

        <ul
            class="submenu flex flex-col gap-2 justify-center sm:gap-4 pl-8 sm:pl-[50px] transition-all overflow-hidden"
            v-if="hasChildren"
            ref="submenu"
        >
            <li
                class="relative"
                v-for="(subitem, key) in item.children"
                :key="key"
            >
                <span
                    class="absolute top-[11px] sm:top-3.5 -left-4 sm:-left-6 w-1.5 h-1.5 sm:w-2 sm:h-2 rounded-full bg-blue-500"
                    v-show="$route.path === subitem.path"
                ></span>

                <router-link
                    class="py-1.5 block text-xs sm:text-base"
                    :to="subitem.path"
                >
                    {{ subitem.name }}
                </router-link>
            </li>
        </ul>
    </div>
</template>
<script>
import CustomIcon from "@/elements/v1/CustomIcon";

export default {
    mixins: [
        {
            name: "NavigationItem",
            components: { CustomIcon },
            data: () => ({
                submenu: HTMLElement,
                open: false,
            }),
            props: {
                item: {
                    type: Object,
                    require: true,
                },
            },
            computed: {
                isActive() {
                    const routePath = this.$route.path;
                    const itemPath = this.item.path;

                    const routePathHasItemPath =
                        routePath.indexOf(itemPath) + 1;
                    const isHomepage = itemPath.length > 1 || routePath == "/";

                    const active = routePathHasItemPath && isHomepage;
                    this.submenu.classList?.toggle(
                        "submenu--hidden",
                        !this.open
                    );
                    return active;
                },
                hasChildren() {
                    return !!this.item.children;
                },
            },
            mounted() {
                if (this.hasChildren) {
                    this.$nextTick(() => this.calcSubmenusHeights(), 0);
                }
            },
            methods: {
                calcSubmenusHeights() {
                    this.submenu = this.$refs.submenu;
                    const { height } = this.submenu.getBoundingClientRect();
                    this.submenu.style.height = `${
                        +height.toFixed(0) + window.innerHeight / 30
                    }px`;
                },
                chevronClick(e) {
                    e.preventDefault();
                    this.submenu.classList.toggle("submenu--hidden");
                    this.open =
                        !this.submenu.classList.contains("submenu--hidden");
                },
            },
        },
    ],
};
</script>
