import * as api from "@/api";
import {getVisitsSales} from "@/api";

const VisitsActions = {
    UPDATE_SOURCE: "updateSource",
    FETCH_VISITS_SALES_SUMMARY: "fetchVisitsSalesSummary",
    FETCH_VISITS_SALES_SUMMARY_NOT_PAYED: "fetchVisitsSalesSummaryNotPayed",
    FETCH_VISITS_SALES_BY_SOURCE: 'fetchVisitsSalesBySource',
    FETCH_VISITS_SALES_BY_STATUS: 'fetchVisitsSalesByStatus',
    FETCH_VISITS_SALES_TRENDS_CURRENT: "fetchVisitsSalesTrendsCurrent",
    FETCH_VISITS_SALES_TRENDS_PREV: "fetchVisitsSalesTrendsPrev",
    FETCH_VISITS_SALES_RANKING: "fetchVisitsSalesRanking"
};

const VisitsMutations = {
    SET_SELECTED_SOURCE: "setSelectedSource",
    SET_VISITS_SUMMARY: "setVisitsSalesSummary",
    SET_VISITS_BY_SOURCE: "setVisitsSalesBySource",
    SET_VISITS_BY_STATUS: "setVisitsSalesByStatus",
    SET_VISITS_SUMMARY_NOT_PAYED: "setVisitsSalesSummaryNotPayed",
    SET_VISITS_TRENDS_CURRENT: "setVisitsSalesTrendsCurrent",
    SET_VISITS_TRENDS_PREV: "setVisitsSalesTrendsPrev",
    SET_VISITS_RANKING: "setVisitsSalesRanking",
    SET_LOADER_STATUS: "setLoaderStatus"
};

const actions = {
    [VisitsActions.UPDATE_SOURCE]: async ({commit}, payload) => {
        commit(VisitsMutations.SET_SELECTED_SOURCE, payload
        );
    },
    [VisitsActions.FETCH_VISITS_SALES_SUMMARY]: async ({commit}, payload) => {
        const {data} = await api
            .getVisitsSales('summary', payload)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_VISITS_SUMMARY, {
            avgPrice: data.data.stats.avgPrice ? data.data.stats.avgPrice : 0,
            sumPrice: data.data.stats.sumPrice ? data.data.stats.sumPrice : 0,
            volume: data.data.stats.volume ? data.data.stats.volume : 0,
        });
    },
    [VisitsActions.FETCH_VISITS_SALES_BY_SOURCE]: async ({commit}, payload) => {
        const {data} = await api
            .getVisitsSales('summary', payload)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_VISITS_BY_SOURCE, {
            avgPrice: data.data.stats.avgPrice ? data.data.stats.avgPrice : 0,
            sumPrice: data.data.stats.sumPrice ? data.data.stats.sumPrice : 0,
            volume: data.data.stats.volume ? data.data.stats.volume : 0,
        });
    },
    [VisitsActions.FETCH_VISITS_SALES_BY_STATUS]: async ({commit}, payload) => {
        const {data} = await api
            .getVisitsSales('summary', payload)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_VISITS_BY_STATUS, {
            avgPrice: data.data.stats.avgPrice ? data.data.stats.avgPrice : 0,
            sumPrice: data.data.stats.sumPrice ? data.data.stats.sumPrice : 0,
            volume: data.data.stats.volume ? data.data.stats.volume : 0,
        });
    },
    [VisitsActions.FETCH_VISITS_SALES_TRENDS_CURRENT]: async ({commit}, payload) => {
        const {data} = await api
            .getVisitsSales('trend', payload)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_VISITS_TRENDS_CURRENT, {
            stats: data.data.stats,
            from: data.data.from,
            to: data.data.to
        });
    },
    [VisitsActions.FETCH_VISITS_SALES_TRENDS_PREV]: async ({commit}, payload) => {
        const {data} = await api
            .getVisitsSales('trend', payload)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_VISITS_TRENDS_PREV, {
            stats: data.data.stats,
            from: data.data.from,
            to: data.data.to
        });
    },
    [VisitsActions.FETCH_VISITS_SALES_RANKING]: async ({state ,commit}, payload) => {
        commit(VisitsMutations.SET_LOADER_STATUS, true);
        const {data} = await api
            .getVisitsSales('ranking', payload)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_LOADER_STATUS, false);
        const visits  = await api
            .getVisitsSales('summary', payload)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_VISITS_RANKING, {
            ranking: data.data.stats,
            globalVolume: visits.data.data.stats.volume ? visits.data.data.stats.volume : 0,

        });
    },
};
const mutations = {
    [VisitsMutations.SET_SELECTED_SOURCE](state, payload) {
        state.selectedSalesSource = payload
    },
    [VisitsMutations.SET_LOADER_STATUS](state, payload) {
        state.loader = payload
    },
    [VisitsMutations.SET_VISITS_SUMMARY](state, payload) {
        state.salesSummary = payload
    },
    [VisitsMutations.SET_VISITS_BY_SOURCE](state, payload) {
        state.salesSummaryBySource = payload
    },
    [VisitsMutations.SET_VISITS_BY_STATUS](state, payload) {
        state.salesSummaryByStatus = payload
    },
    [VisitsMutations.SET_VISITS_TRENDS_CURRENT](state, payload) {
        state.salesTrendsCurrent = payload
    },
    [VisitsMutations.SET_VISITS_TRENDS_PREV](state, payload) {
        state.salesTrendsPrev = payload
    },
    [VisitsMutations.SET_VISITS_RANKING](state, payload) {
        state.salesRanking = payload
    },

};

const getters = {
    selectedSalesSource: (state) => state.selectedSalesSource,
    salesSummary: (state) => state.salesSummary,
    salesTrendsCurrent: (state) => state.salesTrendsCurrent,
    salesTrendsPrev: (state) => state.salesTrendsPrev,
    salesRanking: (state) => state.salesRanking,
    salesSummaryBySource: (state) => state.salesSummaryBySource,
    salesSummaryByStatus: (state) => state.salesSummaryByStatus,
    salesLoader: (state) => state.loader
};

const state = {
    selectedSalesSource: null,
    salesSummary: null,
    salesSummaryNotPayed: null,
    salesTrendsCurrent: null,
    salesTrendsPrev: null,
    salesRanking: null,
    salesSummaryBySource: null,
    salesSummaryByStatus: null,
    loader: false,
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
