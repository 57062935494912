import axios from "axios";

const bugReportUrl = import.meta.env.VITE_REPORT_BUG_URL;

if (!bugReportUrl) {
    console.error(`Provide bug report enviroment variable`);
}

export const issueBug = (formData) => {
    if (!bugReportUrl) return;
    return axios.post(bugReportUrl, formData);
};
