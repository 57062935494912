<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11.833"
        height="17.151"
        viewBox="0 0 11.833 17.151"
    >
        <g
            id="Component_34_1"
            data-name="Component 34 – 1"
            transform="translate(0 0.65)"
        >
            <g
                id="Group_114"
                data-name="Group 114"
                transform="translate(-1815.615 -517.5)"
            >
                <g
                    id="Path_29"
                    data-name="Path 29"
                    transform="translate(1628.303 383.077)"
                    fill="none"
                >
                    <path
                        d="M197.547,150.924H188.91a.885.885,0,0,1-.855-.858l-.741-11.806a.894.894,0,0,1,.855-.98h10.118a.9.9,0,0,1,.855.98l-.741,11.806A.885.885,0,0,1,197.547,150.924Z"
                        stroke="none"
                    />
                    <path
                        d="M 197.1272125244141 149.6235198974609 L 197.8204803466797 138.5796203613281 L 188.6364135742188 138.5796203613281 L 189.3296813964844 149.6235198974609 L 197.1272125244141 149.6235198974609 M 197.5465393066406 150.9235229492188 L 188.9103240966797 150.9235229492188 C 188.4591217041016 150.9235229492188 188.0854949951172 150.5493774414062 188.0548858642578 150.0658416748047 L 187.3137664794922 138.2597961425781 C 187.2810974121094 137.7281341552734 187.673095703125 137.2796173095703 188.1692047119141 137.2796173095703 L 198.2876739501953 137.2796173095703 C 198.7837982177734 137.2796173095703 199.17578125 137.7303314208984 199.1431121826172 138.2597961425781 L 198.4020080566406 150.0658416748047 C 198.3713684082031 150.5493774414062 197.9977569580078 150.9235229492188 197.5465393066406 150.9235229492188 Z"
                        stroke="none"
                        fill="currentColor"
                    />
                </g>
                <line
                    id="Line_42"
                    data-name="Line 42"
                    x2="8"
                    transform="translate(1817.5 517.5)"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-width="1.3"
                />
            </g>
        </g>
    </svg>
</template>
