import * as api from "@/api";

const DepartmentsActions = {
    FETCH_DEPARTMENTS: "fetchDepartments",
};

const DepartmentsMutations = {
    SET_DEPARTMENTS: "setDepartments",
};

const actions = {
    [DepartmentsActions.FETCH_DEPARTMENTS]: async ({ commit }) => {
        const { data } = await api
            .fetchDepartments()
            .catch((e) => Promise.reject(e.response.data));
        commit(DepartmentsMutations.SET_DEPARTMENTS, data.data.data);
    },
};
const mutations = {
    [DepartmentsMutations.SET_DEPARTMENTS](state, payload) {
        state.departments = payload;
    },
};

const getters = {
    allDepartments: (state) => state.departments,
};

const state = {
    departments: null,
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
