<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9.397"
        height="9.426"
        viewBox="0 0 9.397 9.426"
    >
        <path
            d="M1.34 9.425a8.481 8.481 0 0 0 7.679-5.917 7.953 7.953 0 0 0 .378-2.423c0-.409-.126-.566-.5-.661-.6-.126-1.2-.283-1.762-.409a.545.545 0 0 0-.661.346c-.283.661-.566 1.29-.818 1.951a.56.56 0 0 0 .189.724c.252.22.535.441.818.661.126.094.126.157.063.283a6.389 6.389 0 0 1-2.738 2.738c-.126.063-.189.063-.283-.063-.22-.283-.441-.535-.661-.818a.56.56 0 0 0-.728-.187c-.629.252-1.259.535-1.92.818a.612.612 0 0 0-.378.755l.378 1.7a.547.547 0 0 0 .629.5Z"
            fill="currentColor"
        />
    </svg>
</template>
