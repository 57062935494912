import { isDate } from "lodash";
import axiosClient from "../api/apiClient";
import moment from "moment";

export default {
    parseFilters(filters) {
        const params = {};
        for (let key in filters) {
            if (Array.isArray(filters[key])) {
                filters[key] = filters[key]
                    .map((val) => {
                        if (isDate(val)) {
                            return moment(val).format("YYYY-MM-DD HH:mm:ss");
                        }
                        return val;
                    })
                    .join(",");
            }

            params[`filter[${key}]`] = filters[key];
        }

        return params;
    },

    fetchCallsPaginated({
        page = 1,
        perPage = 20,
        filters = {},
        sortField = "createdAt",
        sortOrder = -1,
        config = {},
    } = {}) {
        const params = {
            page,
            perPage,
            sortField: sortField,
            sortOrder,
        };

        const parsedFilters = this.parseFilters(filters);
        return axiosClient.get("/clinic/calls", {
            params: { ...params, ...parsedFilters },
            ...config,
        });
    },

    fetchCalls({
        filters = {},
        sortField = "createdAt",
        sortOrder = -1,
        config = {},
    } = {}) {
        return this.fetchCallsPaginated({
            filters,
            page: 1,
            perPage: -1,
            sortField,
            sortOrder,
            config,
        });
    },

    fetchSwitchboardCalls({
        filters = {},
        sortField = "createdAt",
        sortOrder = -1,
        config = {},
    } = {}) {
        const mergedFilters = {
            ...filters,
            switchboard: 1,
        };

        return this.fetchCalls({
            filters: mergedFilters,
            sortField,
            sortOrder,
            config,
        });
    },

    hangupCall(callId) {
        return axiosClient.post(`/clinic/calls/${callId}/hangup`);
    },

    makeCall(phone, callbackId, departmentId) {
        const params = new URLSearchParams();

        if (callbackId) {
            params.append("calling_back_call_id", callbackId);
        }

        if (departmentId) {
            params.append("department_id", departmentId);
        }

        return axiosClient.post(
            `/clinic/calls?phone=${phone}&${params.toString()}`
        );
    },

    async updateCall(callId, data) {
        const response = await axiosClient
            .patch(`/clinic/calls/${callId}`, data)
            .then((response) => {
                handleSuccess(response.data.message);
            });

        return response.data;
    },

    metafieldValue(call, metafield) {
        return (
            call.group?.metafields?.find((m) => m.metafield.id == metafield.id)
                ?.metafieldSelectableValue?.value ?? null
        );
    },
};
