<template>
    <ul
        class="mt-4 space-y-2.5 max-h-[60vh] overflow-y-auto"
        v-if="messagesList && messagesList.length"
    >
        <li
            class="cursor-pointer p-1 pr-3 rounded-xl border border-gray-200 group flex items-center w-full justify-between gap-2"
            v-for="(message, key) in messagesList"
            :key="key"
            @click="showMessage(message)"
        >
            <div class="flex items-center gap-2">
                <div
                    class="min-w-[36px] h-9 bg-gray-300 bg-opacity-50 rounded-lg flex justify-center items-center text-blue-500"
                >
                    <custom-icon icon="envelope"></custom-icon>
                </div>
                <div>
                    <h5 class="text-xs truncate">
                        Otrzymano nową wiadomość od
                    </h5>
                    <strong class="font-bold">Autor</strong>
                </div>
            </div>

            <div class="flex items-center gap-3">
                <time class="font-light text-sm text-gray-500">
                    {{ formatDate(message.updatedAt) }}
                </time>

                <div
                    class="w-1.5 h-1.5 bg-red-500 rounded-full"
                    :class="message.isRead ? '' : 'opacity-0'"
                ></div>
            </div>
        </li>
    </ul>
</template>

<script>
import date, { formatDate } from "@/mixins/date";
import { getItemFromStoreOrFetchGroup } from "@/mixins/store.js";
import CustomIcon from "@/elements/v2/CustomIcon";

export default {
    mixins: [
        {
            name: "NotificationsMail",
            components: {
                CustomIcon,
            },
            mixins: [
                getItemFromStoreOrFetchGroup(
                    "messages",
                    "allMessages",
                    "fetchMessages"
                ),
                date,
            ],
            mounted() {
                this.getAsyncItems({ patientId: 17 });
            },
            computed: {
                messagesList() {
                    return this.items
                        ? [...this.items].reverse().slice(0, 5)
                        : [];
                },
            },
            methods: {
                showMessage(msg) {
                    // this.currentMessage = msg;
                },
                setLimit(limit) {
                    this.limit = limit;
                },
            },
        },
    ],
};
</script>
