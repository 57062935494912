<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14.364"
        height="10.238"
        viewBox="0 0 14.364 10.238"
    >
        <path
            id="envelope-solid"
            d="M12.156,64.908a1.335,1.335,0,0,1,1.207,1.385,1.253,1.253,0,0,1-.5,1L7.2,71.453a.838.838,0,0,1-1,0L.5,67.3a1.254,1.254,0,0,1-.5-1,1.412,1.412,0,0,1,1.3-1.385H12.156Zm-6.477,6.4a1.668,1.668,0,0,0,2,0l5.68-4.26v5.429a1.672,1.672,0,0,1-1.67,1.67H1.67A1.671,1.671,0,0,1,0,72.476V67.047Z"
            transform="translate(0.5 -64.408)"
            fill="none"
            stroke="currentColor"
            stroke-width="1"
        />
    </svg>
</template>
