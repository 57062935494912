<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 11.785 11.822"
    >
        <path
            id="Path_6058"
            data-name="Path 6058"
            d="M15.581,27.171a10.637,10.637,0,0,0,9.631-7.421,9.975,9.975,0,0,0,.474-3.039c0-.513-.158-.71-.632-.829-.75-.158-1.5-.355-2.21-.513a.683.683,0,0,0-.829.434c-.355.829-.71,1.618-1.026,2.447a.7.7,0,0,0,.237.908c.316.276.671.553,1.026.829.158.118.158.2.079.355A8.013,8.013,0,0,1,18.9,23.777c-.158.079-.237.079-.355-.079-.276-.355-.553-.671-.829-1.026a.7.7,0,0,0-.908-.237c-.789.316-1.579.671-2.408,1.026a.768.768,0,0,0-.474.947L14.4,26.54a.687.687,0,0,0,.789.632Z"
            transform="translate(-13.9 -15.35)"
            fill="currentColor"
        />
    </svg>
</template>
