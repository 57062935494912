export const Home = () => import("@/pages/v2/HomePage.vue");

export const Patient = () => import("@/pages/v2/Patient/PatientPage.vue");
export const Patients = () => import("@/pages/v2/Patient/PatientsPage.vue");

export const Visit = () => import("@/pages/v2/Visit/VisitPage.vue");
export const Visits = () => import("@/pages/v2/Visit/VisitsPage.vue");
export const CreateVisit = () => import("@/pages/v2/Visit/CreateVisitPage.vue");
export const VisitCalendar = () =>
    import("@/pages/v2/Visit/VisitCalendarPage.vue");
export const VisitArchive = () =>
    import("@/pages/v2/Visit/VisitArchivePage.vue");

export const Settings = () => import("@/pages/v2/SettingsPage.vue");
export const SurveyResults = () => import("@/pages/v2/SurveyResultsPage.vue");
//export const Switchboard = () => import("@/pages/v2/SwitchboardPage.vue");
export const Switchboard = () => import("@/pages/v3/SwitchboardPage.vue");
export const Calls = () => import("@/pages/v3/CallsPage.vue");

export const MessagesSms = () =>
    import("@/pages/v2/Messages/MessagesSmsPage.vue");

export const Login = () => import("@/pages/v2/Authentication/LoginPage.vue");
export const LostPassword = () =>
    import("@/pages/v2/Authentication/LostPasswordPage.vue");
export const ResetPassword = () =>
    import("@/pages/v2/Authentication/ResetPasswordPage.vue");

export const Statistics = () =>
    import("@/pages/v2/Statistics/StatisticsPage.vue");

export const StatisticsCalls = () =>
    import("@/pages/v2/Statistics/CallsStats/StatisticsCallsPage.vue");

export const StatisticsVisits = () =>
    import("@/pages/v2/Statistics/VisitsStats/StatisticsVisitsPage.vue");

export const StatisticsEmployee = () =>
    import("@/pages/v2/Statistics/EmployeeStats/StatisticsEmployeePage.vue");

export const StatisticsSales = () =>
    import("@/pages/v2/Statistics/SalesStats/StatisticsStalesPage.vue");

export const SupportPage = () =>
    import("@/pages/v2/SupportPage.vue");

