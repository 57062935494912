<template>
    <edit-popup
        :fields="fields"
        :force-active="forceActive"
        :overheading="{
            text: 'Wizyta',
            icon: 'menu',
        }"
        :title="mode === 'edit' ? 'Edytuj wizytę' : 'Dodaj nową wizytę'"
        custom-class="top-5 md:top-8 right-5 md:right-8"
    >
        <template v-slot="slotProps">
            <FormulateForm
                v-if="mode !== 'edit' || slotProps.formData"
                ref="formulateForm"
                v-model="slotProps.formData"
                :errors="slotProps.errors"
                :values="slotProps.formData"
                class="form"
                @input="slotProps.change"
                @submit="
                    (data) => {
                        if (sendForm(data, slotProps.send))
                            slotProps.closePopup();
                    }
                "
            >
                <div class="grid grid-cols-2 gap-16">
                    <div class="flex flex-col gap-6">
                        <label class="hidden">
                            <FormulateInput
                                v-model="patientUserId"
                                :value="id"
                                class="input-text"
                                name="patientUserId"
                                type="hidden"
                            ></FormulateInput>
                        </label>
                        <label class="row-span-1 hidden">
                            <custom-label>Oddział:</custom-label>
                            <custom-select
                                :default-value="departmentID"
                                :options="mappedDepartments"
                                class="w-full"
                                hidden
                                name="departmentId"
                                placeholder="Przypisz oddział"
                            ></custom-select>
                        </label>
                        <label class="row-span-1">
                            <custom-label>Wybierz rodzaj wizyty:</custom-label>
                            <custom-tree-select
                                name="visitTypeId"
                                :options="mappedVisitsTypes"
                                @update-form="updateVisitType"
                                :validation-messages="{
                                    isType: requireMessage('typ wizyty'),
                                }"
                                :validation-rules="{
                                    isType: validateVisitType,
                                }"
                                validation="isType"
                                validation-name="Pole wizyty"
                            >
                            </custom-tree-select>
                        </label>
                        <label class="row-span-1">
                            <div class="flex gap-2">
                                <custom-label>Wybierz lekarza:</custom-label>
                                <p class="text-xs mt-0.5" v-if="!visitTypeId">
                                    (Wybierz najpierw rodzaj wizyty)
                                </p>
                            </div>
                            <div
                                :class="
                                    visitTypeId ||
                                    'opacity-25 pointer-events-none'
                                "
                            >
                                <custom-select
                                    :default-value="
                                        isEdit
                                            ? slotProps.formData.doctorUserId
                                            : doctorId
                                    "
                                    :options="mappedDoctors"
                                    :validation-messages="{
                                        isDoctor: requireMessage('lekarz'),
                                    }"
                                    :validation-rules="{
                                        isDoctor: validateDoctor,
                                    }"
                                    class="w-full"
                                    name="doctorUserId"
                                    placeholder="Przypisz doktora"
                                    validation="isDoctor"
                                    validation-name="Pole lekarza"
                                    @update-form="updateDoctor"
                                ></custom-select>
                            </div>
                        </label>
                        <label class="row-span-1 col-span-1">
                            <custom-label>Status:</custom-label>
                            <custom-select
                                :default-value="
                                    isEdit
                                        ? slotProps.formData.visitStatusId
                                        : statusID
                                "
                                :options="mappedStatuses"
                                :validation-messages="{
                                    isStatus: requireMessage('status'),
                                }"
                                :validation-rules="{
                                    isStatus: validateStatus,
                                }"
                                class="w-full"
                                name="visitStatusId"
                                placeholder="Wybierz"
                                validation="isStatus"
                                validation-name="Pole Status"
                            ></custom-select>
                        </label>
                        <label class="row-span-1">
                            <custom-label>Zródło wizyty:</custom-label>
                            <custom-select
                                v-model="source"
                                :default-value="
                                    isEdit ? slotProps.formData.source : source
                                "
                                :options="[
                                    {
                                        label: 'E-mail',
                                        value: 'mail',
                                    },
                                    { label: 'Telefon', value: 'phone' },
                                    {
                                        label: 'Rejestracja bezpośrednia',
                                        value: 'directly',
                                    },
                                    {
                                        label: 'Strona internetowa',
                                        value: 'www',
                                    },
                                ]"
                                :validation-messages="{
                                    isSource: requireMessage('źródło'),
                                }"
                                :validation-rules="{
                                    isSource: validateSource,
                                }"
                                class="w-full"
                                name="source"
                                placeholder="Wybierz"
                                validation="isSource"
                                validation-name="Pole źródło"
                            ></custom-select>
                        </label>
                    </div>
                    <div class="flex flex-col gap-6">
                        <label class="row-span-1">
                            <custom-label
                                >Wybierz datę i godzinę wizyty:
                            </custom-label>
                            <div class="relative w-full">
                                <FormulateInput
                                    ref="formulateInput"
                                    :default-value="
                                        isEdit
                                            ? slotProps.formData.startTime
                                            : fullDate
                                    "
                                    :validation-messages="{
                                        isValidDate: isValidDateMessage,
                                    }"
                                    :validation-rules="{
                                        isValidDate: validateDateTime,
                                    }"
                                    class="absolute right-0 w-full"
                                    name="startTime"
                                    type="hidden"
                                    validation="isValidDate"
                                ></FormulateInput>
                            </div>
                            <custom-datepicker
                                :change-on-init="false"
                                :date-input="
                                    isEdit ? mutatedDate : dateInputValue
                                "
                                :default-value="slotProps.formData"
                                :form="$refs.formulateForm"
                                :mode="mode"
                                :show-date-picker="true"
                                :show-time-picker="true"
                                :time-input="
                                    isEdit ? mutatedTime : timeInputValue
                                "
                                name="startTime"
                                @update-date="updateDate"
                                @update-time="updateTime"
                            >
                                >
                            </custom-datepicker>
                        </label>

                        <label class="row-span-5">
                            <custom-label
                                >Dodatkowe informacje (uwagi):
                            </custom-label>
                            <FormulateInput
                                class="input-textarea"
                                name="note"
                                placeholder="Dodatkowe informacje"
                                type="textarea"
                            />
                        </label>
                    </div>
                </div>

                <ul class="mt-1 sm:mt-4 hidden">
                    <li
                        class="flex justify-between border-b border-b-blue-500 border-opacity-10 text-xs sm:text-base py-2 sm:py-4"
                    >
                        <span>dr Joanna Nowak</span>
                        <span class="text-green-500">Dostępny</span>
                    </li>
                    <li
                        class="flex justify-between border-b border-b-blue-500 border-opacity-10 text-xs sm:text-base py-2 sm:py-4"
                    >
                        <span>dr Mateusz Urbański</span>
                        <span class="text-green-500">Dostępny</span>
                    </li>
                    <li
                        class="flex justify-between border-b border-b-blue-500 border-opacity-10 text-xs sm:text-base py-2 sm:py-4"
                    >
                        <span class="opacity-50">dr Anna Wilk</span>
                        <span class="text-red-500">Dostępny o 18:45</span>
                    </li>
                    <li
                        class="flex justify-between border-b border-b-blue-500 border-opacity-10 text-xs sm:text-base py-2 sm:py-4"
                    >
                        <span class="opacity-50">dr Olga Musiałowska</span>
                        <span class="text-red-500">Przerwa urlopowa</span>
                    </li>
                </ul>
                <div
                    v-if="mode == 'edit'"
                    class="flex justify-between gap-8 mt-6 sm:mt-8 lg:mt-12"
                >
                    <custom-button
                        type="empty"
                        @click="slotProps.closePopup"
                    >
                        ANULUJ
                    </custom-button>

                    <FormulateInput
                        :disabled="isLoading"
                        :label="isLoading ? '...' : 'ZAPISZ'"
                        class="input-submit input-submit--filled"
                        type="submit"
                    />
                </div>
                <div
                    v-else-if="mode == 'create'"
                    class="flex justify-center mt-6 sm:mt-8 lg:mt-12"
                >
                    <FormulateInput
                        :disabled="isLoading"
                        :label="isLoading ? '...' : 'POTWIERDŹ WIZYTĘ'"
                        class="input-submit input-submit--filled input-submit--wider"
                        type="submit"
                    />
                </div>
            </FormulateForm>
        </template>
    </edit-popup>
</template>

<script>
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import EditPopup from "@/components/v2/EditPopup";
import CustomDatepicker from "@/elements/v2/form/CustomDatepicker";
import CustomButton from "@/elements/v2/CustomButton";
import CustomSelect from "@/elements/v2/form/CustomSelect";
import CustomTreeSelect from "@/elements/v2/form/CustomTreeSelect";
import CustomLabel from "@/elements/v2/form/CustomLabel";
import DetailsForm from "@/components/v1/Visit/DetailsForm";

dayjs.extend(isBetween);

export default {
    mixins: DetailsForm.mixins,
    components: {
        EditPopup,
        CustomLabel,
        CustomButton,
        CustomSelect,
        CustomDatepicker,
        CustomTreeSelect,
    },
    computed: {
        mappedVisitsTypes() {
            return this.groupVisitTypes(this.allVisitsTypes);
        },
    },
    methods: {
        checkCancellation(visits){
            return visits.filter((x) => x.status.notBooking === 0)
        },
    }
};
</script>
