import {value} from "lodash/seq";

export const numbersOptions = [
    {
        value: 0,
        label: "Brak numeru telefonu",
    },
    {
        value: 1,
        label: "1 numer telefonu",
    },
    {
        value: 2,
        label: "2 numery telefonu",
    },
];

export const langOptions = [
    {
        value: 1,
        label: "Polski",
        id: 1,
    },
    {
        value: 2,
        label: "Angielski",
        id: 2,
    },
    {
        value: 3,
        label: "Ukraiński",
        id: 3
    },
];

export const sourceOptions = [
    {
        value: "facebook",
        label: "Facebook",
        __original: 'facebook',
        id: 'facebook'
    },
    {
        value: "formularz_konsultacyjny",
        label: "Formularz konsultacyjny",
        __original: 'formularz_konsultacyjny',
        id: 'formularz_konsultacyjny'
    },
    {
        value: "instagram",
        label: "Instagram",
        __original: 'instagram',
        id: 'instagram'
    },
    {
        value: "telefon",
        label: "Telefon",
        __original: 'telefon',
        id: 'telefon'
    },
    {
        value: "email",
        label: "Email",
        __original: 'email',
        id: 'email'
    },
];

export const genderOptions = [
    {
        value: "m",
        label: "Mężczyzna",
        __original: 'm',
        id: 'm'
    },
    {
        value: "f",
        label: "Kobieta",
        __original: 'f',
        id: 'f'
    },
];
