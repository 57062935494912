<template>
    <aside
        class="fixed z-30 top-0 left-0 h-screen hidden sm:flex flex-col bg-white transition-all overflow-hidden"
        :class="{ 'w-16': isFolded, 'w-64': !isFolded }"
    >
        <div class="grid grid-cols-2 gap-y-1.5 gap-x-1 w-5 my-10 mx-6">
            <span class="w-1.5 h-1.5 rounded-full bg-green-400"></span>
            <span class="w-1.5 h-1.5 rounded-full bg-green-400"></span>
            <span class="w-1.5 h-1.5 rounded-full bg-green-400"></span>
            <span class="w-1.5 h-1.5 rounded-full bg-green-400"></span>
        </div>

        <ul class="flex flex-col gap-12 mt-5">
            <li class="relative" v-for="(item, key) in items" :key="key">
                <navigation-item
                    v-if="item.access"
                    :item="item"
                    :folded="isFolded"
                ></navigation-item>
            </li>
        </ul>

        <div
            class="absolute border-r border-gray-300 h-full top-0 right-px -z-10"
        ></div>

        <div class="mt-auto mb-10 mx-4">
            <button
                class="w-8 h-8 flex items-center justify-center text-white rounded-lg bg-green-400"
                @click="isFolded = !isFolded"
            >
                <div
                    class="transform origin-center"
                    :class="{ 'rotate-90': isFolded, '-rotate-90': !isFolded }"
                >
                    <custom-icon icon="chevron" :w="12" :h="12" />
                </div>
            </button>
        </div>
    </aside>
</template>

<script>
import AppSidebar from "@/components/v1/Navigation/AppSidebar";
import NavigationItem from "@/components/v2/Navigation/NavigationItem";
import CustomIcon from "@/elements/v2/CustomIcon";

export default {
    mixins: AppSidebar.mixins,
    components: { NavigationItem, CustomIcon },
    data: () => ({
        isFolded: true,
    }),
};
</script>
