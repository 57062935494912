<template>
    <div class="relative h-max">
        <custom-search @search="search" :search-on-click="true" />
        <div class="absolute right-14 top-3">
            <div class="w-full relative">
                <button
                    class="relative z-10 w-[106px] pr-1.5 pl-3 h-6 flex items-center border-gray-300 border-l cursor-pointer bg-white text-gray-500 text-[13px]"
                    :class="selected ? 'justify-between' : 'justify-center'"
                    @click="isOpen = !isOpen"
                >
                    {{ selected ? selected.label : "" }}

                    <custom-icon
                        icon="chevron"
                        :w="9"
                        :h="9"
                        class="origin-center opacity-60 hover:opacity-100 text-gray-500"
                        :class="{ 'transform rotate-180': isOpen }"
                    />
                </button>
                <div
                    v-if="isOpen"
                    class="absolute top-auto left-0 w-56 z-20 py-2 mt-2 rounded-lg border-gray-900 bg-white shadow-xl"
                >
                    <button
                        v-for="listing in listings"
                        :key="listing.value"
                        @click="onListingSelect(listing)"
                        class="text-xs px-3 py-2 cursor-pointer flex items-center gap-2 hover:bg-gray-100 w-full transition-colors"
                        :class="{
                            'pointer-events-none bg-gray-200':
                                selected && listing.value === selected.value,
                        }"
                    >
                        <p>{{ listing.label }}</p>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapActions as mapPiniaActions } from "pinia";
import { useCallsStore } from "@/pinia/calls";

import call from "@/mixins/call";
import CustomIcon from "@/elements/v1/CustomIcon";
import CustomSearch from "@/elements/v1/form/CustomSearch";
import { searchListings } from "@/lib/constants";
import { debounce } from "@/lib/utils";

export default {
    mixins: [
        {
            name: "ListingsSearch",
            components: {
                CustomSearch,
                CustomIcon,
            },
            mixins: [call],
            props: {
                status: {
                    type: String,
                    require: true,
                },
            },
            data() {
                return {
                    needle: "",
                    isOpen: false,
                    listings: searchListings,
                    selected: searchListings[0],
                };
            },
            created() {
                this.debouncedSearch = debounce(() => {
                    const { action } = this.selected;
                    if (action === "searchCalls") {
                        useCallsStore().setSearchNeedle(this.needle);
                        this.searchPatients("");
                    }

                    if (action === "searchPatients") {
                        this.searchPatients(this.needle);
                        useCallsStore().setSearchNeedle("");
                    }
                }, 1000);
            },
            methods: {
                ...mapPiniaActions(useCallsStore, ["setSearchNeedle"]),
                ...mapActions("calls", [
                    "addFilter",
                    "removeFilter",
                    "searchCalls",
                ]),
                ...mapActions("patients", ["searchPatients"]),
                search(value) {
                    this.needle = value;
                    this.debouncedSearch();

                    const routeName = this.selected.route;
                    if (this.$route.name !== routeName)
                        this.$router.push({ name: routeName });
                },
                onListingSelect(status) {
                    const statusObj = {
                        ...status,
                        handling_status: this.status,
                    };
                    if (status.label === this.selected?.label) {
                        this.removeFilter(statusObj);
                    } else {
                        this.addFilter(statusObj);
                    }

                    this.selected =
                        status.label === this.selected?.label ? null : status;
                    this.isOpen = false;
                },
            },
        },
    ],
};
</script>
