import * as api from "@/api";

const VisitsTypesActions = {
    FETCH_VISITS_TYPES: "fetchVisitsTypes",
};

const VisitsTypesMutations = {
    SET_VISITS_TYPES: "setVisitsTypes",
};

const actions = {
    [VisitsTypesActions.FETCH_VISITS_TYPES]: async ({ commit }) => {
        const { data } = await api
            .fetchVisitsTypes()
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsTypesMutations.SET_VISITS_TYPES, data.data.data);
    },
};
const mutations = {
    [VisitsTypesMutations.SET_VISITS_TYPES](state, payload) {
        state.visitsTypes = payload;
    },
};

const getters = {
    allVisitsTypes: (state) => state.visitsTypes,
    singleVisitType: (state) => (searchID) =>
        state.visitsTypes.find((visitType) => +visitType.id === +searchID),
};

const state = {
    visitsTypes: [],
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
