import * as api from "@/api";

const DictionariesActions = {
    FETCH_DICTIONARIES: "fetchDictionaries",
    UPDATE_SELECT: 'updateSelect'
};

const DictionariesMutations = {
    SET_DICTIONARIES: "setDictionaries",
    SET_PATIENT_SOURCES: "setPatientSources",
    SET_SELECT: "setSelect"
};

const actions = {
    [DictionariesActions.FETCH_DICTIONARIES]: async ({ commit }) => {
        const { data } = await api
            .getDicts()
            .catch((e) => Promise.reject(e.response.data));
        commit(DictionariesMutations.SET_DICTIONARIES, data.data.metafields);
        commit(DictionariesMutations.SET_PATIENT_SOURCES, data.data.patientSources);
    },
    [DictionariesActions.UPDATE_SELECT]: async ({ commit }, payload) => {
        commit(DictionariesMutations.SET_SELECT, payload);
    },
};
const mutations = {
    [DictionariesMutations.SET_DICTIONARIES](state, payload) {
        state.dictionaries = payload;
    },
    [DictionariesMutations.SET_PATIENT_SOURCES](state, payload) {
        state.patientSources = payload;
    },

    [DictionariesMutations.SET_SELECT](state, payload) {
        state.select = payload;
    },
};

const getters = {
    additionalFields: (state) => state.dictionaries,
    patientSources: (state) => state.patientSources,
    select: (state) => state.select
};

const state = {
    dictionaries: null,
    patientSources: null,
    select: ""
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
