import { route } from "../helpers";

export default [
    route("/", "Switchboard"),
    route("/calls", "Calls"),
    route("/patients", "Patients"),
    {
        ...route("/visits", "Visits"),
        children: [
            route("/visits-calendar", "VisitCalendar"),
            route("/visits-archive", "VisitCalendar"),
        ],
    },
    route("/messages/sms", "MessagesSms"),
    route("/statistics", "Statistics"),
    route("/statistics/visit-stats", "StatisticsVisits"),
    route("/statistics/calls-stats", "StatisticsCalls"),
    route("/statistics/employee-stats", "StatisticsEmployee"),
    route("/statistics/sales-stats", "StatisticsSales"),
    route("/patients/:id", "Patient", { props: true }),
    route("/visits/:id", "Visit", { props: true }),
    route("/create-visit/:id", "CreateVisit"),
    {
        ...route("/settings", "Settings"),
        children: [route("/settings/survey", "SurveyResults")],
    },
    route("/support", "SupportPage"),
].map((el) => ({
    ...el,
    meta: {
        ...el.meta,
        requiresAuth: true,
    },
    children: el.children?.map((child) => ({
        ...child,
        meta: {
            ...child.meta,
            requiresAuth: true,
        },
    })),
}));
