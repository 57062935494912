<template>
    <div class="hidden xl:flex items-center gap-5 2xl:gap-8 text-blue-500  z-20">
        <div class="flex gap-5 items-center">
            <button
                class="button button--main-green"
                @click="openPatientForm()"
                type="empty"
            >
                Dodaj pacjenta
            </button>

            <details-form-patient
                :force-active="detailsFormPatientActive"
                mode="create"
                :show-icon="false"
            ></details-form-patient>

            <!--            TODO TEMPORARY HIDDEN - form does not contain patient ID selector-->
            <!--            <button-->
            <!--                class="text-sm pt-8 mb-8 border-y-4 border-transparent hover:border-t-green-400 font-bold"-->
            <!--                @click="openVisitForm()"-->
            <!--                type="empty"-->
            <!--            >-->
            <!--                Dodaj wizytę-->
            <!--            </button>-->
            <!--            <details-form-visit-->
            <!--                :force-active="detailsFormVisitActive"-->
            <!--                mode="create"-->
            <!--                :show-icon="false"-->
            <!--            ></details-form-visit>-->

            <listing-search />
        </div>
        <div class="flex gap-3">
            <notifications-dropdown />
            <phoneline-select />
        </div>

        <status-checkbox />

        <button @click="$emit('logout')" class="button button--second-gray">
            Wyloguj się
        </button>
    </div>
</template>

<script>
import AppNavbarDesktop from "@/components/v1/Navigation/AppNavbarDesktop";
import CustomIcon from "@/elements/v2/CustomIcon";
import ListingSearch from "@/elements/v2/form/ListingsSearch";
import DetailsFormVisit from "@/components/v2/Visit/DetailsForm";
import DetailsFormPatient from "@/components/v2/Patient/DetailsForm";
import PhonelineSelect from "@/components/v2/Switchboard/PhonelineSelect";
import StatusCheckbox from "@/components/v2/Switchboard/StatusCheckbox";
import NotificationsDropdown from "@/components/v2/Navigation/NotificationsDropdown";

export default {
    mixins: AppNavbarDesktop.mixins,
    components: {
        CustomIcon,
        ListingSearch,
        DetailsFormVisit,
        DetailsFormPatient,
        PhonelineSelect,
        StatusCheckbox,
        NotificationsDropdown,
    },
};
</script>
