import _ from 'lodash';
import CallService from '../services/CallService';
import CallGroupService from '../services/CallGroupService';
import { handleSuccess, handleErrors } from '../lib/notifications';
import { CallData, CallGroupData, UserData } from '../types';

export const callsActions = {
    clearCalls() {
      this.calls = [];
      this.callGroups = {};
      this.users = {};
    },

    updateRelationsInStore(call: CallData) {
      if (call.group) {
        this.callGroups[call.group?.id] = call.group;
      }
      if (call.patient) {
        this.users[call.patient?.id] = call.patient;
      }
      if (call.employee) {
        this.users[call.employee?.id] = call.employee;
      }
    },

    async hangupCall(callId: number) {
        return CallService.hangupCall(callId).then(response => {
          handleSuccess(response.data.message);
        }).catch(error => {
          handleErrors(error);
        });
    },

    async makeCall(phone: string, callId: number) {
      return CallService.makeCall(phone, callId).then(response => {
        handleSuccess(response.data.message);
      }).catch(error => {
        handleErrors(error);
      });
    },

    async updateCallGroup(groupId: number, data: Partial<CallGroupData>) {
      await CallGroupService.updateCallGroup(groupId, data)
        .then(response => {
          handleSuccess(response.message)
          this.updateCallGroupInStore(response.data.item);
        })
        .catch(error => {
          handleErrors(error);
        });
    },

    updateCallsInStore(calls: CallData[]) {
      calls.forEach(updatedCall => {
        const index = this.calls.findIndex((call: CallData) => call.id === updatedCall.id);
        if (index !== -1) {
          this.calls[index] = updatedCall;
          this.updateRelationsInStore(updatedCall);
        }
      });
    },

    async updateCallsByIdsInStore(ids: number[]) {
      const existingCallIds = this.calls.filter((call: CallData) => ids.includes(call.id)).map((call: CallData) => call.id);
      if (existingCallIds.length === 0) return;

      const updatedCalls = await CallService.fetchCalls({filters: {id: existingCallIds}});

      this.updateCallsInStore(updatedCalls.data.data.data);
    },

    updateCallInStore(updatedCall: CallData) {
      this.updateCallsInStore([updatedCall]);
    },

    addCallToStore(newCall: CallData) {
      this.calls.push(newCall);
      this.updateRelationsInStore(newCall);
    },

    updateCallGroupInStore(updatedGroup: CallGroupData) {
      this.callGroups[updatedGroup.id] = updatedGroup;

      // Update all calls belonging to this group
      this.calls = this.calls.map((call: CallData) => {
        if (call.group?.id === updatedGroup.id) {
          return { ...call, group: updatedGroup };
        }
        return call;
      });
    },

    deleteUserInStore(deletedUser: UserData) {
      if (this.users[deletedUser.id ]) {
        delete this.users[deletedUser.id];
      }

      // Update patients and employees in calls
      this.calls = this.calls.map((call: CallData) => {
        if (call.patient && call.patient.id === deletedUser.id) {
          return { ...call, patient: null };
        }
        if (call.employee && call.employee.id === deletedUser.id) {
          return { ...call, employee: null };
        }
        return call;
      });
    },

    updateUserInStore(updatedUser: UserData, upsert: boolean = false) {
      if (this.users[updatedUser.id]) {
        this.users[updatedUser.id] = updatedUser;
      } else if (upsert) {
        this.users[updatedUser.id] = updatedUser;
      }

      // Update patients and employees in calls
      this.calls = this.calls.map((call: CallData) => {
        if (call.patient && call.patient.id === updatedUser.id) {
          return { ...call, patient: updatedUser };
        }
        if (call.employee && call.employee.id === updatedUser.id) {
          return { ...call, employee: updatedUser };
        }
        return call;
      });
    },

    findCallById(id: number): CallData | undefined {
      return this.calls.find((call: CallData) => call.id == id);
    },

    setCurrentCallId(callId: number | null) {
      this.currentCallId = callId;
    },
  };

export const callsGetters = {
    currentCall: (state): CallData | undefined => {
      return state.calls.find((call: CallData) => call.id === state.currentCallId);
    },
  }
