import axiosClient from "../apiClient";

export function getOverallPatients(timeSlot, newPatients) {
    const params = new URLSearchParams();
    if (!newPatients) {
        params.append('action', 'count_all');
    } else {
        params.append('action', 'count_new');
    }
    if (timeSlot) {
        params.append('time_slot', timeSlot);
    }
    return axiosClient.get(`/clinic/users-stats/?${params.toString()}`);
}

