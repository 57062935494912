export default {
    created() {
        document.addEventListener("keyup", this.escClick);
    },
    methods: {
        escClick(evt) {
            if (evt.key === "Escape" && this.close) this.close();
        },
    },
    beforeUnmount() {
        document.removeEventListener("keyup", this.escClick);
    },
};
