<template>
    <div class="absolute top-1 right-0">
        <button
            @click="$emit('open')"
            class="px-2 opacity-50 hover:opacity-75 transition-opacity"
        >
            <custom-icon :icon="icon" :w="16" :h="16" />
        </button>
    </div>
</template>

<script>
import CustomIcon from "./CustomIcon";

export default {
    mixins: [
        {
            name: "EditButton",
            components: { CustomIcon },
            props: {
                icon: String,
            },
        },
    ],
};
</script>
