<template>
    <aside
        class="relative hidden xl:flex flex-col justify-between bg-white rounded-xl"
    >
        <ul
            class="sticky top-4 w-64 2xl:w-80 h-full flex flex-col gap-3 py-6 2xl:py-10 px-6 2xl:px-9"
        >
            <li class="relative" v-for="(item, key) in items" :key="key">
                <navigation-item :item="item"></navigation-item>
            </li>
        </ul>

        <app-footer />
    </aside>
</template>

<script>
import NavigationItem from "./NavigationItem";
import AppFooter from "./AppFooter";
import { getNavigation } from "@/mixins/navigation";

export default {
    mixins: [
        {
            name: "AppSidebar",
            mixins: [getNavigation],
            components: { NavigationItem, AppFooter },
        },
    ],
};
</script>
