<template>
    <div class="font-sora overflow-hidden min-h-screen" id="app-v2">
        <div v-if="impersonate.is_active" class="absolute top-0 h-[30px] w-full bg-gray-400 z-50 text-center flex justify-center">
            <p class="flex justify-center items-center">Przeglądasz stronę jako: {{user.email}}. <a class="text-blue-900 hover:text-white ml-1" :href="impersonate.leave_url">Opuść tryb impersonacji</a></p>
        </div>
        <notifications
            group="general"
            position="top right"
            animation-type="css"
        />
        <bug-report-popup></bug-report-popup>

        <template v-if="isAuth">
            <app-navbar />
            <div class="flex gap-2 sm:gap-4 bg-gray-100 bg-opacity-50">
                <app-sidebar />
                <router-view
                    class="px-4 sm:pl-24 sm:pr-8 pt-8 custom-scrollbar"
                />
            </div>
        </template>
        <template v-else>
            <app-navbar-unauth />
            <router-view class="custom-scrollbar" />
        </template>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppNavbar from "@/components/v2/Navigation/AppNavbar";
import AppSidebar from "@/components/v2/Navigation/AppSidebar";
import AppNavbarUnauth from "@/components/v2/Navigation/AppNavbarUnauth";
import SwitchboardPopup from "@/components/v1/SwitchboardPopup";
import BugReportPopup from "@/components/v1/BugReportPopup";

export default {
    name: "App",
    components: {
        AppNavbar,
        AppSidebar,
        AppNavbarUnauth,
        SwitchboardPopup,
        BugReportPopup,
    },
    computed: {
        ...mapGetters("auth", ["isAuth", "user"]),
    },
    data: () => ({
        impersonate: null
    }),
    created(){
        this.impersonate = window.app_settings.impersonate
    }
};
</script>
