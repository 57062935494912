<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12.118"
        height="16.193"
        viewBox="0 0 12.118 16.193"
    >
        <g transform="translate(-8.017 -4.97)" fill="currentColor">
            <path
                data-name="Path 5997"
                d="M8.018 13.052V7.176a2.042 2.042 0 0 1 2.18-2.2c1.535-.006 3.071 0 4.609 0a1.7 1.7 0 0 1 1.286.527A451.091 451.091 0 0 0 19.62 9.03a1.655 1.655 0 0 1 .514 1.248c0 2.9.006 5.806-.006 8.708a2.012 2.012 0 0 1-2.228 2.176h-7.7a2.016 2.016 0 0 1-2.183-2.19c-.002-1.972.001-3.948.001-5.92Zm11.127-2.027h-.482c-.986 0-1.973.01-2.962 0a1.5 1.5 0 0 1-1.583-1.6c0-1-.019-2 .006-3 .01-.386-.124-.472-.485-.466-1.184.022-2.368.006-3.553.01A.992.992 0 0 0 8.96 7.07v12.011a1 1 0 0 0 1.124 1.111h7.91c.836 0 1.171-.335 1.171-1.178v-7.53a4.15 4.15 0 0 0-.02-.459Zm-.118-.98C17.709 8.714 16.4 7.4 15.088 6.071c0 1.152-.016 2.346.019 3.537a.609.609 0 0 0 .425.418c1.178.035 2.356.019 3.495.019Z"
            />
            <circle
                data-name="Ellipse 13"
                cx="2.5"
                cy="2.5"
                r="2.5"
                transform="translate(11.658 12.58)"
            />
        </g>
    </svg>
</template>
