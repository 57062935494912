<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.5 3a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1v7.5H3a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h7.5V21a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-7.5H21a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-7.5V3Z"
            fill="fillCurrent"
        />
    </svg>
</template>
