<template>
    <div
        class="w-full sm:w-96 h-10 sm:h-12 border border-gray rounded-lg sm:rounded-2xl flex justify-between items-center overflow-hidden"
    >
        <input
            class="w-full h-full pl-2 sm:pl-5 pb-1.5 sm:pb-0 focus:outline-none placeholder:text-xs sm:placeholder:text-sm"
            type="text"
            :placeholder="placeholder"
            v-model="value"
            @keyup.enter="search"
        />
        <custom-icon
            @click="search"
            class="mr-3 sm:mr-5 cursor-pointer p-1"
            :class="{
                'opacity-50 pointer-events-none': value.length < 3,
                'pointer-events-none': !searchOnClick,
            }"
            icon="search"
            :w="25"
            :h="25"
        />
    </div>
</template>

<script>
import CustomIcon from "@/elements/v1/CustomIcon";

export default {
    mixins: [
        {
            name: "CustomSearch",
            data: () => ({
                value: "",
            }),
            props: {
                placeholder: {
                    type: String,
                    default: "Wyszukaj...",
                    require: false,
                },
                searchOnClick: {
                    type: Boolean,
                    default: false,
                },
                searchOnType: {
                    type: Boolean,
                    default: true,
                    require: false,
                },
            },
            components: { CustomIcon },
            methods: {
                search() {
                    this.$emit("search", this.value);
                },
            },
            watch: {
                value(next) {
                    if (next.length === 0) {
                        this.$emit("search", null);
                    }
                    if (next.length > 2 && this.searchOnType) {
                        this.$emit("search", this.value);
                    }
                },
            },
        },
    ],
};
</script>
