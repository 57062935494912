<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 512 512"
    >
        <path
            d="M468.1 43.9C446.9 22.7 418.7 11 388.8 11c-30 0-58.2 11.7-79.4 32.9l-261 261c-2.9 2.9-4.8 6.5-5.6 10.5L11.4 476.7c-1.3 6.7 1.1 13.3 5.6 18.3 6.7 7.6 17 5.9 18.3 5.6l161.3-31.4c4-.8 7.7-2.7 10.5-5.6l261-261c43.8-43.7 43.8-115 0-158.7zM182.7 430.3 57.3 454.8l24.4-125.4 207.1-207.1 101 101-207.1 207zm256.6-256.5-20.6 20.6-101-101 20.6-20.6c45.6-40 85.8-15.2 101 0 27.8 27.8 27.8 73.1 0 101z"
        />
    </svg>
</template>
