import axiosClient from "../api/apiClient";

export default {
    async updateCallGroup(groupId, data) {
        const response = await axiosClient.patch(`/clinic/call-groups/${groupId}`, data);
        return response.data;
    },

    metafieldValue(group, metafield) {
        return group.metafields.find(m => m.metafield.id == metafield.id)?.metafieldSelectableValue;
    }
};