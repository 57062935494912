import { mapGetters, mapActions } from "vuex";

export function getItemFromStoreOrFetchGroup(storeModule, getter, action) {
    return {
        data: () => ({
            item: {},
            items: [],
            loading: true,
        }),
        computed: {
            ...mapGetters(storeModule, [getter]),
        },
        methods: {
            ...mapActions(storeModule, [action]),
            async getAsyncItem(itemID) {
                if (!this[getter](itemID)) {
                    await this[action]();
                }
                this.loading = false;
                this.item = this[getter](itemID);
            },
            async getAsyncItems(payload, refetch = false) {
                if (refetch || !this[getter]) {
                    await this[action](payload);
                }
                this.items = this[getter];
                this.loading = false;
            },
        },
        watch: {
            [getter](next) {
                this.items = next;
            },
        },
    };
}
