<template>
    <div class="relative">
        <button
            @click.stop="active = !active"
            class="rounded-lg bg-gray-100 flex gap-1.5 items-center justify-center w-12 h-10"
        >
            <custom-icon icon="phoneEmpty" :w="16" :h="16" />
            <custom-icon
                class="transform sm:origin-center"
                :class="{ 'rotate-180': !active }"
                icon="chevron"
                :w="8"
                :h="8"
            />
            <div
                class="w-1.5 h-1.5 absolute top-2 right-6 rounded-full"
                :class="login && login.sipUser ? 'bg-green-500' : 'bg-red-500'"
            ></div>
        </button>

        <div v-show="active" class="absolute top-full pt-3 left-0 z-30">
            <div
                class="text-left shadow border-gray-200 text-sm flex flex-col bg-white rounded-lg py-3 min-w-[10rem]"
            >
                <div class="whitespace-nowrap px-3">Wybierz telefon</div>
                <ul
                    v-click-outside="() => active && closeDropdown()"
                    class="mt-2 flex flex-col gap-1"
                >
                    <li
                        v-for="{ username, id } in logins"
                        :key="id"
                        class="py-0.5 0"
                    >
                        <div
                            @click="updateLogin(id, false)"
                            class="flex items-center gap-2 px-3 bg-white hover:bg-gray-100 cursor-pointer"
                        >
                            <div
                                class="w-1.5 h-1.5 bg-blue-500 rounded-full"
                                v-show="
                                    login &&
                                    login.sipUser &&
                                    login.sipUser.id === id
                                "
                            ></div>
                            {{ username }}
                        </div>

                        <div class="text-xs px-3 mt-1.5" v-show="taken === id">
                            <template
                                v-if="
                                    login &&
                                    login.sipUser &&
                                    id === login.sipUser.id
                                "
                            >
                                Jesteś przypisany do tej kolejki. Czy chcesz
                                połączyć się ponownie?
                            </template>

                            <template v-else>
                                Kolejka <b>{{ username }}</b> jest zajęta. Czy
                                chcesz ja przejąć?
                            </template>

                            <div class="mt-1 grid grid-cols-2 gap-2 text-white">
                                <button
                                    @click="
                                        () => {
                                            updateLogin(id, true);
                                            taken = false;
                                        }
                                    "
                                    class="bg-green-400 w-full p-1 opacity-80 hover:opacity-100 transition-colors"
                                >
                                    TAK
                                </button>
                                <button
                                    @click="taken = false"
                                    class="bg-red-400 w-full p-1 opacity-80 hover:opacity-100 transition-colors"
                                >
                                    NIE
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import PhonelineSelect from "@/components/v1/Switchboard/PhonelineSelect";
import CustomIcon from "@/elements/v2/CustomIcon";

export default {
    mixins: PhonelineSelect.mixins,
    components: {
        CustomIcon,
    },
};
</script>
