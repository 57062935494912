<template>
    <div>
        <button
            class="text-left relative cursor-pointer border w-max text-blue-400 focus:outline-none rounded-xl sm:rounded-2xl h-10 sm:h-12 px-6 border-blue-400 text-xs sm:text-base"
        >
            <input
                class="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0"
                type="file"
                @change="changeInput"
            />

            <span v-if="this.file">{{ this.file.name }}</span>
            <span v-else class="flex items-center gap-2 text-blue-400">
                <div
                    class="w-5 h-5 bg-blue-400 rouded-full flex items-center rounded-full justify-center"
                >
                    <custom-icon
                        class="fill-white"
                        icon="plus"
                        :w="12"
                        :h="12"
                    ></custom-icon>
                </div>
                Wgraj plik
            </span>
        </button>
    </div>
</template>

<script>
import CustomIcon from "@/elements/v2/CustomIcon";
import CustomInputFile from "@/elements/v1/form/CustomInputFile";

export default {
    components:{
        CustomIcon
    },
    mixins: CustomInputFile.mixins,
};
</script>
