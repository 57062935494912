<template>
    <div>
        <button v-if="!buttonType || mode === 'edit'"
                class="group relative w-12 h-12 bg-gray-100 rounded-2xl flex items-center justify-center hover:bg-blue-600"
                @click="$emit('open')"
        >
            <custom-icon :h="16" :w="16" class="fill-blue-600 group-hover:fill-white" icon="pencil"/>
        </button>
        <div v-else @click="$emit('open')">
            <custom-button :type="buttonType">Dodaj informacje</custom-button>
        </div>
    </div>
</template>

<script>
import CustomIcon from "@/elements/v2/CustomIcon";
import CustomButton from "@/elements/v2/CustomButton.vue";

export default {
    components: {
        CustomButton,
        CustomIcon,
    },
    props: {
        buttonType: String,
        mode: String
    }
};
</script>
