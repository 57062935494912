<template>
    <button
        type="button"
        @click="toggle"
        class="w-10 h-6 rounded-3xl bg-gray-75 bg-gray-100 p-[3px]"
    >
        <div
            class="h-4 w-4 rounded-full transition-color transition-transform transform"
            :class="{
                'bg-gray-400': !active,
                'bg-green-400 translate-x-4 ': active,
            }"
        ></div>
    </button>
</template>

<script>
export default {
    props: {
        defaultValue: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        active: false,
    }),
    methods: {
        toggle() {
            this.$emit("update", !this.active);
        },
    },
    watch: {
        defaultValue(newVal) {
            this.active = newVal;
        },
    },
};
</script>
