import * as api from "@/api";
import dayjs from "dayjs";

const VisitsActions = {
    FETCH_COUNT_CALLS: "fetchCountCalls",
    FETCH_CALLED_BACK: "fetchCallBack",
    FETCH_AVERAGE_CALL_LENGTH: "fetchAverageCallLength",
    FETCH_AVERAGE_CALL_LENGTH_BY_USER: "fetchAverageCallLengthByUser",
    FETCH_BY_STATUS: "fetchByStatus",
    FETCH_BY_QUEUES: "fetchByQueues",
    UPDATE_QUEUE: "updateQueue",
    UPDATE_QUEUE_DATE: "updateQueueDate",
    FETCH_BY_USER: "fetchByUser",
    UPDATE_USER: "updateUser",
    UPDATE_USER_DATE: "updateUserDate",
    FETCH_HEATMAP: "fetchHeatmap",
    FETCH_ALL_TO_CALL_BACK: "fetchAllToCallBack",
    FETCH_ALL_CALLED_BACK: "fetchAllCalledBack",
    FETCH_AVERAGE_CALL_BACK_TIME: "fetchAverageCallBackTime",
    FETCH_NOT_ANSWERED: "fetchNotAnswered",
    FETCH_ALL_CALLED_BACK_BY_HOURS: "fetchAllCalledBackByHours",
    UPDATE_CALLED_BACK_DATE: "updateCalledBackDate",
    UPDATE_CALLED_BACK_HOUR:"updateCalledBackHour",
    UPDATE_GLOBAL_CALLS_DATE: "updateGlobalDate",
    UPDATE_SELECTED_CALLBACK_DATE: "updateSelectedCallbackDate",
    FETCH_INCOMING_OUTGOING: "fetchIncomingOutgoing",

};

const VisitsMutations = {
    SET_COUNT_CALLS: "setCountCalls",
    SET_CALLED_BACK: "setCalledBack",
    SET_AVERAGE_CALL_LENGTH: "setAverageCallLength",
    SET_AVERAGE_CALL_LENGTH_BY_USER: "setAverageCallLengthByUser",
    SET_ALL_STATUSES: "setAllStatuses",
    SET_ALL_QUEUES: "setAllQueues",
    SET_SELECTED_QUEUE: "setSelectedQueue",
    SET_SELECTED_QUEUE_DATE: "setSelectedQueueDATE",
    SET_ALL_USERS: "setAllUser",
    SET_SELECTED_USER: "setSelectedUser",
    SET_SELECTED_USER_DATE: "setSelectedUserDate",
    SET_HEATMAP: "setHeatmap",
    SET_ALL_TO_CALLED_BACK: "setAllToCalledBack",
    SET_ALL_CALLED_BACK: "setAllCalledBack",
    SET_AVERAGE_CALLED_BACK_TIME: "setAverageCalledBackTime",
    SET_ALL_NOT_ANSWERED_CALLS: "setAllNotAnsweredCalls",
    SET_ALL_CALLED_BACK_ALL_TIME: "setAllCalledBackAllTime",
    SET_ALL_CALLED_BACK_BY_HOURS: "setAllCalledBackByHours",
    SET_CALLS_BACK_DATE: "setCallsBackDate",
    SET_CALLS_BACK_HOUR: "setCallsBackHour",
    SET_GLOBAL_CALLS_DATE: "setGlobalCallsDate",
    SET_SELECTED_CALLBACK_DATE: "setSelectedCallbackDate",
    SET_INC_OUT: "setIncOut"
};

const actions = {
    [VisitsActions.FETCH_COUNT_CALLS]: async ({commit}) => {
        const {data} = await api
            .getCallsStats()
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_COUNT_CALLS, {
            data: data.data.stats,
        });
    },
    [VisitsActions.FETCH_BY_STATUS]: async ({commit}, timeSlot) => {
        const ended = await api
            .getCallsByStatus('ended', timeSlot)
            .catch((e) => Promise.reject(e.response.data));
        const notAnswered = await api
            .getCallsByStatus('not_answered', timeSlot)
            .catch((e) => Promise.reject(e.response.data));
        const rejected = await api
            .getCallsByStatus('rejected', timeSlot)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_ALL_STATUSES, {
            ended: ended.data.data.stats,
            notAnswered: notAnswered.data.data.stats,
            rejected: rejected.data.data.stats
        });
    },
    [VisitsActions.FETCH_AVERAGE_CALL_LENGTH]: async ({commit, state}, timeSlot) => {
        const {data} = await api
            .getAverageCallLength(timeSlot)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_AVERAGE_CALL_LENGTH, {
            data: data.data.stats,
        });
        const calls = await api
            .getCallsStats(timeSlot)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_COUNT_CALLS, {
            data: calls.data.data.stats,
        });
    },
    [VisitsActions.FETCH_AVERAGE_CALL_LENGTH_BY_USER]: async ({commit, state}, userId) => {
        const {data} = await api
            .getAverageCallLengthByUser(userId)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_AVERAGE_CALL_LENGTH_BY_USER, {
            data: data.data.stats,
        });
    },
    [VisitsActions.FETCH_BY_QUEUES]: async ({commit, state}, timeSlot) => {
        const ended = await api
            .getCallsByQueues(timeSlot, state.selectedQueue, 'ended')
            .catch((e) => Promise.reject(e.response.data));
        const notAnswered = await api
            .getCallsByQueues(timeSlot, state.selectedQueue, 'not_answered')
            .catch((e) => Promise.reject(e.response.data));
        const rejected = await api
            .getCallsByQueues(timeSlot, state.selectedQueue, 'rejected')
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_ALL_QUEUES, {
            ended: ended.data.data.stats,
            notAnswered: notAnswered.data.data.stats,
            rejected: rejected.data.data.stats
        });
    },

    [VisitsActions.FETCH_BY_USER]: async ({commit, state}, timeSlot, type) => {
        if (!state.selectedUser) return;
        const ended = await api
            .getCallsByUser('count_calls', timeSlot, state.selectedUser, 'ended', null)
            .catch((e) => Promise.reject(e.response.data));
        const notAnswered = await api
            .getCallsByUser('count_missed_calls', timeSlot, state.selectedUser, null, null)
            .catch((e) => Promise.reject(e.response.data));

        commit(VisitsMutations.SET_ALL_USERS, {
            ended: ended.data.data.stats,
            notAnswered: notAnswered.data.data.stats,
        });
    },

    [VisitsActions.UPDATE_QUEUE]: async ({commit}, payload) => {
        commit(VisitsMutations.SET_SELECTED_QUEUE, payload
        );
    },
    [VisitsActions.UPDATE_QUEUE_DATE]: async ({commit}, payload) => {
        commit(VisitsMutations.SET_SELECTED_QUEUE_DATE, payload
        );
    },

    [VisitsActions.UPDATE_USER]: async ({commit}, payload) => {
        commit(VisitsMutations.SET_SELECTED_USER, payload
        );
    },
    [VisitsActions.UPDATE_USER_DATE]: async ({commit}, payload) => {
        commit(VisitsMutations.SET_SELECTED_USER_DATE, payload
        );
    },
    [VisitsActions.UPDATE_CALLED_BACK_DATE]: async ({commit}, payload) => {
        commit(VisitsMutations.SET_CALLS_BACK_DATE, payload
        );
    },
    [VisitsActions.UPDATE_CALLED_BACK_HOUR]: async ({commit}, payload) => {
        commit(VisitsMutations.SET_CALLS_BACK_HOUR, payload
        );
    },
    [VisitsActions.UPDATE_GLOBAL_CALLS_DATE]: async ({commit}, payload) => {
        commit(VisitsMutations.SET_GLOBAL_CALLS_DATE, payload
        );
    },

    [VisitsActions.UPDATE_SELECTED_CALLBACK_DATE]: async ({commit}, payload) => {
        commit(VisitsMutations.SET_SELECTED_CALLBACK_DATE, payload
        );
    },
    [VisitsActions.FETCH_HEATMAP]: async ({commit, state}, timeslot) => {
        const heatmap = await api
            .getHeatmap(timeslot)
            .catch((e) => Promise.reject(e.response.data));
        const dataResp = heatmap.data.data.stats;
        const data = dataResp.map((item) => {
            const dayEng = Object.keys(item)[0].substring(0, 3);
            const hour = Object.keys(item)[0].substring(3, 5);
            let day;
            let dayId;
            switch (dayEng) {
                case 'mon':
                    day = 'pon.';
                    dayId = 0;
                    break;
                case 'tue':
                    day = 'wt.';
                    dayId = 1;
                    break;
                case 'wed':
                    day = 'śr.';
                    dayId = 2;
                    break;
                case 'thu':
                    day = 'czw.';
                    dayId = 3;
                    break;
                case 'fri':
                    day = 'pt.';
                    dayId = 4;
                    break;
                case 'sat':
                    day = 'sob.';
                    dayId = 5;
                    break;
                case 'sun':
                    day = 'niedz.';
                    dayId = 6;
                    break;
            }
            return {
                day: {
                    name: day,
                    id: dayId
                },
                hour: +hour,
                amount: +item[Object.keys(item)[0]]
            };
        });
        commit(VisitsMutations.SET_HEATMAP, data);
    },
    [VisitsActions.FETCH_ALL_TO_CALL_BACK]: async ({commit}, timeSlot) => {
        const {data} = await api
            .getAllCallsToCallBack(timeSlot)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_ALL_TO_CALLED_BACK, {
            data: data.data.stats,
        });
    },

    [VisitsActions.FETCH_ALL_CALLED_BACK]: async ({commit, state}, timeSlot, user) => {
        const {data} = await api
            .getAllCalledBack(timeSlot, null)
            .catch((e) => Promise.reject(e.response.data));
        const allUser = await api
            .getAllCalledBack(timeSlot, state.selectedUser)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_ALL_CALLED_BACK, {
            all: data.data.stats,
            allByUser: allUser.data.data.stats,
        });
    },



    [VisitsActions.FETCH_AVERAGE_CALL_BACK_TIME]: async ({commit}, timeSlot) => {
        const {data} = await api
            .getAverageCalledBackTime(timeSlot)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_AVERAGE_CALLED_BACK_TIME, {
            data: data.data.stats,
        });
    },

    [VisitsActions.FETCH_NOT_ANSWERED]: async ({commit, state}, timeSlot) => {
        const notAnswered = await api
            .getCallsByStatus('not_answered', timeSlot)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_ALL_NOT_ANSWERED_CALLS, {
            data: notAnswered.data.data.stats,
        });
    },

    [VisitsActions.FETCH_ALL_CALLED_BACK_BY_HOURS]: async ({commit, state}, hours) => {
        const {data} = await api
            .getAllCalledBackByHours(hours, state.updateCallsBackDate)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_ALL_CALLED_BACK_BY_HOURS, {
            data: data.data.stats,
        });
    },

    [VisitsActions.FETCH_VISITS_STATS]: async ({commit}, timeSlot) => {
        const {data} = await api
            .getOverallVisits(timeSlot)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_VISITS_STATS, {
            data: data.data.stats,
        });
    },
    [VisitsActions.FETCH_INCOMING_OUTGOING]: async ({commit}, timeSlot, type) => {
        const inc = await api
            .getCallsByUser('count_calls', timeSlot, state.selectedUser, 'ended', 'incoming')
            .catch((e) => Promise.reject(e.response.data));
        const out = await api
            .getCallsByUser('count_calls',timeSlot, state.selectedUser, 'ended', 'outgoing')
            .catch((e) => Promise.reject(e.response.data));
        const all = await api
            .getCallsByUser('count_calls', timeSlot, state.selectedUser, null, null)
            .catch((e) => Promise.reject(e.response.data));
        const notAnswered = await api
            .getCallsByUser('count_missed_calls', timeSlot, state.selectedUser, null, null)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_INC_OUT, {
            inc: inc.data.data.stats,
            out: out.data.data.stats,
            all: all.data.data.stats,
            notAnswered: notAnswered.data.data.stats
        });
    },


};
const mutations = {
    [VisitsMutations.SET_COUNT_CALLS](state, payload) {
        state.countedCalls = payload.data
    },
    [VisitsMutations.SET_CALLED_BACK](state, payload) {
        state.calledBack = payload.data
    },
    [VisitsMutations.SET_AVERAGE_CALL_LENGTH](state, payload) {
        state.averageCallLength = payload.data
    },
    [VisitsMutations.SET_AVERAGE_CALL_LENGTH_BY_USER](state, payload) {
        state.averageCallLengthByUser = payload.data
    },
    [VisitsMutations.SET_ALL_STATUSES](state, payload) {
        state.allStatuses = payload
    },
    [VisitsMutations.SET_ALL_QUEUES](state, payload) {
        state.allQueues = payload
    },
    [VisitsMutations.SET_SELECTED_QUEUE](state, payload) {
        state.selectedQueue = payload
    },
    [VisitsMutations.SET_SELECTED_QUEUE_DATE](state, payload) {
        state.selectedQueueDate = payload
    },
    [VisitsMutations.SET_ALL_USERS](state, payload) {
        state.allUserCalls = payload
    },
    [VisitsMutations.SET_SELECTED_USER](state, payload) {
        state.selectedUser = payload
    },
    [VisitsMutations.SET_SELECTED_USER_DATE](state, payload) {
        state.selectedUserDate = payload
    },
    [VisitsMutations.SET_HEATMAP](state, payload) {
        state.heatmapData = payload
    },
    [VisitsMutations.SET_ALL_TO_CALLED_BACK](state, payload) {
        state.allToCallBack = payload.data
    },
    [VisitsMutations.SET_ALL_CALLED_BACK](state, payload) {
        state.allCalledBack = payload
    },
    [VisitsMutations.SET_AVERAGE_CALLED_BACK_TIME](state, payload) {
        state.averageCalledBackTime = payload.data
    },
    [VisitsMutations.SET_ALL_NOT_ANSWERED_CALLS](state, payload) {
        state.allNotAnsweredCalls = payload.data
    },
    [VisitsMutations.SET_ALL_CALLED_BACK_BY_HOURS](state, payload) {
        state.allCalledBackByHours = payload.data
    },
    [VisitsMutations.SET_CALLS_BACK_DATE](state, payload) {
        state.updateCallsBackDate = payload
    },
    [VisitsMutations.SET_CALLS_BACK_HOUR](state, payload) {
        state.updateCallsBackHour = payload
    },
    [VisitsMutations.SET_GLOBAL_CALLS_DATE](state, payload) {
        state.updateGlobalCallDate = payload
    },
    [VisitsMutations.SET_SELECTED_CALLBACK_DATE](state, payload) {
        state.updateCallbackDate = payload
    },
    [VisitsMutations.SET_INC_OUT](state, payload) {
        state.incOutCalls = payload
    }
};

const getters = {
    allCountedCalls: (state) => state.countedCalls,
    allAverageCallLength: (state) => state.averageCallLength,
    allAverageCallLengthByUser: (state) => state.averageCallLengthByUser,
    allStatuses: (state) => state.allStatuses,
    allQueues: (state) => state.allQueues,
    allUserCalls: (state) => state.allUserCalls,
    selectedQueue: (state) => state.selectedQueue,
    selectedQueueDate: (state) => state.selectedQueueDate,
    selectedUser: (state) => state.selectedUser,
    selectedUserDate: (state) => state.selectedUserDate,
    heatmapData: (state) => state.heatmapData,
    allToCallBack: (state) => state.allToCallBack,
    allCalledBack: (state) => state.allCalledBack,
    averageCalledBackTime: (state) => state.averageCalledBackTime,
    allNotAnsweredCalls: (state) => state.allNotAnsweredCalls,
    allCalledBackByHours: (state) => state.allCalledBackByHours,
    selectedCallsBackDate: (state) => state.updateCallsBackDate,
    selectedCallsBackHour: (state) => state.updateCallsBackHour,
    selectedGlobalCallsDate: (state) => state.updateGlobalCallDate,
    selectedCallbackDate: (state) => state.updateCallbackDate,
    incOutCalls: (state) => state.incOutCalls,
};

const state = {
    allTimeSlot: "2023-01-01," + dayjs().format("YYYY-MM-DD"),
    countedCalls: null,
    calledBack: null,
    averageCallLength: null,
    averageCallLengthByUser: null,
    allStatuses: null,
    allQueues: null,
    selectedQueue: null,
    selectedQueueDate: null,
    selectedUser: null,
    selectedUserDate: null,
    allUserCalls: null,
    heatmapData: null,
    allToCallBack: null,
    allCalledBack: null,
    averageCalledBackTime: null,
    allNotAnsweredCalls: null,
    allCalledBackByHours: null,
    updateCallsBackDate: null,
    updateCallsBackHour: null,
    updateGlobalCallDate: null,
    updateCallbackDate: null,
    incOutCalls: null,
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
