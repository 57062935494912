<template>
    <div class="relative h-max">
        <custom-search @search="search" :search-on-click="true" />
        <div class="absolute right-14 top-3">
            <div class="w-full relative">
                <button
                    class="relative w-[106px] pr-1.5 pl-3 h-6 flex items-center border-gray-100 border-l cursor-pointer bg-white text-gray-500 text-[13px]"
                    :class="selected ? 'justify-between' : 'justify-center'"
                    @click="isOpen = !isOpen"
                >
                    {{ selected ? selected.label : "" }}

                    <custom-icon
                        icon="chevron"
                        :w="9"
                        :h="9"
                        class="sm:origin-center opacity-60 hover:opacity-100 text-gray-500"
                        :class="{ 'transform rotate-180': !isOpen }"
                    />
                </button>
                <div
                    v-if="isOpen"
                    class="absolute top-auto left-0 w-24 z-20 border border-gray-100 rounded-t-none rounded-lg mt-2 overflow-hidden bg-white shadow-xl"
                >
                    <button
                        v-for="listing in listings"
                        :key="listing.value"
                        @click="onListingSelect(listing)"
                        class="text-xs px-3 py-2 cursor-pointer flex items-center gap-2 hover:bg-gray-100 w-full transition-colors"
                        :class="{
                            'pointer-events-none bg-gray-200':
                                selected && listing.value === selected.value,
                        }"
                    >
                        <p>{{ listing.label }}</p>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ListingsSearch from "@/elements/v1/form/ListingsSearch";
import CustomIcon from "@/elements/v2/CustomIcon";
import CustomSearch from "@/elements/v2/form/CustomSearch";

export default {
    mixins: ListingsSearch.mixins,
    components: {
        CustomIcon,
        CustomSearch,
    },
};
</script>
