<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="15"
        viewBox="0 0 13 15"
    >
        <g fill="currentColor">
            <path data-name="Rectangle 63" d="M0 0h5v15H0z" />
            <path data-name="Rectangle 64" d="M8 0h5v15H8z" />
        </g>
    </svg>
</template>
