import Echo from "@ably/laravel-echo";
import * as Ably from "ably";

class WebsocketConnector {
    constructor() {
        if (WebsocketConnector.instance) {
            return WebsocketConnector.instance;
        }

        // Initialize Ably and Echo
        window.Ably = Ably;
        this.echo = new Echo({
            broadcaster: "ably",
        });

        // Listen to the connection state
        this.echo?.connector?.ably?.connection?.on((stateChange) => {
            if (stateChange.current === "connected") {
                console.log("Successfully connected to ably server.");
            }
        });

        // Store the instance
        WebsocketConnector.instance = this;
    }

    static getInstance() {
        if (!WebsocketConnector.instance) {
            WebsocketConnector.instance = new WebsocketConnector();
        }
        return WebsocketConnector.instance;
    }
}

export default WebsocketConnector;
