import dayjs from "dayjs";
import * as api from "@/api";

const VisitsActions = {
    FETCH_VISITS: "fetchVisits",
    FETCH_VISIT: "fetchVisit",
    CREATE_VISIT: "createVisit",
    SEARCH_VISITS: "searchVisits",
    FETCH_BY_PATIENT: "fetchByPatient",
    FETCH_BY_DOCTOR: "fetchByDoctor",
    EDIT_VISIT: "editVisit",
    UPDATE_DATE: "updateDate",
    SELECT_TYPE: "selectType",
};

const VisitsMutations = {
    SET_SORTED_VISITS_BY_PATIENT_AND_DATE: "setPatientsVisits",
    SET_SORTED_VISITS_BY_PATIENT: "setSortedVisitsByPatient",
    SET_DOCTOR_VISITS: "setDoctorVisits",
    SET_SINGLE_VISIT: "setSingleVisit",
    SET_VISITS: "setVisits",
    SET_ALL_VISITS: "setAllVisits",
    ADD_VISIT: "addVisit",
    SET_LAST_PAGE: "setLastPage",
    RESET_VISITS: "resetVisits",
    SET_SEARCHED_VISITS: "setSearchedVisits",
    SET_UPDATE_DATE: "setUpdateDate",
    SET_SELECT_TYPE: "setSelectType",
    NEW_VISIT_ID: "newVisitId"
};

const actions = {
    [VisitsActions.FETCH_VISITS]: async ({ commit, state }, pageNumber) => {
        const { data } = await api
            .getVisits(pageNumber, state.visitsPerPage)
            .catch((e) => Promise.reject(e.response.data));

        if (!data.data.nextPageUrl) {
            commit(VisitsMutations.SET_LAST_PAGE, pageNumber);
        }

        commit(VisitsMutations.SET_VISITS, {
            data: data.data.data,
        });
    },
    [VisitsActions.FETCH_BY_PATIENT]: async ({ commit }, patientID) => {
        const { data } = await api
            .getVisitsByFilter(patientID, null, null)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_SORTED_VISITS_BY_PATIENT_AND_DATE, {
            data: data.data.data,
        });
        commit(VisitsMutations.SET_SORTED_VISITS_BY_PATIENT, {
            data: data.data.data,
        });
    },

    [VisitsActions.FETCH_BY_DOCTOR]: async ({ commit }, doctorID) => {
        const { data } = await api
            .getVisitsByFilter(null, doctorID, null)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_DOCTOR_VISITS, {
            data: data.data.data,
        });
    },
    [VisitsActions.FETCH_VISIT]: async ({ commit }, visitID) => {
        const { data } = await api
            .getVisit(visitID)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_SINGLE_VISIT, {
            data: data.data.item,
        });
    },
    [VisitsActions.EDIT_VISIT]: async ({ commit }, payload) => {
        const { data } = await api
            .editVisit(payload)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_SINGLE_VISIT, { data: data.data.item });
        // return res;
    },
    [VisitsActions.CREATE_VISIT]: async ({ dispatch, commit }, payload) => {
        const res = await api
            .createVisit(payload)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.RESET_VISITS);
        // dispatch(VisitsActions.FETCH_VISITS);
        commit(VisitsMutations.NEW_VISIT_ID, res.data.data.item.id);
        return res;
    },
    [VisitsActions.SEARCH_VISITS]: async (store, needle) => {
        if (!needle) {
            store.commit(VisitsMutations.SET_SEARCHED_VISITS, {
                data: null,
                needle,
            });
        } else {
            const { data } = await api
                .searchVisits(needle, store.state.visitsPerPage)
                .catch((e) => Promise.reject(e.response.data));

            store.commit(VisitsMutations.SET_SEARCHED_VISITS, {
                data: data.data.data,
                needle,
            });
        }
    },
    [VisitsActions.UPDATE_DATE]: async ({ commit }, payload) => {
        commit(VisitsMutations.SET_UPDATE_DATE, payload);
    },
    [VisitsActions.SELECT_TYPE]: async ({ commit }, payload) => {
        commit(VisitsMutations.SET_SELECT_TYPE, payload);
    },
};
const mutations = {
    [VisitsMutations.SET_VISITS](state, payload) {
        let arr = state.visits;
        arr.splice(state.lastPage, 0, payload.data);
        arr = payload;

        if (payload?.data?.length)
            state.allVisits = [...state.allVisits, ...payload.data];
    },
    [VisitsMutations.SET_SORTED_VISITS_BY_PATIENT_AND_DATE](state, payload) {
        const arr = [];
        const filterEmpty = payload.data.filter((visit) => !visit.startTime);
        const visits = payload.data.filter(
            (visit) => dayjs(visit.startTime) > dayjs()
        );
        const sortByDate = visits.sort(
            (a, b) => dayjs(a.startTime) - dayjs(b.startTime)
        );

        arr.push(...sortByDate, ...filterEmpty);
        state.visitsByPatientAndDate = arr;
    },
    [VisitsMutations.SET_SORTED_VISITS_BY_PATIENT](state, payload) {
        state.visitsByPatient = payload.data;
    },
    [VisitsMutations.SET_DOCTOR_VISITS](state, payload) {
        state.visitsByDoctor = payload.data;
    },
    [VisitsMutations.RESET_VISITS](state) {
        state.allVisits = [];
        state.Visits = [];
        state.lastPage = null;
    },
    [VisitsMutations.SET_SINGLE_VISIT](state, payload) {
        state.singleVisit = payload.data;
    },
    [VisitsMutations.SET_ALL_VISITS](state, item) {
        // PAGINATED
        const visitsCopy = [...state.visits];
        state.visits = visitsCopy.map((page) => {
            return page.map((visit) => (visit.id === item.id ? item : visit));
        });

        // ALL
        const index = state.allVisits.findIndex((visit) => {
            return visit.id === item.id;
        });
        state.allVisits.splice(index, 1, item);

        // SEARCHED
        if (state.searchedVisits?.length > 0) {
            const idx = state.searchedVisits.findIndex((visit) => {
                return visit.id === item.id;
            });
            state.searchedVisits.splice(idx, 1, item);
        }
    },
    [VisitsMutations.SET_LAST_PAGE](state, lastPageIndex) {
        state.lastPage = lastPageIndex;
    },

    [VisitsMutations.SET_SEARCHED_VISITS](state, payload) {
        if (!payload.data) {
            state.searchedVisits = null;
        } else {
            state.searchedVisits =
                payload.data.length === 0 ? [] : payload.data;
        }

        state.searchNeedle = payload.needle;
    },

    [VisitsMutations.SET_UPDATE_DATE](state, payload) {
        state.updatedDate = payload;
    },

    [VisitsMutations.NEW_VISIT_ID](state, payload) {
        state.newVisitId = payload;
    },

    [VisitsMutations.SET_SELECT_TYPE](state, payload) {
        state.selectType = payload;
    }

};

const getters = {
    allVisits: (state) => state.visits,
    visits: (state) => (pageNumber) => {
        return state.visits[pageNumber - 1];
    },
    visitsByPatientFiltered: (state) => state.visitsByPatient,
    visitsByPatient: (state) => state.visitsByPatientAndDate,
    visitSortedByPatient: (state) => (searchID) =>
        state.allVisits.find((visit) => visit.patientUserId === +searchID),
    visitsByDoctor: (state) => state.visitsByDoctor,
    searchedVisits: (state) => state.searchedVisits,
    searchNeedle: (state) => state.searchNeedle,
    visitsPerPage: (state) => state.visitsPerPage,
    lastPage: (state) => state.lastPage,
    getSingleVisit: (state) => state.singleVisit,
    getUpdatedDate: (state) => state.updatedDate,
    getSelectType: (state) => state.selectType,
    getNewVisitId: (state) => state.newVisitId
};

const state = {
    visits: [],
    allVisits: [],
    visitsByPatient: [],
    visitsByPatientAndDate: [],
    visitsByDoctor: [],
    singleVisit: null,
    lastPage: null,
    visitsPerPage: 2,
    startTime: dayjs().format("YYYY-MM-DD HH:mm"),
    searchedVisits: null,
    searchNeedle: "",
    updatedDate: null,
    selectType: null,
    newVisitId: null
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
