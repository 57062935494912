<template>
    <div class="hidden xl:flex items-center gap-8 2xl:gap-16 text-blue-500">
        <div class="flex gap-5">
            <listing-search />

            <custom-button @click="openPatientForm()" type="empty">
                <custom-icon
                    class="fill-black"
                    icon="plus"
                    :w="12"
                    :h="12"
                ></custom-icon>
                DODAJ PACJENTA
            </custom-button>
            <details-form-patient
                :force-active="detailsFormPatientActive"
                mode="create"
            ></details-form-patient>
            <!-- TODO TEMPORARY HIDDEN 10.08 - form does not contain patient ID field selector-->
            <!--            <custom-button @click="openVisitForm()" type="empty">-->
            <!--                <custom-icon-->
            <!--                    class="fill-black"-->
            <!--                    icon="plus"-->
            <!--                    :w="12"-->
            <!--                    :h="12"-->
            <!--                ></custom-icon>-->
            <!--                DODAJ WIZYTĘ</custom-button-->
            <!--            >-->
            <!--            <details-form-visit-->
            <!--                :force-active="detailsFormVisitActive"-->
            <!--                mode="create"-->
            <!--            ></details-form-visit>-->
        </div>
        <div class="flex gap-3">
            <button class="p-1">
                <custom-icon icon="person" :w="21" :h="21" />
            </button>

            <notifications-dropdown />
            <phoneline-select />
        </div>
        <button
            @click="$emit('logout')"
            class="text-blue-500 font-medium text-lg hidden xl:block"
        >
            Wyloguj się
        </button>
    </div>
</template>

<script>
import CustomIcon from "@/elements/v1/CustomIcon";
import ListingSearch from "@/elements/v1/form/ListingsSearch";
import CustomButton from "@/elements/v1/CustomButton";
import DetailsFormVisit from "@/components/v1/Visit/DetailsForm";
import DetailsFormPatient from "@/components/v1/Patient/DetailsForm";
import PhonelineSelect from "@/components/v1/Switchboard/PhonelineSelect";
import NotificationsDropdown from "@/components/v1/Navigation/NotificationsDropdown";

export default {
    mixins: [
        {
            data: () => ({
                detailsFormPatientActive: false,
                detailsFormVisitActive: false,
            }),

            components: {
                CustomIcon,
                ListingSearch,
                CustomButton,
                DetailsFormVisit,
                DetailsFormPatient,
                PhonelineSelect,
                NotificationsDropdown,
            },
            methods: {
                openPatientForm() {
                    this.detailsFormPatientActive =
                        !this.detailsFormPatientActive;
                },
                openVisitForm() {
                    this.detailsFormVisitActive = !this.detailsFormVisitActive;
                },
                logout() {
                    this.$emit("logout");
                },
            },
        },
    ],
};
</script>
