import * as api from "@/api";

const StatusesActions = {
    FETCH_STATUSES: "fetchStatuses",
};

const StatusesMutations = {
    SET_STATUSES: "setStatuses",
};

const actions = {
    [StatusesActions.FETCH_STATUSES]: async ({ commit }) => {
        const { data } = await api.fetchStatuses().catch((e) => Promise.reject(e.response.data));
        commit(StatusesMutations.SET_STATUSES, data.data.items);
    },
};
const mutations = {
    [StatusesMutations.SET_STATUSES](state, payload) {
        state.statuses = payload;
    },
};

const getters = {
    allStatuses: (state) => state.statuses,
    singleStatus: (state) => (searchID) =>
        state.statuses.find((status) => status.id === +searchID),
};

const state = {
    statuses: null,
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
