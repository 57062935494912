<template>
    <div
        class="w-full sm:w-96 sm:h-[47px] border border-gray rounded-xl flex justify-between items-center overflow-hidden"
    >
        <input
            class="w-full h-full pl-2 sm:pl-5 pb-1.5 sm:pb-0 focus:outline-none placeholder:text-xs sm:placeholder:text-sm"
            type="text"
            :placeholder="placeholder"
            v-model="value"
            @keyup.enter="search"
        />
        <custom-icon
            @click="search"
            class="mr-3 sm:mr-5 cursor-pointer p-1 text-green-500"
            :class="{
                'opacity-100 pointer-events-none': value.length < 3,
                'pointer-events-none': !searchOnClick,
            }"
            icon="search"
            :w="22"
            :h="22"
        />
    </div>
</template>

<script>
import CustomSearch from "@/elements/v1/form/CustomSearch";
import CustomIcon from "@/elements/v2/CustomIcon";

export default {
    mixins: CustomSearch.mixins,
    components: {
        CustomIcon,
    },
};
</script>
