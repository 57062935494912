import * as api from "@/api";

const DoctorsActions = {
    FETCH_DOCTORS: "fetchDoctors",
    FETCH_DOCTOR: "fetchDoctor",
    UPDATE_DOCTOR: "updateDoctor"

};

const DoctorsMutations = {
    SET_DOCTORS: "setDoctors",
    SET_DOCTOR: "setDoctor",
    SET_UPDATED_DOCTOR: "setUpdatedDoctor"
};

const actions = {
    [DoctorsActions.FETCH_DOCTORS]: async ({ commit }) => {
        const { data } = await api.fetchDoctors().catch((e) => Promise.reject(e.response.data));
        commit(DoctorsMutations.SET_DOCTORS, data.data.data);
    },
    [DoctorsActions.FETCH_DOCTOR]: async ({ commit }, id) => {
        const { data } = await api.fetchDoctor(id).catch((e) => Promise.reject(e.response.data));
        commit(DoctorsMutations.SET_DOCTOR, data.data.item);
    },
    [DoctorsActions.UPDATE_DOCTOR]: async ({ commit }, payload) => {
        commit(DoctorsMutations.SET_UPDATED_DOCTOR, payload);
    },
};
const mutations = {
    [DoctorsMutations.SET_DOCTORS](state, payload) {
        state.doctors = payload;
    },
    [DoctorsMutations.SET_DOCTOR](state, payload) {
        state.doctor = payload
    },
    [DoctorsMutations.SET_UPDATED_DOCTOR](state, payload) {
        state.updatedDoctor = payload
    },
};

const getters = {
    allDoctors: (state) => state.doctors,
    singleDoctor: (state) => state.doctor,
    getUpdatedDoctor: (state) => state.updatedDoctor
};

const state = {
    doctors: null,
    doctor: null,
    updatedDoctor: null
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
