import * as api from "@/api";

export const AuthActions = {
    LOGIN: "login",
    LOGOUT: "logout",
    AUTHORIZE: "authorize",
    SEND_EMAIL_RESET: "sendEmailReset",
    RESET_PASSWORD: "resetPassword",
};

const AuthMutations = {
    SET_USER: "setUser",
    SET_USER_ROLES: "setUserRoles",
};

const actions = {
    [AuthActions.LOGOUT]: async ({ commit }) => {
        const res = await api.logout().catch((e) => Promise.reject(e.response));
        commit(AuthMutations.SET_USER, null);
        return res;
    },
    [AuthActions.LOGIN]: async ({ dispatch }, payload) => {
        const res = await api.login(payload).catch((e) => {
            return Promise.reject(e.response);
        });
        dispatch(AuthActions.AUTHORIZE);
        dispatch("voip/getStatus", null, { root: true });
        return res;
    },
    [AuthActions.AUTHORIZE]: ({ commit }) => {
        return api
            .authorize()
            .then(({ data }) => {
                commit(AuthMutations.SET_USER, data.data.item);
                return true;
            })
            .catch(() => {
                commit(AuthMutations.SET_USER, null);
                return false;
            });
    },
    [AuthActions.SEND_EMAIL_RESET]: async (store, payload) => {
        await api.sendEmailReset(payload).catch((e) => {
            return Promise.reject(e.response);
        });
    },
    [AuthActions.RESET_PASSWORD]: async (store, payload) => {
        await api.resetPassword(payload).catch((e) => {
            return Promise.reject(e.response);
        });
    },
};
const mutations = {
    [AuthMutations.SET_USER](state, payload) {
        state.user = payload;
    },
};

const getters = {
    isAuth: (state) => !!state.user,
    user: (state) => state.user,
    roles: (state) => state.user?.roles,
};

const state = {
    isAuth: false,
    user: null,
};
export default {
    namespaced: true,
    getters,
    actions,
    mutations,
    state,
};
