<template>
    <div class="formulate-select">
        <button
            class="flex justify-between items-center cursor-pointer w-full"
            @click.stop="toggle"
            type="button"
        >
            <div
                class="flex items-center h-10 sm:h-12 text-left"
                :class="hasValue && 'text-blue-500'"
            >
                <span>{{ label }}</span>
            </div>
            <div class="flex items-center gap-1">
                <button v-if="allowReset && value" @click="reset" class="p-0.5">
                    <custom-icon
                        icon="plus"
                        class="fill-blue-500 transform rotate-45 origin-top mt-0.5"
                        :w="12"
                        :h="12"
                    />
                </button>

                <div
                    :class="{
                        'transform transition text-blue-500': true,
                        'rotate-180': active,
                    }"
                >
                    <custom-icon icon="chevron" :w="12" :h="12" />
                </div>
            </div>
        </button>

        <div v-click-outside="() => active && clickOutside()">
            <FormKit
                :options="options"
                type="radio"
                :classes="{
    outer: 'input-select'
  }"
                :name="name"
                v-model="value"
                error-behavior="submit"
                :validation="validation"
                :validation-name="validationName"
                :validation-rules="validationRules"
                :validation-messages="validationMessages"
                class="input-select"
                :outer-class="{ 'is-hidden': !active, 'z-20': active }"
            />
        </div>
    </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import CustomIcon from "@/elements/v1/CustomIcon.vue";

export default {
    mixins: [
        {
            name: "CustomSelect",
            components: { CustomIcon },
            props: {
                options: {
                    type: Array,
                    require: true,
                },
                name: String,
                defaultValue: [String, Number],
                placeholder: String,
                validation: String,
                validationName: String,
                validationRules: Object,
                validationMessages: Object,
                allowReset: {
                    type: Boolean,
                    default: false,
                },
            },
            directives: {
                ClickOutside,
            },
            data: () => ({
                value: null,
                active: false,
                initialRender: true,
            }),
            computed: {
                label() {
                    const placeholderOrDefault =
                        this.defaultValue ?? this.placeholder;
                    let finalValue = this.value;

                    if (typeof this.options[0] === "object" && this.hasValue) {
                        finalValue = this.options.find(
                            (option) => option.value == this.value
                        )?.label;
                    }

                    return this.hasValue ? finalValue : placeholderOrDefault;
                },
                hasValue() {
                    return this.value || this.value === 0;
                },
            },
            updated() {
                if (!this.value) {
                    this.value = this.defaultValue;
                }
            },
            mounted() {
                if (
                    !this.value &&
                    (this.defaultValue || this.defaultValue === 0)
                ) {
                    this.value = this.defaultValue;
                }
                this.initialRender = false;
            },
            methods: {
                toggle() {
                    this.active = !this.active;
                },
                clickOutside() {
                    this.active = false;
                },
                reset(e) {
                    e.stopPropagation();
                    this.value = null;
                    this.$emit("update-form", { key: this.name, value: null });
                },
            },
            watch: {
                value(value) {
                    if (this.initialRender || !this.value) return;
                    this.active = false;
                    this.$emit("update-form", { key: this.name, value });
                },
                defaultValue(newVal) {
                    if (this.value !== newVal) {
                        this.value ??= newVal;
                    }
                },
            },
        },
    ],
};
</script>
