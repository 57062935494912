<template>
    <div class="flex gap-2 items-center">
        <strong class="text-blue-500 whitespace-nowrap" v-if="user">
            {{ user.name }}
        </strong>
        <toggle-checkbox
            :defaultValue="currentStatus === 'A'"
            @update="updateStatus"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CustomLabel from "@/elements/v1/form/CustomLabel";
import ToggleCheckbox from "@/elements/v2/form/ToggleCheckbox";
import { translate } from "@/lib/utils";


export default {
    name: "StatusBox",
    components: {
        CustomLabel,
        ToggleCheckbox,
    },
    data: () => ({
        currentStatus: null,
    }),
    computed: {
        ...mapGetters("voip", ["queues", "preferedQueue", "status"]),
        ...mapGetters("auth", ["user"]),
    },
    mounted() {
        if (!this.user) this.authorize();

        if (!this.currentStatus)
            this.getStatus()
                .then(() => {
                    this.currentStatus = this.status;
                })
                .catch((e) => {
                    if (e.data.message && this.$refs.errorMessage) {
                        this.$refs.errorMessage.classList.remove("hidden");
                        this.$refs.errorMessage.innerHTML =
                            this.translate(
                                e.data.message,
                                "voip"
                            );
                    }
                });
    },
    methods: {
        ...mapActions("voip", [
            "getStatus",
            "setStatus",
            "getQueues",
            "getPreferedQueue",
            "setPreferedQueue",
        ]),
        ...mapActions("auth", ["authorize"]),
        translate,
        updateStatus(status) {
            const statusValue = status ? "A" : "N";
            if (statusValue === this.currentStatus) return;
            const prevStatus = statusValue;
            this.currentStatus = statusValue;

            this.setStatus(statusValue)
                .then(() => {
                    this.$notify({
                        group: "general",
                        type: "success",
                        text: "Status został zaktualizowany",
                    });
                })
                .catch((e) => {
                    this.currentStatus = prevStatus;
                    this.$notify({
                        group: "general",
                        type: "error",
                        text:
                            e.data.message ||
                            "Wystąpił błąd podczas aktualizacji statusu",
                    });
                });
        },
    },
};
</script>
