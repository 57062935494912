import axiosClient from "../apiClient";

export function getOverallVisits(timeSlot, user, source) {
    const params = new URLSearchParams();
    params.append('action', 'count_appointments');
    if (timeSlot) {
        params.append('time_slot', timeSlot);
    }
    if (user) {
        params.append('filter[user_id]', user);
    }
    if (source) {
        params.append('filter[source]', source);
    }
    return axiosClient.get(`/clinic/visits-stats/?${params.toString()}`);
}

export function getVisitsTrends(filters) {
    const params = new URLSearchParams();
    params.append('action', 'trend');
    if (filters.type === 'timeslot') {
        params.append('time_slot', filters.value);
    }
    if (filters.length) {
        filters.forEach(({value, type}) => {
            params.append(`filter[${type}]`, value);
        });
    }
    return axiosClient.get(`/clinic/visits-stats/?${params.toString()}`);
}


export function getVisitsSales(action, filters) {
    const params = new URLSearchParams();
    if (action) {
        params.append('action', action);
        if (filters.length) {
            filters.forEach(({value, type}) => {
                if (type === 'timeslot'){
                    params.append('time_slot', value);
                } else {
                params.append(`filter[${type}]`, value);
                }
            });
        }
    }
    return axiosClient.get(`/clinic/visits-sales-stats/?${params.toString()}`);
}
