import * as api from "@/api";

const VisitsActions = {
    FETCH_VISITS_STATS: "fetchVisitsStats",
    FETCH_VISITS_STATS_BY_USER: "fetchVisitsStatsByUser",
    FETCH_VISITS_BY_SOURCE: "fetchVisitsBySource",
    FETCH_VISITS_BY_BOTH: "fetchVisitsByBoth",
    UPDATE_USER: "updateUser",
    UPDATE_USER_DATE: "updateUserDate",
    FETCH_VISITS_TRENDS_CURRENT: "fetchVisitsTrendsCurrent",
    FETCH_VISITS_TRENDS_PREV: "fetchVisitsTrendsPrev",
};

const VisitsMutations = {
    SET_VISITS_STATS: "setVisitsStats",
    SET_VISITS_STATS_BY_USER: "setVisitsStatsByUser",
    SET_VISITS_BY_SOURCE: "setVisitsBySource",
    SET_SELECTED_USER: "setSelectedUser",
    SET_SELECTED_USER_DATE: "setSelectedUserDate",
    SET_VISITS_BY_USER_PERIOD: "setVisitsByUserPeriod",
    SET_VISITS_BY_SOURCE_PERIOD: "setVisitsBySourcePeriod",
    SET_VISITS_BY_BOTH: "setVisitsByBoth",
    SET_VISITS_TRENDS_CURRENT: "setVisitsTrendsCurrent",
    SET_VISITS_TRENDS_PREV: "setVisitsTrendsPrev"
};

const actions = {
    [VisitsActions.FETCH_VISITS_STATS]: async ({commit}, timeSlot) => {

        const {data} = await api
            .getOverallVisits(timeSlot)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_VISITS_STATS, {
            data: data.data.stats,
        });
    },
    [VisitsActions.FETCH_VISITS_STATS_BY_USER]: async ({commit, state}, timeSlot) => {
        if (!state.selectedVisitsUser) return;
        const {data} = await api
            .getOverallVisits(timeSlot, state.selectedVisitsUser)
            .catch((e) => Promise.reject(e.response.data));
        const usersByUserPeriod = await api
            .getOverallVisits(timeSlot)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_VISITS_STATS_BY_USER, {
            data: data.data.stats,
        });
        commit(VisitsMutations.SET_VISITS_BY_USER_PERIOD, {
            data: usersByUserPeriod.data.data.stats,
        });
    },
    [VisitsActions.FETCH_VISITS_BY_SOURCE]: async ({commit}, timeSlot) => {
        const {data} = await api
            .getOverallVisits(timeSlot, null, "phone")
            .catch((e) => Promise.reject(e.response.data));

        const visitsBySourcePeriod = await api
            .getOverallVisits(timeSlot)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_VISITS_BY_SOURCE, {
            data: data.data.stats,
        });
        commit(VisitsMutations.SET_VISITS_BY_SOURCE_PERIOD, {
            data: visitsBySourcePeriod.data.data.stats,
        });

    },
    [VisitsActions.FETCH_VISITS_BY_BOTH]: async ({commit}, timeSlot) => {
        const all = await api
            .getOverallVisits(timeSlot)
            .catch((e) => Promise.reject(e.response.data));
        const phone = await api
            .getOverallVisits(timeSlot, state.selectedVisitsUser, "phone")
            .catch((e) => Promise.reject(e.response.data));
        const allUser = await api
            .getOverallVisits(timeSlot, state.selectedVisitsUser)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_VISITS_BY_BOTH, {
            all: all.data.data.stats,
            phone: phone.data.data.stats,
            allUser: allUser.data.data.stats,
        });
    },
    [VisitsActions.UPDATE_USER]: async ({commit}, payload) => {
        commit(VisitsMutations.SET_SELECTED_USER, payload
        );
    },
    [VisitsActions.UPDATE_USER_DATE]: async ({commit}, payload) => {
        commit(VisitsMutations.SET_SELECTED_USER_DATE, payload
        );
    },


    [VisitsActions.FETCH_VISITS_TRENDS_CURRENT]: async ({commit, state}, filters) => {
        const {data} = await api
            .getVisitsTrends(filters)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_VISITS_TRENDS_CURRENT,  {
            stats: data.data.stats,
            from: data.data.from,
            to: data.data.to
        });
    },
    [VisitsActions.FETCH_VISITS_TRENDS_PREV]: async ({commit, state}, filters) => {
        const {data} = await api
            .getVisitsTrends(filters)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_VISITS_TRENDS_PREV, {
            stats: data.data.stats,
            from: data.data.from,
            to: data.data.to
        });
    },
};
const mutations = {
    [VisitsMutations.SET_VISITS_STATS](state, payload) {
        state.allVisitsStats = payload.data
    },
    [VisitsMutations.SET_VISITS_STATS_BY_USER](state, payload) {
        state.allVisitsByUser = payload.data
    },
    [VisitsMutations.SET_SELECTED_USER](state, payload) {
        state.selectedVisitsUser = payload
    },
    [VisitsMutations.SET_SELECTED_USER_DATE](state, payload) {
        state.selectedVisitsUserDate = payload
    },
    [VisitsMutations.SET_VISITS_BY_USER_PERIOD](state, payload) {
        state.allVisitsByUserPeriod = payload.data
    },
    [VisitsMutations.SET_VISITS_BY_SOURCE](state, payload) {
        state.allVisitsBySource = payload.data
    },
    [VisitsMutations.SET_VISITS_BY_SOURCE_PERIOD](state, payload) {
        state.allVisitsBySourcePeriod = payload.data
    },
    [VisitsMutations.SET_VISITS_BY_BOTH](state, payload) {
        state.visitsByBoth = payload
    },
    [VisitsMutations.SET_VISITS_TRENDS_CURRENT](state, payload) {
        state.visitsTrendsCurrent = payload
    },
    [VisitsMutations.SET_VISITS_TRENDS_PREV](state, payload) {
        state.visitsTrendsPrev = payload
    },

};

const getters = {
    allVisitsStats: (state) => state.allVisitsStats,
    allVisitsByUser: (state) => state.allVisitsByUser,
    selectedVisitsUser: (state) => state.selectedVisitsUser,
    selectedVisitsUserDate: (state) => state.selectedVisitsUserDate,
    allVisitsByUserPeriod: (state) => state.allVisitsByUserPeriod,
    allVisitsBySource: (state) => state.allVisitsBySource,
    allVisitsBySourcePeriod: (state) => state.allVisitsBySourcePeriod,
    visitsByBoth: (state) => state.visitsByBoth,
    visitsTrendsCurrent: (state) => state.visitsTrendsCurrent,
    visitsTrendsPrev: (state) => state.visitsTrendsPrev
};

const state = {
    allVisitsStats: null,
    allVisitsByUser: null,
    selectedVisitsUser: null,
    selectedVisitsUserDate: null,
    allVisitsByUserPeriod: null,
    allVisitsBySource: null,
    allVisitsBySourcePeriod: null,
    visitsByBoth: null,
    visitsTrendsCurrent: null,
    visitsTrendsPrev: null
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
