<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14.473"
        height="11.257"
        viewBox="0 0 14.473 11.257"
    >
        <path
            d="M7.026 3.226a1.978 1.978 0 0 1 .211-.01 2.412 2.412 0 1 1-2.412 2.412 1.978 1.978 0 0 1 .01-.211 1.844 1.844 0 0 0 .794.211A1.61 1.61 0 0 0 7.237 4.02a1.844 1.844 0 0 0-.211-.794Zm5.051-1.2a9.735 9.735 0 0 1 2.334 3.294.8.8 0 0 1 0 .618 10.188 10.188 0 0 1-2.334 3.294 7.071 7.071 0 0 1-4.84 2.025A7.068 7.068 0 0 1 2.4 9.232 10.227 10.227 0 0 1 .062 5.938a.8.8 0 0 1 0-.618A9.77 9.77 0 0 1 2.4 2.025 7.073 7.073 0 0 1 7.237 0a7.075 7.075 0 0 1 4.84 2.025Zm-4.84-.015a3.618 3.618 0 1 0 3.618 3.618A3.619 3.619 0 0 0 7.237 2.01Z"
            fill="currentColor"
        />
    </svg>
</template>
