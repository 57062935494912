import { defineStore } from "pinia";
import { CallData, CallGroupData, UserData } from "../types";
import { CancelTokenSource } from "axios";
import { callsActions, callsGetters } from "./sharedCalls";
import CallService from "../services/CallService";
import { handleErrors } from "../lib/notifications";

interface CallsState {
    calls: CallData[];
    callGroups: Record<number, CallGroupData>;
    users: Record<number, UserData>;
    currentCallId: number | null;
    totalCalls?: number;
    currentFetchCancelToken: CancelTokenSource | null;
    search: string;
}

type Filters = any;

export const useCallsStore = defineStore("calls", {
    state: (): CallsState => ({
        calls: [],
        callGroups: {},
        users: {},
        currentCallId: null,
        currentFetchCancelToken: null,
        search: "",
    }),

    actions: {
        ...callsActions,
        async fetchCalls({
            page = 1,
            perPage = 20,
            filters = {},
            sortField = "createdAt",
            sortOrder = -1,
        }: {
            page?: number;
            perPage?: number;
            filters?: Partial<Filters>;
            sortField: string;
            sortOrder: number;
        }) {
            await CallService.fetchCallsPaginated({
                page,
                perPage,
                filters,
                sortField,
                sortOrder,
            })
                .then((response) => {
                    const apiResponse = response.data;
                    this.calls = apiResponse.data.data;
                    this.totalCalls = apiResponse.data.total;

                    // Create map for CallGroups and Users
                    this.callGroups = {};
                    this.users = {};

                    apiResponse.data.data.forEach((call: CallData) =>
                        this.updateRelationsInStore(call)
                    );
                })
                .catch((error) => {
                    handleErrors(error);
                });
        },
        setSearchNeedle(needle: string) {
            this.search = needle || "";
        },
    },

    getters: {
        ...callsGetters,
    },
});
