<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="12"
        viewBox="0 0 17 12"
    >
        <g data-name="Group 8127">
            <path
                data-name="Rectangle 97"
                fill="#fff"
                d="M0 .002h16.996v11.979H0z"
            />
            <path
                data-name="Path 6031"
                d="M16.996 4.795H9.513V.002H7.47v4.793H.004v2.4h7.464v4.791h2.043V7.195h7.483Z"
                fill="#ce1124"
            />
        </g>
        <g data-name="Group 8128">
            <path
                data-name="Path 6032"
                d="M.004 1.397v2.608h3.7Z"
                fill="#00247d"
            />
            <path
                data-name="Path 6033"
                d="M2.045.001h-.054l4.807 3.4v-3.4Z"
                fill="#00247d"
            />
            <path
                data-name="Path 6034"
                d="M5.704 3.988.004.001v.94l4.345 3.047Z"
                fill="#ce1124"
            />
            <path
                data-name="Path 6035"
                d="m11.295 7.993 5.7 3.987v-.94L12.65 7.993Z"
                fill="#ce1124"
            />
        </g>
        <g data-name="Group 8129">
            <path
                data-name="Path 6036"
                d="M.004 10.605V7.997h3.7Z"
                fill="#00247d"
            />
            <path
                data-name="Path 6037"
                d="M2.045 12h-.054l4.807-3.4V12Z"
                fill="#00247d"
            />
            <path
                data-name="Path 6038"
                d="M5.675 7.993.003 11.978h1.326l5.473-3.825v-.162Z"
                fill="#ce1124"
            />
        </g>
        <g data-name="Group 8130">
            <path
                data-name="Path 6039"
                d="M17.004 1.396v2.608h-3.707Z"
                fill="#00247d"
            />
            <path
                data-name="Path 6040"
                d="M14.955 0h.054l-4.807 3.4V0Z"
                fill="#00247d"
            />
            <path
                data-name="Path 6041"
                d="M16.994.006h-1.276l-5.511 3.852v.135h1.166Z"
                fill="#ce1124"
            />
        </g>
        <g data-name="Group 8131" fill="#00247d">
            <path data-name="Path 6042" d="M17.004 10.604V7.996h-3.707Z" />
            <path
                data-name="Path 6043"
                d="M14.955 11.999h.054l-4.807-3.4v3.4Z"
            />
        </g>
    </svg>
</template>
