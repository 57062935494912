import * as api from "@/api/lib/patient";

const PatientsActions = {
    FETCH_PATIENTS: "fetchPatients",
    FETCH_PATIENT: "fetchPatient",
    SET_PATIENT: "setPatient",
    CREATE_PATIENT: "createPatient",
    DELETE_PATIENT: "deletePatient",
    EDIT_PATIENT: "editPatient",
    SEARCH_PATIENTS: "searchPatients",
};

const PatientsMutations = {
    SET_PATIENTS: "setPatients",
    SET_PATIENT: "setPatient",
    SET_ALL_PATIENTS: "setAllPatients",
    SET_LAST_PAGE: "setLastPage",
    ADD_PATIENT: "addPatient",
    SET_SEARCHED_PATIENTS: "setSearchedPatients",
    RESET_PATIENTS: "resetPatients",
    SET_ADDED_NUMBER: "setAddedNumber",
};

const actions = {
    [PatientsActions.FETCH_PATIENTS]: async ({ commit, state }, pageNumber) => {
        const { data } = await api
            .getPatients(pageNumber, state.patientsPerPage)
            .catch((e) => Promise.reject(e.response.data));
        if (!data.data.nextPageUrl) {
            commit(PatientsMutations.SET_LAST_PAGE, pageNumber);
        }

        commit(PatientsMutations.SET_PATIENTS, {
            data: data.data.data,
        });
    },
    [PatientsActions.FETCH_PATIENT]: async ({ commit }, patientID) => {
        const res = await api
            .getPatient(patientID)
            .catch((e) => Promise.reject(e.response.data));

        commit(PatientsMutations.SET_PATIENT, res.data.data.item);
        return res.data.data.item;
    },
    [PatientsActions.SET_PATIENT]: async ({ commit }, patient) => {
        commit(PatientsMutations.SET_PATIENT, patient);
    },
    [PatientsActions.CREATE_PATIENT]: async ({ commit, dispatch }, payload) => {
        const res = await api
            .createPatient(payload)
            .catch((e) => Promise.reject(e.response.data));
        commit(PatientsMutations.RESET_PATIENTS);
        dispatch(PatientsActions.FETCH_PATIENTS);
        commit(
            PatientsMutations.SET_ADDED_NUMBER,
            res?.data?.data?.item?.phones
        );
        return res;
    },
    [PatientsActions.DELETE_PATIENT]: async (
        { commit, dispatch },
        patientID
    ) => {
        await api
            .deletePatient(patientID)
            .catch((e) => Promise.reject(e.response.data));
        commit(PatientsMutations.RESET_PATIENTS);
        dispatch(PatientsActions.FETCH_PATIENTS);
    },
    [PatientsActions.EDIT_PATIENT]: async ({ commit }, payload) => {
        const res = await api
            .editPatient(payload)
            .catch((e) => Promise.reject(e.response.data));

        const { item } = res.data.data;

        commit(PatientsMutations.SET_ALL_PATIENTS, item);
        return res;
    },
    [PatientsActions.SEARCH_PATIENTS]: async (store, needle) => {
        if (!needle) {
            store.commit(PatientsMutations.SET_SEARCHED_PATIENTS, {
                data: null,
                needle,
            });
        } else {
            const { data } = await api
                .searchPatients(needle, store.state.patientsPerPage)
                .catch((e) => Promise.reject(e.response.data));

            store.commit(PatientsMutations.SET_SEARCHED_PATIENTS, {
                data: data.data.data,
                needle,
            });
        }
    },
};
const mutations = {
    [PatientsMutations.SET_PATIENTS](state, payload) {
        state.patients.splice(state.lastPage, 0, payload.data);

        if (payload?.data?.length)
            state.allPatients = [...state.allPatients, ...payload.data];
    },
    [PatientsMutations.SET_ALL_PATIENTS](state, item) {
        // PAGINATED
        const patientsCopy = [...state.patients];
        state.patients = patientsCopy.map((page) => {
            return page.map((patient) =>
                patient.id === item.id ? item : patient
            );
        });

        // ALL
        const index = state.allPatients.findIndex((patient) => {
            return patient.id === item.id;
        });
        state.allPatients.splice(index, 1, item);

        // SEARCHED
        if (state.searchedPatients?.length > 0) {
            const idx = state.searchedPatients.findIndex((patient) => {
                return patient.id === item.id;
            });
            state.searchedPatients.splice(idx, 1, item);
        }
    },
    [PatientsMutations.RESET_PATIENTS](state) {
        state.allPatients = [];
        state.patients = [];
        state.lastPage = null;
    },
    [PatientsMutations.SET_LAST_PAGE](state, lastPageIndex) {
        state.lastPage = lastPageIndex;
    },
    [PatientsMutations.SET_SEARCHED_PATIENTS](state, payload) {
        if (!payload.data) {
            state.searchedPatients = null;
        } else {
            state.searchedPatients =
                payload.data.length === 0 ? [] : payload.data;
        }

        state.searchNeedle = payload.needle;
    },
    [PatientsMutations.SET_PATIENT](state, payload) {
        state.singlePatient = payload;
    },
    [PatientsMutations.SET_ADDED_NUMBER](state, payload) {
        state.addedNumber = payload;
    },
};

const getters = {
    patientsPerPage: (state) => state.patientsPerPage,
    lastPage: (state) => state.lastPage,
    allPatients: (state) => state.allPatients,
    patients: (state) => (pageNumber) => {
        return state.patients[pageNumber - 1];
    },
    searchedPatients: (state) => state.searchedPatients,
    searchNeedle: (state) => state.searchNeedle,
    contactHistory: (state) => state.contactHistory,
    singlePatient: (state) => (searchID) =>
        state.allPatients.find((patient) => patient.id === +searchID),
    getSinglePatient: (state) => state.singlePatient,
    getAddedNumber: (state) => state.addedNumber,
};

const state = {
    patients: [],
    allPatients: [],
    searchedPatients: null,
    searchNeedle: "",
    lastPage: null,
    patientsPerPage: 10,
    singlePatient: null,
    addedNumber: [],
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
