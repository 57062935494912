import axiosClient from "../apiClient";

export function getLogins() {
    return axiosClient.get("/clinic/voip-sip-users");
}

export function getLogin() {
    return axiosClient.get("/clinic/user/voip");
}

export function setLogin(voipID, withOvertaken = false) {
    const data = {
        voip_sip_user_id: voipID,
    };

    if (withOvertaken) {
        data.confirmUpdate = true;
    }

    return axiosClient.patch(`/clinic/user/voip`, data);
}

export function getStatus() {
    return axiosClient.get("/clinic/user/voip/virtual-agent");
}

export function setStatus(status) {
    const inQueueStatuses = {
        A: "A",
        N: "N",
    };

    const data = {
        status,
    };

    if (inQueueStatuses[status]) {
        data.in_queue_status = inQueueStatuses[status];
    }
    return axiosClient.patch("/clinic/user/voip/virtual-agent", data);
}

export async function getQueues() {
    return axiosClient.get("/clinic/voip-queues");
}

export async function getDepartments() {
    return axiosClient.get("/clinic/departments");
}
