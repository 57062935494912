<template>
    <div>
        <button
            class="text-left font-bold relative cursor-pointer border border-gray w-full focus:outline-none rounded-xl sm:rounded-2xl h-10 sm:h-12 px-6 text-blue-500 placeholder-gray-500 text-xs sm:text-base"
        >
            <input
                class="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0"
                type="file"
                @change="changeInput"
            />

            <span v-if="this.file">{{ this.file.name }}</span>
            <span v-else class="flex items-center gap-1.5 tracking-wide">
                <custom-icon
                    class="fill-blue-500"
                    icon="plus"
                    :w="12"
                    :h="12"
                ></custom-icon>
                WGRAJ PLIK
            </span>
        </button>
    </div>
</template>

<script>
import CustomIcon from "@/elements/v1/CustomIcon";

export default {
    mixins: [
        {
            name: "CustomInputFile",
            components: {
                CustomIcon,
            },
            data: () => ({
                file: null,
            }),
            methods: {
                changeInput(e) {
                    this.file = e.target?.files && e.target.files[0];
                    if (this.file) {
                        this.$emit("update", { key: "file", value: this.file });
                    }
                },
            },
        },
    ],
};
</script>
