export const Home = () => import("@/pages/v1/HomePage.vue");

export const Patient = () => import("@/pages/v1/Patient/PatientPage.vue");
export const Patients = () => import("@/pages/v1/Patient/PatientsPage.vue");

export const Visit = () => import("@/pages/v1/Visit/VisitPage.vue");
export const Visits = () => import("@/pages/v1/Visit/VisitsPage.vue");
export const VisitCalendar = () =>
    import("@/pages/v1/Visit/VisitCalendarPage.vue");
export const VisitArchive = () =>
    import("@/pages/v1/Visit/VisitArchivePage.vue");

export const Settings = () => import("@/pages/v1/SettingsPage.vue");
export const SurveyResults = () => import("@/pages/v1/SurveyResultsPage.vue");
export const Switchboard = () => import("@/pages/v1/SwitchboardPage.vue");

export const MessagesSms = () =>
    import("@/pages/v1/Messages/MessagesSmsPage.vue");

export const Login = () => import("@/pages/v1/Authentication/LoginPage.vue");
export const LostPassword = () =>
    import("@/pages/v1/Authentication/LostPasswordPage.vue");
export const ResetPassword = () =>
    import("@/pages/v1/Authentication/ResetPasswordPage.vue");
