<template>
    <div>
        <edit-button
            v-if="pencil"
            @open="active = true"
            :class="customClass"
            :icon="pencil"
        />
        <popup-wrapper
            :active="active"
            @close="closePopup"
            :outer-class="outerClass"
        >
            <custom-heading
                v-if="overheading"
                :icon="overheading.icon"
                :text="overheading.text"
            ></custom-heading>

            <h2
                v-if="title"
                class="text-lg md:text-3xl mt-1 sm:mt-3 text-blue-500"
            >
                {{ title }}
            </h2>

            <div class="mt-6 sm:mt-8 md:mt-12">
                <slot
                    :form-data="formData"
                    :closePopup="closePopup"
                    :send="send"
                    :change="change"
                    :errors="inputErrors"
                ></slot>
            </div>
        </popup-wrapper>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import CustomHeading from "@/elements/v1/CustomHeading";
import PopupWrapper from "@/components/v1/PopupWrapper";
import EditButton from "@/elements/v1/EditButton";

export default {
    mixins: [
        {
            name: "EditPopup",
            components: { CustomHeading, PopupWrapper, EditButton },
            data: () => ({
                active: false,
                formData: null,
                inputErrors: {},
            }),
            props: {
                callback: Function,
                overheading: Object,
                title: String,
                forceActive: Boolean,
                outerClass: String,
                mode: String,
                pencil: {
                    type: [String, Boolean],
                },
                customClass: String,
                fields: {
                    type: Object,
                    require: true,
                },
            },
            mounted() {
                this.formData = this.fields || {};
            },

            methods: {
                ...mapActions("patients", ["createPatient", "editPatient"]),
                ...mapActions("messages", ["sendMessage"]),
                ...mapActions("sms", ["sendSms"]),
                ...mapActions("calls", ["updateCall"]),
                closePopup() {
                    this.active = false;
                    this.$emit("on-close");
                },
                async send(data, config, successCallback) {
                    const { mode, set, id, successMessage } = config;
                    const storeAction = `${mode}${set}`;
                    try {
                        await this[storeAction]({
                            data: data || this.formData,
                            id,
                        });
                        if (successCallback) {
                            successCallback();
                        }
                        this.closePopup();
                        this.$notify({
                            group: "general",
                            type: "success",
                            text: successMessage,
                        });
                    } catch (e) {
                        if (e?.data?.message) {
                            this.$notify({
                                group: "general",
                                type: "error",
                                text: e?.data?.message,
                            });
                        }
                        if (e?.data?.messages) {
                            Object.values(e.data.messages).forEach(
                                (messages) => {
                                    this.$notify({
                                        group: "general",
                                        type: "error",
                                        text: messages[0],
                                    });
                                }
                            );
                        }
                    }
                },
                change(e) {
                    if (e === "reset" || !this.formData) {
                        this.formData = {};
                    }
                    if (e.key && e.value) {
                        this.formData[e.key] = e.value;
                    }
                },
            },
            watch: {
                forceActive(val) {
                    this.active = val !== "close";
                },
                fields(val) {
                    this.formData = val;
                },
            },
        },
    ],
};
</script>
