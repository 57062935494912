import * as api from "@/api";

const MessagesActions = {
    FETCH_TEMPLATES: "fetchTemplates",
    FETCH_MESSAGES: "fetchMessages",
    SEND_MESSAGE: "sendMessage",
};

const MessagesMutations = {
    SET_TEMPLATES: "setTemplates",
    SET_MESSAGES: "setMessages",
};

const actions = {
    [MessagesActions.FETCH_TEMPLATES]: async ({ commit }) => {
        const res = await api
            .getMessageTemplates()
            .catch((e) => Promise.reject(e.response));

        commit(MessagesMutations.SET_TEMPLATES, res.data.data.items);
    },
    [MessagesActions.FETCH_MESSAGES]: async ({ commit }, patientID) => {
        const { data } = await api
            .getMessages(patientID)
            .catch((e) => Promise.reject(e.response));

        commit(MessagesMutations.SET_MESSAGES, data.data.data);
    },
    [MessagesActions.SEND_MESSAGE]: async ({ dispatch }, payload) => {
        await api.sendMessage(payload).catch((e) => Promise.reject(e.response));
        dispatch(MessagesActions.FETCH_MESSAGES, payload.id);
    },
};
const mutations = {
    [MessagesMutations.SET_TEMPLATES](state, payload) {
        state.templates = payload;
    },
    [MessagesMutations.SET_MESSAGES](state, payload) {
        state.messages = payload;
    },
};

const getters = {
    allTemplates: (state) => state.templates,
    allMessages: (state) => state.messages,
};

const state = {
    templates: null,
    messages: null,
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
