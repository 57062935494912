<template>
    <div id="app" class="bg-gray-100 font-jakarta">
        <notifications
            group="general"
            position="top right"
            animation-type="css"
        />

        <switchboard-popup v-if="user && user.id"/>

        <template v-if="isAuth">
            <app-navbar/>
            <div class="flex gap-2 sm:gap-4 mt-2 sm:mt-4">
                <app-sidebar/>
                <router-view class="custom-scrollbar main"/>
            </div>
        </template>
        <template v-else>
            <router-view class="full-screen"/>
        </template>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import AppNavbar from "@/components/v1/Navigation/AppNavbar";
import AppSidebar from "@/components/v1/Navigation/AppSidebar";
import SwitchboardPopup from "@/components/v1/SwitchboardPopup";
import BugReportPopup from "@/components/v1/BugReportPopup";

export default {
    name: "App",
    components: {AppNavbar, AppSidebar, SwitchboardPopup, BugReportPopup},
    computed: {
        ...mapGetters("auth", ["isAuth", "user"]),
    },

    created() {
        this.$root.isDemo = window.version === 2;
    },
};
</script>
