<template>
    <div
        class="flex items-center bg-gray-100 font-light rounded-lg sm:rounded-2xl"
    >
        <div
            v-if="showDatepicker"
            class=" relative flex items-center px-3 md:pr-3 lg:pr-5 sm:px-5 h-10 sm:h-12"
            @click="() => toggle('dateActive', true)"
            v-click-outside="() => toggle('dateActive', false)"
        >
            <div
                class="flex items-center gap-1 cursor-pointer text-xs sm:text-base"
            >
                <span
                    class="text-green-500 flex items-center gap-1.5 leading-none"
                >
                    <custom-icon
                        class="transform -translate-y-[1px] sm:translate-y-0"
                        icon="calendar"
                    />
                    Data:
                </span>
                <div class="w-20">{{ date || currentDate }}</div>
            </div>

            <vue-date-picker
                             v-model="date"
                             :single-date="true"
                             @date-update="changeDate"
            />
        </div>
        <template v-if="showTimepicker">
            <div class="h-4 sm:h-7 w-[1px] bg-blue-500 bg-opacity-10"></div>
            <div
                class="timepicker relative flex items-center px-3 md:pl-3 lg:pl-5 sm:px-5 h-10 sm:h-12"
                @click="() => toggle('timeActive', true)"
                v-click-outside="() => toggle('timeActive', false)"
            >
                <div
                    class="flex items-center gap-1 cursor-pointer text-xs sm:text-base leading-none"
                >
                    <span
                        class="text-green-500 flex items-center gap-1.5 leading-none"
                    >
                        <custom-icon
                            class="transform -translate-y-[1px] sm:translate-y-0"
                            icon="clock"
                        />
                        Godzina:
                    </span>
                    <span
                        class="whitespace-nowrap"
                        v-show="
                            time.HH ||
                            (currentTime.HH && time.mm) ||
                            currentTime.mm
                        "
                    >
                        {{ time.HH || currentTime.HH }}:{{
                            time.mm || currentTime.mm
                        }}
                    </span>
                </div>

                <div
                    class=""
                >
                    <VueDatePicker v-model="time" time-picker format="HH:mm" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import vClickOutside from "click-outside-vue3"
import CustomIcon from "@/elements/v2/CustomIcon";
import CustomDatepicker from "@/elements/v1/form/CustomDatepicker";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
export default {
    mixins: CustomDatepicker.mixins,
    components: {
        VueDatePicker,
        CustomIcon,
        vClickOutside
    },
};
</script>

<style>
.vue .display-time {
    opacity: 0;
    height: 0px;
}

.time .time-picker {
    height: auto;
}

.time .time-picker .dropdown {
    position: relative;
    display: block !important;
    margin-top: -22px;
}
.datepicker .calendar-header h3 {
    width: 200px;
    text-align: center;
    max-width: 43vw;
}

.datepicker .calendar-header,
.datepicker .datePicker_wrap {
    background-color: white;
}

.datepicker ul.calendar {
    padding-bottom: 12px;
}

.datepicker ul.calendar li.day span.innerStartDate:hover,
.datepicker ul.calendar li.day span.innerStartDate:hover,
.datepicker ul.calendar li.day span.innerStartDate {
    background-color: #0e073d;
}

.datepicker ul.calendar li.day span.today {
    box-shadow: inset 0 0 0 2px #0e073d;
}

.datepicker ul.calendar li.day span:hover {
    background-color: rgb(188, 188, 188);
}

.datepicker .datePicker_wrap .calendar-header span.arrow:hover {
    background-color: #0e073d;
}

.timepicker .display-time {
    opacity: 0;
    height: 0px;
    visibility: hidden;
}

.timepicker .time-picker {
    height: auto;
}

.timepicker .time-picker .dropdown {
    position: relative;
    display: block !important;
    margin-top: -22px;
}

.timepicker .time-picker .hint,
.timepicker .time-picker-overlay {
    display: none;
}

.timepicker .time-picker .dropdown .select-list ul li.active:hover,
.timepicker .time-picker .dropdown .select-list ul li.active {
    background: #0e073d;
    color: white;
}
</style>
