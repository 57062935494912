<template>
    <edit-popup
        :callback="sendForm"
        :fields="{
            ...fields,
            phones:
                fields.phones && fields.phones.length
                    ? fields.phones
                    : [
                          {
                              isMain: 1,
                              phone: null,
                          },
                      ],
        }"
        :force-active="forceActive"
        :overheading="{
            text: 'Pacjent',
            icon: 'person',
        }"
        :pencil="isEdit && 'pencil'"
        :title="isEdit ? 'Edytuj pacjenta' : 'Dodaj pacjenta'"
        outer-class="sm:max-w-6xl"
    >
        <template v-slot="slotProps">
            <FormulateForm
                v-if="mode !== 'edit' || slotProps.formData"
                ref="form"
                :errors="slotProps.errors"
                :values="slotProps.formData"
                class="form"
                @submit="
                    (data) => {
                        if (sendForm(data)) slotProps.closePopup();
                    }
                "
            >
                <div class="form-row sm:grid-cols-2 lg:grid-cols-3">
                    <!-- NAME -->
                    <FormulateInput
                        class="input-text"
                        error-behavior="submit"
                        name="name"
                        placeholder="Imię"
                        type="text"
                        validation="required"
                        validation-name="Imię"
                    ></FormulateInput>

                    <!-- SURNAME -->
                    <FormulateInput
                        class="input-text"
                        error-behavior="submit"
                        name="surname"
                        placeholder="Nazwisko"
                        type="text"
                        validation="required"
                        validation-name="Nazwisko"
                    ></FormulateInput>

                    <!-- EMAIL -->
                    <FormulateInput
                        :validation-messages="{
                            isEmailOrPhone: requireMessage('email'),
                            isValidEmail: 'Niepoprawny format emaila',
                        }"
                        :validation-rules="{
                            isEmailOrPhone: (e) => validateEmailOrPhone(e),
                            isValidEmail: (e) => validateEmail(e),
                        }"
                        class="input-email"
                        error-behavior="submit"
                        name="email"
                        placeholder="Pole adres e-mail"
                        type="text"
                        validation="isEmailOrPhone|isValidEmail"
                        validation-name="Pole adres e-mail"
                    ></FormulateInput>
                </div>

                <FormulateInput
                    v-if="slotProps.formData"
                    :add-label="`+ DODAJ ${
                        slotProps.formData && slotProps.formData.phones.length
                            ? 'KOLEJNY'
                            : ''
                    } NUMER TELEFONU`"
                    :repeatable="true"
                    name="phones"
                    type="group"
                >
                    <template #default="{ index }">
                        <div
                            v-if="slotProps.formData.phones[index]"
                            class="form-row sm:grid-cols-2 lg:grid-cols-3 pb-10 border-b border-b-gray-100 lg:border-none lg:pb-0"
                        >
                            <FormulateInput
                                :validation-messages="phoneValidationMessages()"
                                :validation-rules="{
                                    isEmailOrPhone: validateEmailOrPhone,
                                    isValidPhone: (e) =>
                                        validatePhone(e, index),
                                }"
                                class="input-tel"
                                error-behavior="submit"
                                name="phone"
                                placeholder="Pole numer telefonu"
                                type="tel"
                                validation="isEmailOrPhone|isValidPhone"
                                validation-name="Pole numer telefonu"
                            ></FormulateInput>

                            <div class="form-row">
                                <custom-select
                                    :default-value="
                                        fields && fields.phones[index]
                                            ? fields.phones[index].type
                                            : null
                                    "
                                    :options="[
                                        { label: 'Dom', value: 'home' },
                                        { label: 'Praca', value: 'job' },
                                        { label: 'Inny', value: 'other' },
                                    ]"
                                    :validation-messages="{
                                        hasPhone: 'Pole jest wymagane.',
                                    }"
                                    :validation-rules="
                                        phoneTypeValidationRules(
                                            slotProps.formData,
                                            index,
                                            'type'
                                        )
                                    "
                                    class="w-full"
                                    error-behavior="submit"
                                    name="type"
                                    placeholder="Typ numeru"
                                    validation="hasPhone"
                                    validation-name="Pole"
                                ></custom-select>

                                <custom-select
                                    :default-value="
                                        fields && fields.phones[index]
                                            ? fields.phones[index].isMobile
                                            : 0
                                    "
                                    :options="[
                                        {
                                            label: 'Komórka',
                                            value: 1,
                                        },
                                        {
                                            label: 'Stacjonarny',
                                            value: 0,
                                        },
                                    ]"
                                    :validation-messages="{
                                        hasPhone: 'Pole jest wymagane.',
                                    }"
                                    :validation-rules="
                                        phoneTypeValidationRules(
                                            slotProps.formData,
                                            index,
                                            'isMobile'
                                        )
                                    "
                                    class="w-full"
                                    name="isMobile"
                                    placeholder="Rodzaj"
                                    validation="hasPhone"
                                    validation-name="Pole"
                                ></custom-select>
                            </div>

                            <div class="form-row items-center">
                                <div
                                    v-if="
                                        slotProps.formData &&
                                        slotProps.formData.phones[index]
                                    "
                                >
                                    <button
                                        class="flex gap-2 items-center"
                                        type="button"
                                        @click="mainPhone = index"
                                    >
                                        <div class="checkbox">
                                            <label
                                                v-show="mainPhone === index"
                                            ></label>
                                        </div>
                                        główny
                                    </button>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template #remove="{ index }">
                        <button
                            v-if="slotProps.formData.phones[index]"
                            class="remove-phone lg:absolute right-[10%] top-4 mb-4 lg:mb-0 flex gap-2 items-center text-red-500"
                            type="button"
                            @click="
                                () => {
                                    slotProps.formData.phones.splice(index, 1);
                                }
                            "
                        >
                            <custom-icon icon="trash"/>
                            Usuń
                        </button>
                    </template>
                </FormulateInput>

                <div class="form-row sm:grid-cols-2 lg:grid-cols-3">
                    <!-- GENDER -->
                    <custom-select
                        :default-value="
                            slotProps.formData && slotProps.formData.gender
                        "
                        :options="genderOptions"
                        :validation-messages="{
                            isEmailAndGender: requireMessage('płeć'),
                        }"
                        :validation-rules="{
                            isEmailAndGender: validateGender,
                        }"
                        class="w-full"
                        name="gender"
                        placeholder="Wybierz płeć"
                        validation="isEmailAndGender"
                        validation-name="Pole płeć"
                    ></custom-select>

                    <!-- SOURCE -->
                    <custom-select
                        :default-value="
                            slotProps.formData && slotProps.formData.source
                        "
                        :options="sourceOptions"
                        class="w-full"
                        name="source"
                        placeholder="Skąd przyszedł?"
                        validation-name="Skąd przyszedł?"
                        @update-form="
                            (data) => {
                                slotProps.change(data);
                            }
                        "
                    ></custom-select>

                    <!-- INSTAGRAM NICK -->
                    <FormulateInput
                        v-show="
                            slotProps.formData &&
                            slotProps.formData.source === 'Instagram'
                        "
                        class="input-text"
                        error-behavior="submit"
                        name="nickInsta"
                        placeholder="Nick Instagram"
                        type="text"
                        validation-name="Instagram"
                    ></FormulateInput>
                </div>

                <!-- LANGUAGE -->
                <div class="flex gap-6 items-center relative w-max">
                    <span class="text-xs sm:text-base">Język kontaktu:</span>
                    <FormulateInput
                        :options="langOptions"
                        :validation-messages="{
                            isEmailAndLanguage:
                                requireMessage('język kontaktu'),
                        }"
                        :validation-rules="{
                            isEmailAndLanguage: validateLanguage,
                        }"
                        :value="
                            (slotProps.formData &&
                                slotProps.formData.languageId) ||
                            2
                        "
                        class="input-lang"
                        error-behavior="submit"
                        name="languageId"
                        type="radio"
                        validation="isEmailAndLanguage"
                        validation-name="Język pacjenta"
                    />
                </div>

                <FormulateInput
                    class="input-textarea"
                    error-behavior="submit"
                    name="notes"
                    placeholder="Dodatkowe informacje"
                    type="textarea"
                />

                <div
                    v-if="mode == 'edit'"
                    class="flex justify-between gap-8 mt-6 sm:mt-8 lg:mt-12"
                >
                    <custom-button
                        type="empty"
                        @click.native="slotProps.closePopup"
                    >
                        ANULUJ
                    </custom-button>

                    <FormulateInput
                        :disabled="isLoading"
                        :label="isLoading ? '...' : 'ZAPISZ'"
                        class="input-submit input-submit--filled"
                        type="submit"
                    />
                </div>
                <div
                    v-else-if="mode == 'create'"
                    class="flex justify-center mt-4 sm:mt-6 lg:mt-9"
                >
                    <FormulateInput
                        :disabled="isLoading"
                        :label="isLoading ? '...' : 'DODAJ PACJENTA'"
                        class="input-submit input-submit--filled input-submit--wider"
                        type="submit"
                    />
                </div>
            </FormulateForm>
        </template>
    </edit-popup>
</template>

<script>
import {mapActions} from "vuex";
import EditPopup from "@/components/v1/EditPopup";
import CustomButton from "@/elements/v1/CustomButton";
import CustomSelect from "@/elements/v1/form/CustomSelect";
import CustomIcon from "@/elements/v1/CustomIcon";
import {isValidPhoneNumber, isValidEmail} from "@/lib/regex";
import {handleErrors, handleSuccess} from "@/lib/notifications";
import {
    numbersOptions,
    langOptions,
    sourceOptions,
    genderOptions,
} from "@/lib/forms/patient";
import {translate} from "@/lib/utils";
import {callStatus} from "@/lib/constants";


export default {
    mixins: [
        {
            name: "DetailsForm",
            components: {
                EditPopup,
                CustomButton,
                CustomSelect,
                CustomIcon,
            },
            data() {
                return {
                    isLoading: false,
                    numbersOptions,
                    langOptions,
                    sourceOptions,
                    genderOptions,
                    prevFields: null,

                            mainPhone: null,
                        };
                    },
                    props: {
                        mode: String,
                        fields: {
                            type: Object,
                            require: false,
                            default: () => ({
                                name: "",
                                surname: "",
                                email: "",
                                source: null,
                                languageId: 1,
                                nickInsta: null,
                                notes: null,
                                phones: [
                                    {
                                        isMain: 1,
                                        phone: null,
                                    },
                                ],
                            }),
                            validator: function (value) {
                                if (
                                    Array.isArray(value) &&
                                    value?.length === 0
                                ) {
                                    return false;
                                }
                                return true;
                            },
                        },
                        forceActive: Boolean,
                    },
                    computed: {
                        isEdit() {
                            return this.mode === "edit";
                        },
                        valueTransformer() {
                            return {
                                from(value) {
                                    return value ? 1 : 0;
                                },
                                to(value) {
                                    return value === 1;
                                },
                            };
                        },
                    },
                    mounted() {
                        if (this.isEdit) {
                            this.fields.phones.forEach((phone, i) => {
                                if (phone.isMain) {
                                    this.mainPhone = i;
                                }
                            });
                        } else {
                            this.mainPhone = 0;
                        }
                    },
                    methods: {
                        ...mapActions("patients", [
                            "createPatient",
                            "editPatient",
                        ]),
                        translate,
                        async sendForm(rawData) {
                            console.log(rawData)
                            // const isEditMode = this.mode === "edit";
                            // const action = isEditMode
                            //     ? this.editPatient
                            //     : this.createPatient;
                            //
                            // const data = rawData;
                            //
                            // data.phones = data.phones
                            //     .filter(({ phone }) => phone)
                            //     .map((phone, i) => ({
                            //         ...phone,
                            //         isMain: i === this.mainPhone ? 1 : 0,
                            //         isMobile: +phone.isMobile,
                            //     }));
                            //
                            // if (
                            //     data.phones.length &&
                            //     !data.phones.some((phone) => phone.isMain)
                            // ) {
                            //     this.$notify({
                            //         group: "general",
                            //         type: "error",
                            //         text: "Przynajmniej jeden numer telefonu musi być główny",
                            //     });
                            //     return;
                            // }
                            //
                            // try {
                            //     await action({
                            //         data: data,
                            //         id: data.id,
                            //     });
                            //     handleSuccess(
                            //         this.translate(
                            //             isEditMode ? "updated" : "created",
                            //             "store",
                            //             "client"
                            //         )
                            //     );
                            // } catch (e) {
                            //     handleErrors(e);
                            // }
                        },
                        requireMessage(val) {
                            return `Pole ${val} jest wymagane`;
                        },
                        validateGender(e) {
                            const formData = e.getFormValues();
                            return (
                                !formData?.email ||
                                (formData.gender && formData.email)
                            );
                        },
                        validateLanguage(e) {
                            const formData = e.getFormValues();

                            return (
                                !formData?.email ||
                                (formData.languageId && formData.email)
                            );
                        },
                        validateEmail({ value }) {
                            return value ? isValidEmail(value) : true;
                        },
                        validateEmailOrPhone(e) {
                            const formData = e.value;
                            return (
                                formData?.email ||
                                formData?.phones?.some(({ phone }) => phone)
                            );
                        },
                        validatePhone(e, index) {
                            const { phones, email } = e.getFormValues();
                            const phone = phones[index]?.phone;
                            if (!phone) return true;
                            const isValidPhone = phone
                                ? isValidPhoneNumber(phone)
                                : true;

                            return email
                                ? isValidPhone
                                : isValidPhoneNumber(phone);
                        },
                        phoneTypeValidationRules(formData, index, type) {
                            const phone =
                                formData?.phones &&
                                formData?.phones[index]?.phone;
                            const hasValue =
                                formData?.phones[index] &&
                                (formData?.phones[index][type] ||
                                    formData?.phones[index][type] === 0);
                            return {
                                hasPhone: () => !phone || hasValue,
                            };
                        },
                        phoneValidationMessages() {
                            return {
                                isEmailOrPhone:
                                    this.requireMessage("numer telefonu"),
                                isValidPhone:
                                    "Numer telefonu powinien zawierać 11 cyfr i zaczynać się od numeru kierunkowego np. +48111222333",
                            };
                        },
                        removeNullValues(obj) {
                            return Object.fromEntries(
                                Object.entries(obj).filter(
                                    (value) =>
                                        value[1] !== null && value[1] !== ""
                                )
                            );
                        },
                    },
                },
            ],
};
</script>
