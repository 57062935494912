<template>
    <ul
        class="mt-4 space-y-2.5 max-h-[60vh] overflow-y-auto"
        v-if="messagesList && messagesList.length"
    >
        <li
            class="cursor-pointer p-2 pr-3 rounded-2xl bg-gray-100 group flex items-center w-full justify-between gap-2"
            v-for="(message, key) in messagesList"
            :key="key"
            @click="showMessage(message)"
            :class="
                message.isRead
                    ? 'bg-blue-600 border border-transparent text-white'
                    : 'bg-gray-100'
            "
        >
            <div class="flex items-center gap-2">
                <div
                    class="min-w-[36px] h-9 bg-gray-200 bg-opacity-50 rounded-xl flex justify-center items-center"
                    :class="
                        message.isRead
                            ? 'bg-white/10 text-white'
                            : 'bg-gray-300 text-blue-600 bg-opacity-50'
                    "
                >
                    <custom-icon icon="envelope"></custom-icon>
                </div>
                <div>
                    <h5 class="text-xs truncate">
                        Otrzymano nową wiadomość od:
                    </h5>
                    <strong class="font-bold">Autor</strong>
                </div>
            </div>

            <div class="flex items-center gap-3">
                <time class="font-light text-sm text-gray-500">
                    {{ formatDate(message.updatedAt) }}
                </time>

                <div
                    class="w-1.5 h-1.5 bg-red-500 rounded-full"
                    :class="message.isRead ? '' : 'opacity-0'"
                ></div>
            </div>
        </li>
    </ul>
</template>

<script>
import NotificationsMail from "@/components/v1/Navigation/NotificationsMail";

import CustomIcon from "@/elements/v2/CustomIcon";

export default {
    mixins: NotificationsMail.mixins,
};
</script>
