import axiosClient from "../apiClient";
import {smsFilter} from "@/lib/constants";

export function getSmses({
                             grouped,
                             patientUserId,
                             phone,
                             filters,
                             page = 1,
                             perPage = 25,
                         }) {
    const params = new URLSearchParams({
        page,
        per_page: perPage,
    });

    let hasSearch = false;

    if (filters) {
        filters.forEach(({value, type}) => {
            if (type === "is_read" && +value === smsFilter.ALL) return;
            hasSearch = true;
            params.append(
                type === "search" ? "search" : `filter[${type}]`,
                value
            );
        });
    }

    if (grouped) params.append("grouped", grouped);
    if (patientUserId) params.append("filter[patient_user_id]", patientUserId);
    if (phone) params.append("filter[phone]", phone);
    return axiosClient.get(`/clinic/smses?${params.toString()}`);
}

export function getUnreadCount(inOrOut) {
    return axiosClient.get(`/clinic/smses/count/in/unread`);
}

export function smsSearch(value) {
    const params = new URLSearchParams({
        page: 1,
        per_page: 100,
        grouped: true
    });
    params.append("search",value);
    return axiosClient.get(`/clinic/smses?${params.toString()}`);
}

export function setSmsStatus({id, isRead}) {
    return axiosClient.patch(`/clinic/smses/${id}`, {is_read: isRead});
}

export function setSmsesStatus({id}) {
    return axiosClient.patch(`/clinic/smses/mark-as-read`, {ids: id});
}

export function sendSms({data, id}) {
    const formData = {
        phone: data.phone,
        patient_user_id: id,
        content: data.content,
    };
    if (!id) {
        delete formData['patient_user_id'];
    }
    return axiosClient.post(`/clinic/smses`, formData);
}

export function getSmsTemplates() {
    return axiosClient.get("/clinic/sms-templates");
}
