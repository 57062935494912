<template>
    <svg fill="currentColor" viewBox="0 0 11.835 13.186">
        <path
            data-name="Path 5983"
            d="M11.674 4.986 6.236.122a.476.476 0 0 0-.636 0L.157 4.986a.487.487 0 0 0-.162.363V12.7a.482.482 0 0 0 .48.485h10.882a.482.482 0 0 0 .48-.485V5.349a.487.487 0 0 0-.163-.363Zm-.8 7.231H.957V5.568l4.958-4.435 4.958 4.435Z"
        />
        <path
            data-name="Path 5984"
            d="M7.422 8.057H4.11a.492.492 0 0 0-.492.492v4.348h.984V9.041h2.33v3.856h.985V8.549a.492.492 0 0 0-.495-.492Z"
        />
    </svg>
</template>
