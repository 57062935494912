<template>
    <footer
        class="text-[8px] leading-normal sm:text-[10px] text-gray-500 text-center mb-6"
    >
        <p class="uppercase">
            ©unident union {{ new Date().getFullYear() }}. all rights reserved.
        </p>
        <p>
            <span class="uppercase">realizacja</span>:
            <a
                class="hover:text-black transition-colors"
                href="https://brandnewstory.eu/"
                target="_blank"
                >BrandNewStory.eu</a
            >
        </p>
    </footer>
</template>

<script>
export default {
    name: "AppFooter",
};
</script>
