<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
    >
        <path
            data-name="Path 6018"
            d="m5.5 8.5 3 3s3.554-3.66 5.08-5.19"
            fill="none"
            stroke="currentColor"
            stroke-width="3"
        />
    </svg>
</template>
