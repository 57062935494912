<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.874"
        height="16.874"
        viewBox="0 0 16.874 16.874"
    >
        <path
            data-name="Path 5883"
            d="M7.431 0a7.431 7.431 0 1 0 7.431 7.431A7.44 7.44 0 0 0 7.431 0Zm0 13.49a6.059 6.059 0 1 1 6.059-6.059 6.066 6.066 0 0 1-6.059 6.059Z"
            fill="currentColor"
        />
        <path
            data-name="Path 5884"
            d="m16.673 15.704-3.933-3.933a.686.686 0 1 0-.97.97l3.933 3.933a.686.686 0 0 0 .97-.97Z"
            fill="currentColor"
        />
    </svg>
</template>
