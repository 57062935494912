<template>
    <svg
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        width="20.91"
        height="23.858"
        viewBox="0 0 20.91 23.858"
        stroke="2"
    >
        <path
            d="M10.439 19.308c-2.675 0-5.337.012-8.012 0-1.417-.012-2.379-.863-2.268-2.083a2.346 2.346 0 0 1 .592-1.354 7.76 7.76 0 0 0 2.342-5.83 8.614 8.614 0 0 1 .875-4.536 7.235 7.235 0 0 1 5.263-3.759c.419-.074.555-.21.53-.629-.037-.629.234-1 .666-.961.567.049.752.444.715.949-.025.444.16.567.579.653a7.087 7.087 0 0 1 5.941 5.953c.21 1.122.111 2.293.21 3.439a7.056 7.056 0 0 0 2.12 4.536 2.075 2.075 0 0 1 .629 2.342 1.987 1.987 0 0 1-2.046 1.294q-4.068-.037-8.135-.012Zm-.086-1.417h6.163c.7 0 1.393.025 2.1-.012a.922.922 0 0 0 .641-.345c.086-.185-.037-.493-.111-.727a1.1 1.1 0 0 0-.308-.37 8.118 8.118 0 0 1-2.206-4.388 28.653 28.653 0 0 1-.21-3.254 5.955 5.955 0 0 0-5.263-5.744 5.858 5.858 0 0 0-6.57 4.881 22.4 22.4 0 0 0-.136 2.576 8.922 8.922 0 0 1-1.832 5.263c-.283.37-.616.715-.9 1.1-.382.505-.173.961.456 1.035a3.3 3.3 0 0 0 .493.012c2.551-.028 5.119-.028 7.683-.028Zm.077 5.817a2.86 2.86 0 0 1-2.65-1.775c-.16-.394-.185-.777.259-1.011s.727.012.949.394c.814 1.38 2.058 1.368 2.884-.025.21-.357.468-.579.9-.382.456.21.481.567.333.974a2.844 2.844 0 0 1-2.675 1.825Z"
        />
    </svg>
</template>
