import * as api from "../api";

const SurveyActions = {
    FETCH_SURVEY: "fetchSurvey",
};

const SurveyMutations = {
    SET_SURVEY: "setSurvey",
};

const actions = {
    [SurveyActions.FETCH_SURVEY]: ({ commit }) => {
        return api
            .getSurveyResults()
            .then((response) => {
                commit(SurveyMutations.SET_SURVEY, response);
                return response;
            })
            .catch((e) => Promise.reject(e.response.data));
    },
};
const mutations = {
    [SurveyMutations.SET_SURVEY](state, payload) {
        state.survey = payload;
    },
};

const getters = {
    allSurveyResults: (state) => state.survey,
};

const state = {
    survey: null,
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
