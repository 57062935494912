<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14.413"
        height="14.413"
        viewBox="0 0 14.413 14.413"
    >
        <path
            d="M6.531 3.378a.676.676 0 0 1 1.351 0v3.468l2.4 1.6a.652.652 0 0 1 .163.937.62.62 0 0 1-.912.163l-2.7-1.8a.617.617 0 0 1-.3-.563ZM7.206 0A7.206 7.206 0 1 1 0 7.206 7.206 7.206 0 0 1 7.206 0ZM1.351 7.206a5.855 5.855 0 1 0 5.855-5.855 5.854 5.854 0 0 0-5.855 5.855Z"
            fill="currentColor"
        />
    </svg>
</template>
