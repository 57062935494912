
import { createStore } from 'vuex'
import auth from "./modules/auth";
import patients from "./modules/patients";
import visits from "./modules/visits";
import visitsTypes from "./modules/visitsTypes";
import survey from "./modules/survey";
import messages from "./modules/messages";
import statuses from "./modules/statuses";
import voip from "./modules/voip";
import calls from "./modules/calls";
import sms from "./modules/sms";
import doctors from "./modules/doctors";
import departments from "./modules/departments";
import stats from "./modules/stats";
import statsVisits from "./modules/statsVisits";
import statsPatients from "./modules/statsPatients";
import users from "./modules/users";
import dicts from "./modules/dicts";
import statsSales from "./modules/statsSales";

const store = createStore({
    modules: {
        auth,
        patients,
        visits,
        visitsTypes,
        survey,
        messages,
        voip,
        calls,
        sms,
        doctors,
        statuses,
        departments,
        stats,
        users,
        statsVisits,
        statsPatients,
        dicts,
        statsSales
    },
});

export default store;
