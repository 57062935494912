<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10.63"
        height="7.973"
        viewBox="0 0 10.63 7.973"
    >
        <path
            d="M9.634 0a1 1 0 0 1 .6 1.794l-4.52 3.388a.666.666 0 0 1-.8 0L.4 1.794A1 1 0 0 1 1 0ZM4.518 5.714a1.327 1.327 0 0 0 1.595 0l4.518-3.388v4.319A1.33 1.33 0 0 1 9.3 7.973H1.329A1.329 1.329 0 0 1 0 6.644V2.325Z"
            fill="currentColor"
        />
    </svg>
</template>
