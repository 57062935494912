import { createRouter, createWebHistory } from 'vue-router'

import authenticatedRoutes from "./routes/authenticated";
import unauthenticatedRoutes from "./routes/unauthenticated";
import store from "../store";


const routes = [...authenticatedRoutes, ...unauthenticatedRoutes];
const router = createRouter({
    history: createWebHistory( "/clinic"),
    routes,
});

router.beforeEach(async (to, from, next) => {
    let isAuth = store.getters["auth/isAuth"];
    if (!isAuth) isAuth = await store.dispatch("auth/authorize");
    const {requiresAuth} = to.meta;
    let role = store.getters["auth/roles"];
    const stats = '/statistics';
    const statsVisits = '/statistics/visit-stats';
    const statsCalls = '/statistics/calls-stats';
    const employeeCalls = '/statistics/employee-stats';
    const admin = 'Administrator';
    const root = 'Root';
    const coo = 'Coordinator';
    if (requiresAuth && !isAuth) {
        next("/login");
    } else if (!requiresAuth && isAuth) {
        next("/");
    } else {
        if ([stats, statsVisits, statsCalls, employeeCalls].includes(to.path)) {
            if ([coo, admin, root].includes(role[0].name)) {
                next();
            } else {
                next("/");
            }
        } else {
            next()
        }
    }

});


export default router;
