<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        viewBox="0 0 17 17"
    >
        <g fill="currentColor">
            <path data-name="Rectangle 54" d="M0 0h5v5H0z" />
            <path data-name="Rectangle 59" d="M0 6h5v5H0z" />
            <path data-name="Rectangle 62" d="M0 12h5v5H0z" />
            <path data-name="Rectangle 55" d="M6 0h5v5H6z" />
            <path data-name="Rectangle 58" d="M6 6h5v5H6z" />
            <path data-name="Rectangle 61" d="M6 12h5v5H6z" />
            <path data-name="Rectangle 56" d="M12 0h5v5h-5z" />
            <path data-name="Rectangle 57" d="M12 6h5v5h-5z" />
            <path data-name="Rectangle 60" d="M12 12h5v5h-5z" />
        </g>
    </svg>
</template>
