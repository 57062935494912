export default {
    methods: {
        getIconColorClass(status) {
            return {
                "text-gray-500": ["pending"].includes(status),
                "text-green-500": ["answered", "ended"].includes(status),
                "text-red-500": ["rejected", "not_answered"].includes(status),
                "text-sky-500": ["documents", "cash"].includes(status),
                "text-blue-950": ["neutral"].includes(status),
            };
        },
        getIconBackgroundClass(status) {
            return {
                "bg-neutral-200": [
                    "neutral"
                ].includes(status),
                "bg-sky-100": [
                    "cash"
                ].includes(status),
                "bg-gray-7 group-hover:bg-gray-12 transition-colors": [
                    "pending", "documents"
                ].includes(status),
                "bg-green-7 group-hover:bg-green-12 transition-colors": [
                    "answered",
                    "ended",
                ].includes(status),
                "bg-red-7 group-hover:bg-red-12 transition-colors": [
                    "rejected",
                    "not_answered",
                ].includes(status),
            };
        },
    },
};
