<template>
    <div>
        <button
            ref="button"
            @click="isOpen = !isOpen"
            class="group fixed bottom-10 right-10 w-12 h-12 2xl:w-16 2xl:h-16 flex flex-col justify-center items-center gap-1.5 bg-gradient-custom rounded-2xl 2xl:rounded-3xl z-10"
        >
            <span
                class="w-1 2xl:w-1.5 h-3 2xl:h-4 bg-white transform transition-transform group-hover:-translate-y-0.5 duration-300"
            ></span
            ><span
                class="w-1 2xl:w-1.5 h-1 2xl:h-1.5 bg-white transform transition-transform group-hover:translate-y-0.5 duration-300"
            ></span>
        </button>

        <div
            v-click-outside="clickOutside"
            v-show="isOpen"
            class="z-50 bg-white fixed bottom-32 right-10 rounded-2xl p-6 shadow-xl max-h-[80vh] overflow-y-auto"
        >
            <FormKit
                v-model="formData"
                :errors="errors"
                form-class="flex flex-col gap-3"
                @submit="submit"
                :actions="null"
                type="form"
                :incomplete-message="null"
            >
                <h2 class="text-blue-500 font-bold text-lg my-3">Zgłoś błąd</h2>

                <FormKit
                    name="title"
                    type="text"
                    placeholder="Tytuł*"
                    outer-class="input-text input-text--light"
                    error-behavior="submit"
                    validation="required"
                    validation-name="Pole tytuł"
                ></FormKit>

                <FormKit
                    type="text"
                    name="name"
                    placeholder="Imię i nazwisko*"
                    outer-class="input-text input-text--light"
                    error-behavior="submit"
                    validation="required"
                    validation-name="Imię i nazwisko"
                ></FormKit>

                <FormKit
                    outer-class="input-email input-email--light"
                    type="text"
                    name="email"
                    placeholder="Adres e-mail*"
                    error-behavior="submit"
                    validation="required|isValidEmail"
                    :validation-rules="{
                        isValidEmail: (e) => validateEmail(e),
                    }"
                    :validation-messages="{
                        isValidEmail: 'Niepoprawny format emaila',
                    }"
                    validation-name="Adres e-mail"
                ></FormKit>

                <FormKit
                    name="url"
                    type="text"
                    placeholder="URL"
                    outer-class="input-text input-text--light"
                    error-behavior="submit"
                ></FormKit>

                <textarea
                    name="description"
                    class="bg-gray-100 w-full focus:outline-none rounded-xl sm:rounded-2xl h-10 sm:h-24 px-6 py-3 text-blue-500 placeholder-gray-500 text-xs sm:text-base"
                    placeholder="Opis"
                    v-model="formData.description"
                ></textarea>

                <custom-input-file @update="({ value }) => setFile(value)" />

                <div class="flex justify-center mt-4">
                    <FormKit
                        outer-class="input-submit input-submit--filled-blue input-submit--wider"
                        type="submit"
                        :disabled="isLoading"
                        :label="isLoading ? '...' : 'Prześlij'"
                    />
                </div>
            </FormKit>
        </div>
    </div>
</template>

<script>
import vClickOutside from "click-outside-vue3"
import CustomButton from "@/elements/v2/CustomButton";
import CustomInput from "@/elements/v2/form/CustomInput";
import CustomInputFile from "@/elements/v2/form/CustomInputFile";
import { isValidEmail } from "@/lib/regex";
import { issueBug } from "@/api/lib/bugs";

export default {
    name: "BugReportPopup",
    components: {
        CustomInputFile,
        CustomInput,
        CustomButton,
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    data() {
        return {
            isLoading: false,
            isOpen: false,
            errors: [],
            formData: {},
            files: null,
        };
    },
    methods: {
        clickOutside(e) {
            if (e.target === this.$refs.button) return;
            this.isOpen = false;
        },
        setFile(file) {
            this.files = file;
        },
        async submit(data) {
            try {
                await issueBug({ files: [this.files], ...data });
                this.$notify({
                    group: "general",
                    type: "success",
                    text: "Wysłano zgłoszenie",
                });
            } catch (err) {
                throw err;
            }
        },
        validateEmail({ value }) {
            return value ? isValidEmail(value) : true;
        },
    },
};
</script>
