import * as api from "@/api";

export const VoipActions = {
    GET_LOGINS: "getLogins",
    GET_LOGIN: "getLogin",
    SET_LOGIN: "setLogin",
    SET_STATUS: "setStatus",
    GET_STATUS: "getStatus",
    SET_DEPARTMENT: "setDepartment",
    GET_DEPARTMENTS: "getDepartments",
    GET_QUEUES: "getQueues",
    GET_PREFERED_QUEUE: "getPreferedQueue",
    SET_PREFERED_QUEUE: "setPreferedQueue",
    UPDATE_PREFERED_QUEUE: "updatePreferedQueue",
};

const VoipMutations = {
    SET_LOGINS: "setLogins",
    SET_LOGIN: "setLogin",
    SET_STATUS: "setStatus",
    SET_DEPARTMENT: "setDepartment",
    SET_DEPARTMENTS: "setDepartments",
    SET_QUEUES: "setQueues",
    SET_PREFERED_QUEUE: "setPreferedQueue",
};

const actions = {
    [VoipActions.GET_LOGIN]: async ({ commit }) => {
        const { data } = await api
            .getLogin()
            .catch((e) => Promise.reject(e.response));

        if (data.data) commit(VoipMutations.SET_LOGIN, data.data.item);
    },
    [VoipActions.SET_LOGIN]: async (
        { dispatch },
        { id: loginID, withOvertaken }
    ) => {
        return api
            .setLogin(loginID, withOvertaken)
            .then(() => {
                dispatch(VoipActions.GET_LOGIN);
            })
            .catch((e) => {
                if (e.response.status !== 400) {
                    return Promise.reject(e.response);
                }

                if (e.response.status === 400) {
                    return {
                        taken: loginID,
                    };
                }

                return null;
            });
    },
    [VoipActions.SET_STATUS]: async ({ commit }, status) => {
        const res = await api
            .setStatus(status)
            .catch((e) => Promise.reject(e.response));

        commit(VoipMutations.SET_STATUS, status);

        return res;
    },
    [VoipActions.GET_STATUS]: async ({ commit }) => {
        const { data } = await api
            .getStatus()
            .catch((e) => Promise.reject(e.response));

        if (data) {
            commit(VoipMutations.SET_STATUS, data.data.status);
        }
    },
    [VoipActions.GET_LOGINS]: async ({ commit }) => {
        const { data } = await api
            .getLogins()
            .catch((e) => Promise.reject(e.response));

        commit(VoipMutations.SET_LOGINS, data.data.items);
    },

    [VoipActions.GET_QUEUES]: async ({ commit }) => {
        const { data } = await api
            .getQueues()
            .catch((e) => Promise.reject(e.response));

        const queues = data.data.items;

        commit(VoipMutations.SET_QUEUES, queues);
    },
    [VoipActions.GET_PREFERED_QUEUE]: ({ commit }) => {
        commit(VoipMutations.SET_PREFERED_QUEUE, [
            {
                key: "wszystkie",
                value: -1,
            },
        ]);
    },
    [VoipActions.SET_DEPARTMENT]: async ({ commit, dispatch }, department) => {
        commit(VoipMutations.SET_DEPARTMENT, department);

        dispatch(
            "calls/refetchCalls",
            {
                processingPage: 1,
                completedPage: 1,
                reset: true,
                exclude: [],
            },
            { root: true }
        );
    },
    [VoipActions.GET_DEPARTMENTS]: async ({ commit }) => {
        const { data } = await api
            .getDepartments()
            .catch((e) => Promise.reject(e.response));

        const departments = data.data.data;

        commit(VoipMutations.SET_DEPARTMENTS, departments);
    },
    [VoipActions.UPDATE_PREFERED_QUEUE]: async ({ commit }, payload) => {
        commit(VoipMutations.SET_PREFERED_QUEUE, payload);
    },
    [VoipActions.SET_PREFERED_QUEUE]: async ({ commit, dispatch }, payload) => {
        commit(VoipMutations.SET_PREFERED_QUEUE, payload);

        dispatch(
            "calls/refetchCalls",
            {
                processingPage: 1,
                completedPage: 1,
                reset: true,
                exclude: [],
            },
            { root: true }
        );
    },
};
const mutations = {
    [VoipMutations.SET_LOGINS](state, payload) {
        state.logins = payload;
    },
    [VoipMutations.SET_LOGIN](state, payload) {
        state.login = payload;
    },
    [VoipMutations.SET_STATUS](state, payload) {
        state.status = payload;
    },
    [VoipMutations.SET_QUEUES](state, payload) {
        state.queues = payload;
    },
    [VoipMutations.SET_PREFERED_QUEUE](state, payload) {
        state.preferedQueue = payload;
    },
    [VoipMutations.SET_DEPARTMENT](state, payload) {
        state.department = payload;
    },
    [VoipMutations.SET_DEPARTMENTS](state, payload) {
        state.departments = payload;
    },
};

const getters = {
    logins: (state) => state.logins,
    login: (state) => state.login,
    status: (state) => state.status,
    queues: (state) => state.queues,
    department: () => state.department,
    departments: (state) => state.departments,
    preferedQueue: (state) => state.preferedQueue,
};

const state = {
    logins: [],
    login: null,
    status: null,
    queues: null,
    department: "-1",
    departments: null,
    preferedQueue: null,
};

export default {
    namespaced: true,
    getters,
    actions,
    mutations,
    state,
};
