import axios from "axios";
import visits from "./fakeData/visits.json";
import survey from "./fakeData/survey.json";
import slots from "./fakeData/doctor-object.json";
import fields from "./fakeData/additional-fields.json";

axios.defaults.baseURL = "/api";

// visits
export async function getVisits() {
    // return axios.get(`/visits`);
    return new Promise((resolve, reject) => {
        if (visits) {
            resolve(visits);
        } else {
            reject(new Error("Not found"));
        }
    });
}

// survey
export async function getSurveyResults() {
    // return axios.get(`/survey`);
    return new Promise((resolve, reject) => {
        if (visits) {
            resolve(survey);
        } else {
            reject(new Error("Not found"));
        }
    });
}


export async function getAdditionalFields() {
    return new Promise((resolve, reject) => {
        if (fields) {
            resolve(fields);
        } else {
            reject(new Error("Not found"));
        }
    });
}

