<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22.584"
        height="17.373"
        viewBox="0 0 22.584 17.373"
    >
        <path
            data-name="Path 5985"
            d="M10.075 17.373a13.166 13.166 0 0 0 11.92-9.183 12.347 12.347 0 0 0 .586-3.762c0-.635-.2-.879-.782-1.026-.928-.2-1.857-.44-2.736-.635a.845.845 0 0 0-1.026.537c-.44 1.026-.879 2-1.27 3.029a.87.87 0 0 0 .293 1.124c.391.342.831.684 1.27 1.026.2.147.2.244.1.44a9.918 9.918 0 0 1-4.251 4.251c-.2.1-.293.1-.44-.1-.342-.44-.684-.831-1.026-1.27a.87.87 0 0 0-1.124-.293c-.977.391-1.954.831-2.98 1.27a.95.95 0 0 0-.586 1.173l.586 2.638a.85.85 0 0 0 .977.782Z"
            fill="currentColor"
        />
        <g
            data-name="Group 8117"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
        >
            <path
                data-name="Path 6023"
                d="M5.006.707 1.414 4.298l3.592 3.591"
            />
            <path data-name="Path 6024" d="M1.415 4.298h9.91" />
        </g>
    </svg>
</template>
