<template>
    <edit-popup
        v-bind="$props"
        :callback="sendForm"
        :pencil="pencil"
        :force-active="forceActive"
    >
        <template v-slot="slotProps">
            <FormulateForm
                class="form"
                v-model="slotProps.formData"
                @submit="
                    (data) => {
                        if (sendForm(data)) slotProps.closePopup();
                    }
                "
                @input="slotProps.change"
            >
                <FormulateInput
                    class="input-textarea"
                    type="textarea"
                    placeholder="Dodatkowe informacje"
                    error-behavior="submit"
                    :name="name"
                />
                <div class="flex justify-between gap-8 mt-6 sm:mt-8 lg:mt-12">
                    <custom-button
                        @click="slotProps.closePopup"
                        type="empty"
                    >
                        ANULUJ
                    </custom-button>

                    <FormulateInput
                        class="input-submit input-submit--filled"
                        type="submit"
                        :disabled="isLoading"
                        :label="isLoading ? '...' : 'ZAPISZ'"
                    />
                </div>
            </FormulateForm>
        </template>
    </edit-popup>
</template>

<script>
import { mapActions } from "vuex";
import EditPopup from "./EditPopup";
import CustomButton from "@/elements/v1/CustomButton";
import { handleErrors } from "@/lib/notifications";
import { translate } from "@/lib/utils";


export default {
    mixins: [
        {
            name: "NotesForm",
            components: {
                EditPopup,
                CustomButton,
            },
            data: () => ({
                isLoading: false,
            }),
            props: {
                fields: Object,
                forceActive: Boolean,
                overheading: Object,
                title: String,
                name: {
                    type: String,
                    default: "notes",
                },
                pencil: {
                    type: [Boolean, String],
                    default: "pencilSquare",
                },
                callback: {
                    type: [Function, Boolean],
                    default: false,
                },
            },
            methods: {
                ...mapActions("patients", ["editPatient"]),
                ...mapActions("visits", ["editVisit"]),
                translate,
                async sendForm(data) {
                    if (this.callback) return this.callback(data);
                    const editAction =
                        this.name === "note"
                            ? this.editVisit
                            : this.editPatient;
                    await editAction({ data, id: data.id }).catch((e) =>
                        handleErrors(e)
                    );
                    this.$notify({
                        group: "general",
                        type: "success",
                        text: this.translate(
                            "updated",
                            "store",
                            this.name === "note" ? "note" : "client"
                        ),
                    });
                    return true;
                },
            },
        },
    ],
};
</script>
