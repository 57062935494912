import * as api from "@/api/lib/user";

const UsersActions = {
    FETCH_USERS: "fetchUsers",
    UPDATE_PAGE: "updatePage",
};

const UsersMutations = {
    SET_USERS: "setUsers",
    SET_LAST_PAGE: "setLastPage",
    SET_CURRENT_PAGE: "setCurrentPage",
};

const actions = {
    [UsersActions.FETCH_USERS]: async ({ commit, state }, pageNumber) => {
        const { data } = await api
            .getUsers(pageNumber, state.usersPerPage, state.userType)
            .catch((e) => Promise.reject(e.response.data));
        if (data.data.nextPageUrl) {
            commit(UsersMutations.SET_LAST_PAGE, +data.data.currentPage);
        }
        commit(UsersMutations.SET_USERS, {
            data: data.data.data,
        });
    },

    [UsersActions.UPDATE_PAGE]: async ({ commit, state }, pageNumber) => {
        commit(UsersMutations.SET_CURRENT_PAGE, pageNumber);
    }

};
const mutations = {
    [UsersMutations.SET_USERS](state, payload) {
        state.users.splice(state.lastPage, 0, payload.data);
        if (payload?.data?.length)
            state.allUsers = [...state.allUsers, ...payload.data];
    },
    [UsersMutations.SET_LAST_PAGE](state, lastPageIndex) {
        state.lastPage = lastPageIndex;
    },
    [UsersMutations.SET_CURRENT_PAGE](state, currentPageIndex) {
        state.currentPage = currentPageIndex;
    }
};

const getters = {
    usersPerPage: (state) => state.usersPerPage,
    currentPage: (state) => state.currentPage,
    lastPage: (state) => state.lastPage,
    allUsers: (state) => state.allUsers,
    users: (state) => (pageNumber) => {
        return state.users[pageNumber - 1];
    },

};

const state = {
    users: [],
    allUsers: [],
    lastPage: null,
    usersPerPage: 10,
    userType: "Receptionist,Coordinator",
    currentPage: 1,
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
