<template>
    <input
        class="bg-gray-100 w-full focus:outline-none rounded-xl sm:rounded-2xl h-10 sm:h-12 px-6 text-blue-500 placeholder-gray-500 text-xs sm:text-base"
        :type="type"
        @change="changeInput"
        :value="defaultValue"
        :placeholder="placeholder"
    />
</template>

<script>
export default {
    name: "CustomInput",
    props: {
        type: {
            type: String,
            require: false,
            default: "text",
        },
        name: {
            type: String,
            require: true,
        },
        placeholder: {
            type: String,
            require: false,
        },
        defaultValue: {},
    },
    methods: {
        changeInput(e) {
            this.$emit("update-form", {
                key: this.name,
                val: e.target.value,
            });
        },
    },
};
</script>
