<template>
    <nav
        class="bg-white px-4 sm:pr-10 sm:pl-20 sm:py-[22px] w-full flex items-center justify-between"
    >
        <img
            v-if="logoPath"
            :src="logoPath"
            alt="logo"
            class="max-w-[100px] sm:max-w-[140px] py-2"
        />

        <div>
            <app-navbar-desktop @logout="logoutUser"></app-navbar-desktop>
            <app-navbar-mobile
                @logout="logoutUser"
                :items="items"
            ></app-navbar-mobile>
        </div>
    </nav>
</template>

<script>
import AppNavbar from "@/components/v1/Navigation/AppNavbar.vue";
import AppNavbarDesktop from "@/components/v2/Navigation/AppNavbarDesktop.vue";
import AppNavbarMobile from "@/components/v2/Navigation/AppNavbarMobile.vue";
import CustomIcon from "@/elements/v2/CustomIcon";

export default {
    mixins: AppNavbar.mixins,
    components: {
        AppNavbarDesktop,
        AppNavbarMobile,
        CustomIcon,
    },
};
</script>
