import { callStatus } from "@/lib/constants";
import axiosClient from "../apiClient";
import { formatDate } from "@/mixins/date";
import call from "@/mixins/call";

const PER_PAGE = 25;

const appendFiltersToParams = (filters, params, isGroup) => {
    filters.forEach((filter) => {
        if (filter.user_id) {
            params.append(`filter[user_id]`, filter.user_id);
        }

        if (filter.type && filter.type !== "date") {
            params.append(
                `filter${isGroup ? "[calls]" : ""}[type]`,
                filter.type
            );
        }

        if (filter.status) {
            params.append(
                `filter${isGroup ? "[calls]" : ""}[status]`,
                filter.status
            );
        }

        if (filter.called_back) {
            params.append(
                `filter${isGroup ? "[calls]" : ""}[called_back]`,
                filter.called_back
            );
        }

        if (filter.start && filter.end) {
            const dateFormat = "YYYY-MM-DD";
            params.append(
                `filter${isGroup ? "[calls]" : ""}[created_between]`,
                `${formatDate(filter.start, dateFormat)},${formatDate(
                    filter.end,
                    dateFormat
                )}`
            );
        }
    });
};

export async function getCalls(
    status,
    queues,
    page,
    filters,
    searchNeedle,
    departmentId
) {
    const params = new URLSearchParams({
        page,
        grouped: 1,
        "filter[handling_status]": status,
    });

    if (departmentId && +departmentId !== -1) {
        params.append("filter[department_id]", departmentId);
    }

    appendFiltersToParams(filters, params);

    if (status !== callStatus.PENDING) params.append("per_page", PER_PAGE);

    if (searchNeedle) {
        params.append("filter[search]", searchNeedle);
    }

    if (queues?.length && (queues.length > 1 || queues[0].value !== -1)) {
        params.append(
            "filter[voip_queue_id]",
            queues.reduce((acc, val) => {
                if (val.value === -1) return acc;
                return `${acc ? acc + "," : acc}${val.value}`;
            }, "")
        );
    }

    return axiosClient.get(`/clinic/calls?${params.toString()}`);
}

export async function getCall(id) {
    return axiosClient.get(`/clinic/calls/${id}`);
}

export async function updateCall(payload) {
    return axiosClient.patch(`/clinic/calls/${payload.id}`, payload.data);
}

export async function updateCallsGroup(payload) {
    return axiosClient.patch(`/clinic/call-groups/${payload.id}`, payload.data);
}

export function fetchCallsGroup(id, filters, queues, departmentId) {
    const params = new URLSearchParams();
    appendFiltersToParams(filters, params, true);

    if (departmentId && departmentId !== "-1") {
        params.append("filter[calls][department_id]", departmentId);
    }

    if (queues?.length && (queues.length > 1 || queues[0].value !== -1)) {
        params.append(
            "filter[calls][voip_queue_id]",
            queues.reduce((acc, val) => {
                if (val.value === -1) return acc;
                return `${acc ? acc + "," : acc}${val.value}`;
            }, "")
        );
    }

    return axiosClient.get(`/clinic/call-groups/${id}?${params.toString()}`);
}

export function makeCall(phone, callbackId, departmentId) {
    const params = new URLSearchParams();
    if (callbackId) {
        params.append("calling_back_call_id", callbackId);
    }
    if (departmentId) {
        params.append("department_id", departmentId);
    }
    return axiosClient.post(`/clinic/calls?phone=${phone}&${params.toString()}`);
}

export function hangupCall(id) {
    return axiosClient.post(`/clinic/calls/${id}/hangup`);
}
