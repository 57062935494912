<template>
    <h2
        class="flex items-center gap-0.5 sm:gap-2 text-gray-500 font-medium text-xs sm:text-md lg:text-lg leading-none"
    >
        <custom-icon v-if="icon" :icon="icon" :w="iconSize" :h="iconSize" />
        <div class="w-4/5">{{ text }}</div>
    </h2>
</template>

<script>
import CustomIcon from "./CustomIcon";

export default {
    mixins: [{
    name: "CustomHeading",
    components: { CustomIcon },
    props: {
        icon: {
            type: String,
            require: false,
        },
        text: {
            type: String,
            require: true,
        },
        iconSize: {
            type: Number,
            default: 18,
        },
    },
    }]
};
</script>
