<template>
    <div class="relative">
        <button
            @click.stop="active = !active"
            class="relative rounded-lg bg-gray-100 flex items-center justify-center w-10 h-10"
        >
            <div
                class="w-1.5 h-1.5 absolute top-1.5 right-2 rounded-full"
                :class="hasUnreadMessages ? 'bg-red-500' : 'bg-green-500'"
            ></div>
            <custom-icon icon="bell" :w="16" :h="16" />
        </button>

        <div
            v-show="active"
            class="absolute top-full pt-3 left-1/2 transform -translate-x-1/2 rounded-xl z-20"
            v-click-outside="() => active && closeDropdown()"
        >
            <div
                class="py-6 px-4 shadow border-gray-200 text-sm flex flex-col bg-white rounded-lg w-screen max-w-md"
            >
                <div class="whitespace-nowrap font-bold text-lg">
                    Wiadomości
                </div>

                <div class="flex mt-3 gap-4 border-b border-gray-200">
                    <button
                        @click="type = 'sms'"
                        class="relative pb-3"
                        :class="
                            type === 'sms' ? 'text-blue-600' : 'text-gray-400'
                        "
                    >
                        SMS
                        <div
                            class="absolute -bottom-px rounded left-0 w-full h-0.5 bg-green-400 transition-opacity"
                            :class="
                                type === 'sms' ? 'opacity-100' : 'opacity-0'
                            "
                        ></div>
                    </button>

                    <!-- temporary hidden -->
                    <!-- <button
                        @click="type = 'mail'"
                        class="relative pb-3"
                        :class="
                            type === 'mail' ? 'text-blue-600' : 'text-gray-400'
                        "
                    >
                        e-mail
                        <div
                            class="absolute -bottom-px rounded left-0 w-full h-0.5 bg-green-400 transition-opacity"
                            :class="
                                type === 'mail' ? 'opacity-100' : 'opacity-0'
                            "
                        ></div>
                    </button>  -->
                </div>

                <div>
                    <!-- temporary hidden -->
                    <!-- <notifications-mail v-if="type === 'mail'" /> -->
                    <notifications-sms v-if="type === 'sms'" @click="close" />
                </div>
            </div>
        </div>

        <div
            v-show="newChatFrom"
            class="z-20 absolute -bottom-24 left-1/2 transform -translate-x-1/2 p-4 whitespace-nowrap pr-6 shadow-lg flex gap-2 rounded-2xl"
        >
            <div
                class="min-w-[48px] h-12 flex justify-center items-center rounded-2xl bg-gray-100"
            >
                <custom-icon icon="envelope"></custom-icon>
            </div>

            <div class="text-xs">
                <h5>Otrzymano nową wiadomość od:</h5>
                <p class="text-lg font-bold text-green-400">
                    {{ newChatFrom }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import NotificationsDropdown from "@/components/v1/Navigation/NotificationsDropdown";

import CustomIcon from "@/elements/v2/CustomIcon";
import NotificationsMail from "@/components/v2/Navigation/NotificationsMail";
import NotificationsSms from "@/components/v2/Navigation/NotificationsSms";

export default {
    mixins: NotificationsDropdown.mixins,
    components: {
        CustomIcon,
        NotificationsMail,
        NotificationsSms,
    },
    methods:{
        close(){
            this.active = false;
        }
    }
};
</script>
