<template>
    <div>
        <edit-button :button-type="buttonType" @open="active = true" v-if="showIcon" :mode="mode" />
        <Teleport to="body">
        <popup-wrapper
            :active="active"
            @close="closePopup"
            :outer-class="outerClass"

        >
            <div class="flex justify-between">
            <h2 class="font-semibold text-xl mb-8">{{ title }}</h2>
                <div @click="closePopup" class="cursor-pointer">
                <custom-icon :w="10" :h="10" icon="closeMark"/>
                </div>
            </div>

            <slot
                :form-data="formData"
                :closePopup="closePopup"
                :send="send"
                :change="change"
            ></slot>
        </popup-wrapper>
        </Teleport>
    </div>
</template>

<script>
import EditPopup from "@/components/v1/EditPopup";
import EditButton from "@/elements/v2/EditButton";
import CustomIcon from "@/elements/v2/CustomIcon.vue";
import PopupWrapper from "@/components/v2/PopupWrapper.vue";
import { Teleport } from "vue";

export default  {
    mixins: EditPopup.mixins,
    components: {
        CustomIcon,
        EditButton,
        PopupWrapper
    },
    props: {
        showIcon: {
            type: Boolean,
            default: true,
        },
        buttonType: String,
        mode: String,
        id: String,
        fields: {
            type: Object,
            require: true,
        },
        methods: {
            closePopup() {
                this.active = false;
                this.$emit("on-close");
                if (this.mode === 'create'){
                this.$formkit.reset(this.id);
                }
            },
        }
    },
};
</script>
