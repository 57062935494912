import axiosClient from "../apiClient";

export function getPatients(page, per_page, filters = []) {

    const params = new URLSearchParams({
        page,
        per_page,
    });

    if (filters.length) {
        filters.forEach((filter) => {
            if (filter.key === 'metafields'){
                params.append(`filter[${filter.key}][0][${filter.metafield_id_key}]`, filter.metafield_id_value);
                params.append(`filter[${filter.key}][0][${filter.metafield_selectable_value_id_key}]`, filter.metafield_selectable_value_id_value);
            } else {
                params.append(`filter[${filter.key}]`, filter.value);
            }
        });
    }


    return axiosClient.get(
        `/clinic/patients?${params.toString()}`
    );
}

export function getPatient(patientID) {
    return axiosClient.get(`/clinic/patients/${patientID}`);
}

export function deletePatient(patientID) {
    return axiosClient.delete(`/clinic/patients/${patientID}`);
}

export function createPatient(payload) {
    return axiosClient.post("/clinic/patients", payload.data);
}

export function editPatient(payload) {
    const { id, data } = payload;

    return axiosClient.patch(`/clinic/patients/${id}`, data);
}

export function searchPatients(needle) {
    return axiosClient.get(`/clinic/patients?search=${needle}&per_page=99`);
}

export function getContactHistory({ patientId, filters }) {
    const params = new URLSearchParams({
        user_id: patientId,
    });

    if (filters) {
        filters.forEach(({ value, type }) => {
            params.append(`filter[${type}]`, value);
        });
    }

    return axiosClient.get(
        `/clinic/communication-history?${params.toString()}`
    );
}


export function sendMedicalRecords(data) {
    return axiosClient.post("/clinic/medical-records", data);
}
