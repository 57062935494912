import store from "../store";

export const getNavigation = {
    data() {
        return {
            items: [
                // this.getLink("Strona Główna", "home", "/"),
                this.getLink("Centralka", "phoneEmpty", "/"),
                this.getLink("Rejestr połączeń", "callsList", "/calls"),
                this.getLink("Pacjenci", "person", "/patients"),
                this.getLink("Wizyty", "menu", "/visits", [
                    this.getLink("Kalendarz Wizyt", "home", "/visits/calendar"),
                    this.getLink("Archiwum wizyt", "home", "/visits/archive"),
                ]),
                // this.getLink("Ustawienia", "settings", "/settings"),
                this.getLink("Wiadomości", "envelope", "/messages/sms", [
                    this.getLink("Wiadomości SMS", "home", "/messages/sms"),
                ]),

            ],
        };
    },
    methods: {
        getLink: (
            name,
            icon,
            path,
            children = null,
            permission = null,
            access = true
        ) => ({
            name,
            path,
            icon,
            children,
            permission,
            access,
        }),
        reduceNavigationAccess: function () {
            const role = store.getters["auth/roles"];
            const findPermissions = this.items.filter(
                (item) => item.permission !== null
            );
            const checkPermissions = findPermissions.filter(
                (item) => !item.permission.includes(role[0].name)
            );
            if (checkPermissions.length) {
                checkPermissions.forEach((item) => (item.access = false));
            } else {
                this.items.forEach((item) => (item.access = true));
            }
        },
    },
    mounted() {
        this.reduceNavigationAccess();

        if (window.version === 2) {
            this.items.push(
                this.getLink(
                    "Statystyki",
                    "chart",
                    "/statistics",
                    [
                        this.getLink(
                            "Statystyki połączeń",
                            "Home",
                            "/statistics"
                        ),
                    ],
                    ["Root", "Administrator", "Coordinator"]
                )
            );
            this.items.push(
                this.getLink("Support", "support", "/support"),
            );
        }
    },
};
