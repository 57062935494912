<template>
    <edit-popup
        :fields="fields"
        :force-active="forceActive"
        :overheading="{
            text: 'Wizyta',
            icon: 'menu',
        }"
        :pencil="isEdit ? 'pencilSquare' : false"
        :title="mode === 'edit' ? 'Edytuj wizytę' : 'Dodaj nową wizytę'"
        custom-class="top-5 md:top-8 right-5 md:right-8"
    >
        <template v-slot="slotProps">
            <FormulateForm
                v-if="mode !== 'edit' || slotProps.formData"
                ref="formulateForm"
                v-model="slotProps.formData"
                :errors="slotProps.errors"
                :values="slotProps.formData"
                class="form"
                @input="slotProps.change"
                @submit="
                    (data) => {
                        if (sendForm(data, slotProps.send))
                            slotProps.closePopup();
                    }
                "
            >
                <div class="form-row">
                    <label class="hidden">
                        <FormulateInput
                            v-model="patientUserId"
                            :value="id"
                            class="input-text"
                            name="patientUserId"
                            type="hidden"
                        ></FormulateInput>
                    </label>
                    <label class="row-span-1 hidden">
                        <custom-label>Oddział:</custom-label>
                        <custom-select
                            :default-value="departmentID"
                            :options="mappedDepartments"
                            class="w-full"
                            hidden
                            name="departmentId"
                            placeholder="Przypisz oddział"
                        ></custom-select>
                    </label>
                    <label class="row-span-1">
                        <custom-label>Wybierz rodzaj wizyty:</custom-label>
                        <custom-select
                            :default-value="
                                isEdit && slotProps.formData
                                    ? slotProps.formData.visitTypeId
                                    : null
                            "
                            :options="mappedVisitsTypes"
                            :validation-messages="{
                                isType: requireMessage('typ wizyty'),
                            }"
                            :validation-rules="{
                                isType: validateVisitType,
                            }"
                            class="w-full"
                            name="visitTypeId"
                            placeholder="Przypisz wizytę"
                            validation="isType"
                            validation-name="Pole wizyty"
                            @update-form="updateVisitType"
                        ></custom-select>
                    </label>

                    <label class="row-span-1">
                        <custom-label
                        >Wybierz datę i godzinę wizyty:
                        </custom-label>
                        <div class="relative w-full">
                            <FormulateInput
                                ref="formulateInput"
                                :default-value="
                                    isEdit
                                        ? slotProps.formData.startTime
                                        : fullDate
                                "
                                :validation-messages="{
                                    isValidDate: isValidDateMessage,
                                }"
                                :validation-rules="{
                                    isValidDate: validateDateTime,
                                }"
                                class="absolute right-0 w-full"
                                name="startTime"
                                type="hidden"
                                validation="isValidDate"
                            ></FormulateInput>
                        </div>
                        <custom-datepicker
                            :change-on-init="false"
                            :date-input="isEdit ? mutatedDate : dateInputValue"
                            :default-value="slotProps.formData"
                            :form="$refs.formulateForm"
                            :mode="mode"
                            :show-date-picker="true"
                            :show-time-picker="true"
                            :time-input="isEdit ? mutatedTime : timeInputValue"
                            name="startTime"
                            @update-date="updateDate"
                            @update-time="updateTime"
                        >
                            >
                        </custom-datepicker>
                    </label>
                    <label class="row-span-1">
                        <custom-label>Wybierz lekarza:</custom-label>
                        <custom-select
                            :default-value="
                                isEdit
                                    ? slotProps.formData.doctorUserId
                                    : doctorId
                            "
                            :options="mappedDoctors"
                            :validation-messages="{
                                isDoctor: requireMessage('lekarz'),
                            }"
                            :validation-rules="{
                                isDoctor: validateDoctor,
                            }"
                            class="w-full"
                            name="doctorUserId"
                            placeholder="Przypisz doktora"
                            validation="isDoctor"
                            validation-name="Pole lekarza"
                            @update-form="updateDoctor"
                        ></custom-select>
                    </label>
                    <label class="row-span-5">
                        <custom-label
                        >Dodatkowe informacje (uwagi):
                        </custom-label>
                        <FormulateInput
                            class="input-textarea"
                            name="note"
                            placeholder="Dodatkowe informacje"
                            type="textarea"
                        />
                    </label>

                    <label class="row-span-1 col-span-1">
                        <custom-label>Status:</custom-label>
                        <custom-select
                            :default-value="
                                isEdit
                                    ? slotProps.formData.visitStatusId
                                    : statusID
                            "
                            :options="mappedStatuses"
                            :validation-messages="{
                                isStatus: requireMessage('status'),
                            }"
                            :validation-rules="{
                                isStatus: validateStatus,
                            }"
                            class="w-full"
                            name="visitStatusId"
                            placeholder="Wybierz"
                            validation="isStatus"
                            validation-name="Pole Status"
                        ></custom-select>
                    </label>
                    <label class="row-span-1">
                        <custom-label>Zródło wizyty:</custom-label>
                        <custom-select
                            v-model="source"
                            :default-value="
                                isEdit ? slotProps.formData.source : source
                            "
                            :options="visitSources"
                            :validation-messages="{
                                isSource: requireMessage('źródło'),
                            }"
                            :validation-rules="{
                                isSource: validateSource,
                            }"
                            class="w-full"
                            name="source"
                            placeholder="Wybierz"
                            validation="isSource"
                            validation-name="Pole źródło"
                        ></custom-select>
                    </label>
                </div>
                <ul class="mt-1 sm:mt-4 hidden">
                    <li
                        class="flex justify-between border-b border-b-blue-500 border-opacity-10 text-xs sm:text-base py-2 sm:py-4"
                    >
                        <span>dr Joanna Nowak</span>
                        <span class="text-green-500">Dostępny</span>
                    </li>
                    <li
                        class="flex justify-between border-b border-b-blue-500 border-opacity-10 text-xs sm:text-base py-2 sm:py-4"
                    >
                        <span>dr Mateusz Urbański</span>
                        <span class="text-green-500">Dostępny</span>
                    </li>
                    <li
                        class="flex justify-between border-b border-b-blue-500 border-opacity-10 text-xs sm:text-base py-2 sm:py-4"
                    >
                        <span class="opacity-50">dr Anna Wilk</span>
                        <span class="text-red-500">Dostępny o 18:45</span>
                    </li>
                    <li
                        class="flex justify-between border-b border-b-blue-500 border-opacity-10 text-xs sm:text-base py-2 sm:py-4"
                    >
                        <span class="opacity-50">dr Olga Musiałowska</span>
                        <span class="text-red-500">Przerwa urlopowa</span>
                    </li>
                </ul>
                <div
                    v-if="mode == 'edit'"
                    class="flex justify-between gap-8 mt-6 sm:mt-8 lg:mt-12"
                >
                    <custom-button
                        type="empty"
                        @click="slotProps.closePopup"
                    >
                        ANULUJ
                    </custom-button>

                    <FormulateInput
                        :disabled="isLoading"
                        :label="isLoading ? '...' : 'ZAPISZ'"
                        class="input-submit input-submit--filled"
                        type="submit"
                    />
                </div>
                <div
                    v-else-if="mode == 'create'"
                    class="flex justify-center mt-6 sm:mt-8 lg:mt-12"
                >
                    <FormulateInput
                        :disabled="isLoading"
                        :label="isLoading ? '...' : 'POTWIERDŹ WIZYTĘ'"
                        class="input-submit input-submit--filled input-submit--wider"
                        type="submit"
                    />
                </div>
            </FormulateForm>
        </template>
    </edit-popup>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import dayjs from "dayjs";
import {visitSources} from "@/lib/forms/visit";
import isBetween from "dayjs/plugin/isBetween";
import EditPopup from "@/components/v1/EditPopup";
import CustomDatepicker from "@/elements/v1/form/CustomDatepicker";
import CustomButton from "@/elements/v1/CustomButton";
import CustomSelect from "@/elements/v1/form/CustomSelect";
import CustomLabel from "@/elements/v1/form/CustomLabel";
import {handleErrors, handleSuccess} from "@/lib/notifications";
import date from "@/mixins/date";
import duration from "dayjs/plugin/duration";
import { translate } from "@/lib/utils";


dayjs.extend(duration);
dayjs.extend(isBetween);

export default {
    mixins: [
        {
            name: "DetailsForm",
            components: {
                EditPopup,
                CustomLabel,
                CustomButton,
                CustomSelect,
                CustomDatepicker,
            },
            data: () => ({
                isLoading: false,
                patientUserId: null,
                doctorId: null,
                visitTypeId: null,
                dateSeconds: String,
                patientValidDate: null,
                doctorValidDate: null,
                departmentID: null,
                date: null,
                fullDate: null,
                statusID: null,
                source: null,
                dateInputValue: null,
                timeInputValue: null,
                lateThanNow: false,
                visitSources,
            }),
            async created() {
                await this.fetchVisitsTypes();
                await this.fetchDepartments();
                await this.fetchVisits();
                this.visitTypeId = this.fields?.visitTypeId;
                this.doctorId = this.fields?.doctorUserId;
                this.patientUserId = this.fields?.patientUserId;
                this.checkTime();
            },
            mixins: [date],
            props: {
                fields: {
                    type: Object,
                    require: false,
                },
                mode: {
                    type: String,
                    require: false,
                    default: "edit",
                    validator(value) {
                        return ["edit", "create"].includes(value);
                    },
                },
                forceActive: {
                    type: Boolean,
                    require: false,
                    default: false,
                },
                id: {
                    type: Number,
                    require: true,
                },
                pencil: {
                    type: [Boolean, String],
                    default: "pencilSquare",
                },
                dateInput: [String, Date],
                timeInput: Object,
                doctorUserId: null,
                visitStatusId: null,
                sourceName: null,
            },
            computed: {
                ...mapGetters("visitsTypes", ["allVisitsTypes"]),
                ...mapGetters("doctors", ["allDoctors"]),
                ...mapGetters("statuses", ["allStatuses"]),
                ...mapGetters("departments", ["allDepartments"]),
                ...mapGetters("visits", [
                    "allVisits",
                    "visitsByPatientFiltered",
                    "visitsByDoctor",
                ]),
                isEdit() {
                    return this.mode === "edit";
                },
                mutatedDate() {
                    return this.dateInput;
                },
                mutatedTime() {
                    return this.timeInput;
                },
                mappedVisitsTypes() {
                    return this.allVisitsTypes
                        ? this.allVisitsTypes.map((visitType) => {
                            const baseFormat = visitType.price
                                ? visitType.price
                                : "";
                            const price = baseFormat.replace(/\.00$/, "");
                            return {
                                label: `${visitType.name}${
                                    !visitType.isCat
                                        ? ": " + price + "PLN"
                                        : ""
                                }${
                                    !visitType.isCat
                                        ? ", " +
                                        this.minutesToTime(visitType.time)
                                        : ""
                                }`,
                                value: visitType.id,
                            };
                        })
                        : [];
                },
                mappedVisitsTypesPrice() {
                    return this.allVisitsTypes
                        ? this.allVisitsTypes.map(({price, id}) => ({
                            price,
                            id,
                        }))
                        : [];
                },
                mappedDoctors() {
                    const visit = this.allVisitsTypes.find(
                        (visit) => visit.id === +this.visitTypeId
                    );
                    return this.allDoctors
                        ? this.allDoctors
                            .filter((doctor) => {
                                return visit
                                    ? visit.users.find(
                                        (user) => user.id === doctor.id
                                    )
                                    : false;
                            })
                            .map((doctor) => ({
                                label: doctor.name,
                                value: doctor.id,
                            }))
                        : [];
                },
                mappedStatuses() {
                    return this.allStatuses
                        ? this.allStatuses.map((status) => ({
                            label: status.name,
                            value: status.id,
                        }))
                        : [];
                },
                mappedDepartments() {
                    if (this.allDepartments) {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.departmentID = this.allDepartments[0]?.id;
                    }
                    return this.allDepartments
                        ? this.allDepartments.map((department) => ({
                            label: department.name,
                            value: department.id,
                        }))
                        : [];
                },
                advance() {
                    return this.price?.sum - this.price?.advance.amount;
                },
                deficit() {
                    return this.price?.sum - this.advance;
                },
                currency() {
                    return this.price?.currency;
                },
                paid() {
                    return this.price?.advance.paid;
                },
                lateThanNowSet() {
                    this.checkTime();
                },
            },
            methods: {
                ...mapActions("visitsTypes", ["fetchVisitsTypes"]),
                ...mapActions("departments", ["fetchDepartments"]),
                ...mapActions("visits", [
                    "createVisit",
                    "fetchVisits",
                    "fetchByPatient",
                    "editVisit",
                    "fetchByDoctor",
                ]),
                translate,
                async sendForm(data) {
                    const dataVar = data;
                    const isEditMode = this.mode === "edit";
                    const action = isEditMode
                        ? this.editVisit
                        : this.createVisit;
                    const selectedVisitPrice =
                        this.mappedVisitsTypesPrice.filter(
                            (x) => x.id === +this.visitTypeId
                        );
                    // eslint-disable-next-line no-param-reassign
                    dataVar.patientUserId = this.id;
                    // eslint-disable-next-line no-param-reassign
                    dataVar.price = selectedVisitPrice[0].price;
                    if (!this.date && this.mode === "edit") {
                        const dateVal = new Date(this.dateInput);
                        dataVar.startTime = dayjs(
                            dateVal.setHours(
                                this.timeInput.HH,
                                this.timeInput.mm
                            )
                        ).format("YYYY-MM-DD HH:mm");
                    }
                    try {
                        await action({
                            data: this.removeNullValues(data),
                            id: data.id || +data.visitId,
                        });
                        handleSuccess(
                            this.translate(
                                isEditMode ? "updated" : "created",
                                "store",
                                "visit"
                            )
                        );
                    } catch (e) {
                        handleErrors(e);
                    }
                    this.fetchByPatient(this.id);
                },
                getDuration(dur) {
                    return dayjs.duration(dur);
                },
                checkTime() {
                    if (this.timeInput) {
                        const now = dayjs().add(5, "minute");
                        const dateForEdit = new Date(this.dateInput).setHours(
                            +this.timeInput.HH,
                            +this.timeInput.mm
                        );
                        this.lateThanNow = dayjs(
                            !this.date && this.mode === "edit"
                                ? dateForEdit
                                : this.date
                        ).isAfter(now);
                    }
                },
                updateDate({key, value}) {
                    if (this.mode === "edit") {
                        const dateVal = new Date(value);
                        const updatedTime = this.timeInput
                            ? dateVal.setHours(
                                this.timeInput.HH,
                                this.timeInput.mm
                            )
                            : null;
                        this.date = this.formatDate(
                            updatedTime,
                            "YYYY-MM-DD HH:mm"
                        );
                        this.$refs.formulateForm.setFieldValue(key, this.date);
                    } else {
                        this.date = value;
                    }
                },
                updateTime({key, value}) {
                    const dateVal = new Date(this.date);
                    const arr = value.split(":");
                    const updatedTime = dateVal.setHours(+arr[0], +arr[1]);
                    this.dateSeconds = updatedTime;
                    this.date = this.formatDate(
                        updatedTime,
                        "YYYY-MM-DD HH:mm"
                    );
                    const now = dayjs().add(5, "minute");
                    this.lateThanNow = dayjs(this.date).isAfter(now);
                    this.patientValidDate = this.dateValidationPatient();
                    this.doctorValidDate = this.dateValidationDoctor();
                    this.$refs.formulateForm.setFieldValue(key, this.date);
                },
                dateValidationPatient() {
                    if (!this.visitTypeId) return false;
                    const currentVisitTimeStart = this.dateSeconds;
                    const currentVisitType = this.allVisitsTypes.filter(
                        (x) => x.id === +this.visitTypeId
                    );
                    const currentVisitDuration = currentVisitType[0].time;
                    const filterPatientAndDay =
                        this.visitsByPatientFiltered.filter((visit) => {
                            if (this.mode === "edit") {
                                return (
                                    this.formatDate(
                                        currentVisitTimeStart,
                                        "YYYY-MM-DD"
                                    ) ===
                                    this.formatDate(
                                        visit.startTime,
                                        "YYYY-MM-DD"
                                    ) && +visit.id !== +this.fields?.visitId
                                );
                            }
                            return (
                                this.formatDate(
                                    currentVisitTimeStart,
                                    "YYYY-MM-DD"
                                ) ===
                                this.formatDate(visit.startTime, "YYYY-MM-DD")
                            );
                        });
                    const validDates = [];
                    const data = window.version === 2 ? this.checkCancellation(filterPatientAndDay) : filterPatientAndDay;
                    data.filter((x) => {
                        const existVisitsId = x?.visitTypeId;
                        const filteredTypes = this.allVisitsTypes.filter(
                            (type) => type.id === existVisitsId
                        )[0];
                        const currVisitStart = this.formatDate(
                            currentVisitTimeStart,
                            "YYYY-MM-DD HH:mm:ss"
                        );
                        const existVisitEnd = this.formatDate(
                            // prettier-ignore
                            dayjs(x.startTime).add(
                                this.getDuration({
                                    'minute': filteredTypes.time,
                                })
                            ),
                            "YYYY-MM-DD HH:mm:ss"
                        );

                        const currVisitEnd = this.formatDate(
                            dayjs(currentVisitTimeStart).add(
                                // prettier-ignore
                                this.getDuration({
                                    'minute': currentVisitDuration,
                                }),
                                "YYYY-MM-DD HH:mm:ss"
                            )
                        );
                        const existVisitStart = this.formatDate(
                            x.startTime,
                            "YYYY-MM-DD HH:mm:ss"
                        );
                        const validDateBetween = dayjs(
                            currVisitStart
                        ).isBetween(existVisitStart, dayjs(existVisitEnd));
                        const validSame = currVisitEnd === existVisitStart;
                        const validSameStart =
                            currVisitStart === existVisitStart;
                        const validDateEndCurrent = dayjs(
                            currVisitEnd
                        ).isBetween(existVisitStart, dayjs(existVisitEnd));
                        validDates.push(
                            validDateBetween,
                            validDateEndCurrent,
                            validSame,
                            validSameStart
                        );
                        return true;
                    });
                    return validDates.filter((x) => x === true).length === 0;
                },
                dateValidationDoctor() {
                    if (!this.visitTypeId) return false;
                    const currentVisitTimeStart = this.dateSeconds;
                    const currentVisitType = this.allVisitsTypes.filter(
                        (x) => x.id === +this.visitTypeId
                    );
                    const currentVisitDuration = currentVisitType[0].time;
                    const filterDoctorAndDay = this.visitsByDoctor.filter(
                        (visit) => {
                            if (this.mode === "edit") {
                                return (
                                    this.formatDate(
                                        currentVisitTimeStart,
                                        "YYYY-MM-DD"
                                    ) ===
                                    this.formatDate(
                                        visit.startTime,
                                        "YYYY-MM-DD"
                                    ) && +visit.id !== +this.fields?.visitId
                                );
                            }
                            return (
                                this.formatDate(
                                    currentVisitTimeStart,
                                    "YYYY-MM-DD"
                                ) ===
                                this.formatDate(visit.startTime, "YYYY-MM-DD")
                            );
                        }
                    );
                    const validDates = [];
                    // eslint-disable-next-line array-callback-return
                    const data = window.version === 2 ? this.checkCancellation(filterDoctorAndDay) : filterDoctorAndDay;
                    data.filter((x) => {
                        const existVisitsId = x?.visitTypeId;
                        const filteredTypes = this.allVisitsTypes.filter(
                            (type) => type.id === existVisitsId
                        )[0];

                        const currVisitStart = this.formatDate(
                            currentVisitTimeStart,
                            "YYYY-MM-DD HH:mm:ss"
                        );
                        const existVisitEnd = this.formatDate(
                            // prettier-ignore
                            dayjs(x.startTime).add(
                                this.getDuration({
                                    'minute': filteredTypes.time,
                                })
                            ),
                            "YYYY-MM-DD HH:mm:ss"
                        );

                        const currVisitEnd = this.formatDate(
                            dayjs(currentVisitTimeStart).add(
                                // prettier-ignore
                                this.getDuration({
                                    'minute': currentVisitDuration,
                                }),
                                "YYYY-MM-DD HH:mm:ss"
                            ),
                            "YYYY-MM-DD HH:mm:ss"
                        );
                        const existVisitStart = this.formatDate(
                            x.startTime,
                            "YYYY-MM-DD HH:mm:ss"
                        );

                        const validDateBetween = dayjs(
                            currVisitStart
                        ).isBetween(existVisitStart, dayjs(existVisitEnd));

                        const validDateEndCurrent = dayjs(
                            currVisitEnd
                        ).isBetween(existVisitStart, dayjs(existVisitEnd));
                        const validSame = currVisitEnd === existVisitStart;
                        const validSameStart =
                            currVisitStart === existVisitStart;
                        validDates.push(
                            validDateBetween,
                            validDateEndCurrent,
                            validSame,
                            validSameStart
                        );
                        return true;
                    });
                    return validDates.filter((x) => x === true).length === 0;
                },

                requireMessage(val) {
                    return `Pole ${val} jest wymagane`;
                },
                validateStatus(e) {
                    const formData = e.getFormValues();
                    return formData.visitStatusId;
                },
                validateDoctor(e) {
                    const formData = e.getFormValues();
                    return formData.doctorUserId;
                },
                validateDateTime(e) {
                    const formData = e.getFormValues();
                    return (
                        formData.patientValidDate && formData.doctorValidDate
                    );
                },
                messageDate(message) {
                    return message;
                },
                validateVisitType(e) {
                    const formData = e.getFormValues();
                    return formData?.visitTypeId;
                },
                validateSource(e) {
                    const formData = e.getFormValues();
                    return formData.source;
                },
                removeNullValues(obj) {
                    return Object.fromEntries(
                        Object.entries(obj).filter(
                            (value) => value[1] !== null && value[1] !== ""
                        )
                    );
                },
                updateDoctor({value}) {
                    this.doctorId = value;
                    this.fetchByDoctor(value)
                },
                updateVisitType({value}) {
                    this.visitTypeId = value;
                },
                isValidDateMessage(e) {
                    const startDate = e.getFormValues().startTime;
                    const dateType = typeof this.date === "string";
                    switch (true) {
                        case this.visitTypeId &&
                        dateType &&
                        !this.patientValidDate:
                            return "Pacjent ma umówioną wizytę w tym terminie. Wybierz inny termin wizyty.";
                        case this.doctorId && dateType && !this.doctorValidDate:
                            return "Lekarz nie jest dostępny w tym terminie. Wybierz inny termin wizyty.";
                        case !this.visitTypeId:
                            return "Wybierz rodzaj wizyty i ponownie wybierz datę i godzinę wizyty.";
                        case !this.doctorId:
                            return "Wybierz lekarza i ponownie wybierz datę i godzinę wizyty.";
                        case !this.lateThanNow:
                            return "Data musi być późniejsza niż obecna o co najmniej 5 minut";
                        case dateType &&
                        this.doctorValidDate &&
                        this.patientValidDate &&
                        this.visitTypeId &&
                        this.doctorId &&
                        this.lateThanNow:
                            return "";
                        case !startDate:
                            return "Uzupełnij date i godzinę wizyty";
                        default:
                            return "";
                    }
                },

                additionalData(item) {
                    const baseFormat = item.price ? item.price : "";
                    const price = baseFormat.replace(/\.00$/, "");
                    return {
                        label: `${item.name}${
                            !item.isCat ? ": " + price + "PLN" : ""
                        }${
                            !item.isCat
                                ? ", " + this.minutesToTime(item.time)
                                : ""
                        }`,
                        value: item.id,
                    };
                },
                groupVisitTypes(data) {
                    return data.reduce(
                        (result, item) => {
                            if (item.isCat) {
                                result[item.name] = result[item.name] || [];
                            } else {
                                if (item.parentId === 0) {
                                    result.uncategorized.push(
                                        this.additionalData(item)
                                    );
                                } else {
                                    const parent = data.find(
                                        (cat) => cat.id === item.parentId
                                    );

                                    if (!result[parent.name]) {
                                        result[parent.name] = [];
                                    }
                                    result[parent.name].push(
                                        this.additionalData(item)
                                    );
                                }
                            }
                            return result;
                        },
                        {
                            uncategorized: [],
                        }
                    );
                },
            },
            watch: {
                startTimeString(oldValue, newValue) {
                    if (oldValue !== newValue) {
                        this.fullTime = newValue;
                    }
                },
                doctorUserId(oldValue, newValue) {
                    if (oldValue !== newValue) {
                        this.doctorId = newValue;
                    }
                },
                visitStatusId(oldValue, newValue) {
                    if (oldValue !== newValue) {
                        this.statusID = newValue;
                    }
                },
                sourceName(oldValue, newValue) {
                    if (oldValue !== newValue) {
                        this.source = newValue;
                    }
                },
                dateInput(oldValue, newValue) {
                    if (oldValue !== newValue) {
                        this.dateInputValue = newValue;
                    }
                },
                timeInput(oldValue, newValue) {
                    this.timeInputValue = newValue;
                },
            },
        },
    ],
};
// [
//     {
//         id: 5,
//         name: "Sub wizyta2",
//         slug: "sub-wizyta2",
//         parentId: 3,
//         price: "300.00",
//         time: 30,
//         createdAt: "2023-09-28T13:44:32.000000Z",
//         updatedAt: "2023-09-28T13:44:32.000000Z",
//         tenantId: 1,
//         isCat: 0,
//     },
//     {
//         id: 4,
//         name: "Sub wizyta",
//         slug: "sub-wizyta",
//         parentId: 3,
//         price: "120.00",
//         time: 20,
//         createdAt: "2023-09-28T13:44:02.000000Z",
//         updatedAt: "2023-09-28T13:44:02.000000Z",
//         tenantId: 1,
//         isCat: 0,
//     },
//     {
//         id: 3,
//         name: "Kategoria jakas",
//         slug: "kategoria-jakas",
//         parentId: 0,
//         price: null,
//         time: null,
//         createdAt: "2023-09-28T13:43:47.000000Z",
//         updatedAt: "2023-09-28T13:43:47.000000Z",
//         tenantId: 1,
//         isCat: 1,
//     },
//     {
//         id: 2,
//         name: "Jakaś tam wizyta",
//         slug: "jakas-tam-wizyta",
//         parentId: 0,
//         price: "200.00",
//         time: 30,
//         createdAt: "2023-09-28T13:43:34.000000Z",
//         updatedAt: "2023-09-28T13:43:34.000000Z",
//         tenantId: 1,
//         isCat: 0,
//     },
//     {
//         id: 1,
//         name: "jakis",
//         slug: "jakis",
//         parentId: 0,
//         price: "0.00",
//         time: null,
//         createdAt: null,
//         updatedAt: null,
//         tenantId: 1,
//         isCat: 0,
//     },
// ];
</script>
