<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14.408"
        height="14.434"
        viewBox="0 0 14.408 14.434"
    >
        <defs>
            <clip-path id="a">
                <path
                    data-name="Rectangle 76"
                    fill="currentColor"
                    d="M0 0h14.408v14.434H0z"
                />
            </clip-path>
        </defs>
        <g data-name="Group 8119" clip-path="url(#a)" fill="currentColor">
            <path
                data-name="Path 6027"
                d="M14.408 8.844C14.389 8.07 14.4 7.3 14.4 6.521c0-1.607.008-3.213 0-4.82A1.559 1.559 0 0 0 12.837.007Q7.2-.008 1.562.008A1.492 1.492 0 0 0 0 1.6q0 5.613.005 11.228a1.517 1.517 0 0 0 1.629 1.6h7.532a.96.96 0 0 0 .04-.152c0-1.262 0-2.524.011-3.787.008-.84.385-1.209 1.214-1.211 1.176 0 2.353-.01 3.529 0 .335 0 .456-.1.448-.444"
            />
            <path
                data-name="Path 6028"
                d="M10.563 10.409c-.079 0-.221.175-.222.269-.015 1.175-.01 2.35-.01 3.528l3.834-3.81c-1.174 0-2.388-.005-3.6.012"
            />
        </g>
    </svg>
</template>
