import * as api from "@/api";
import {smsFilter} from "@/lib/constants";

const SmsActions = {
    FETCH_TEMPLATES: "fetchTemplates",
    GET_SMSES: "getSmses",
    SEND_SMS: "sendSms",
    SET_SMS_STATUS: "setSmsStatus",
    SET_CURRENT_CHAT: "setCurrentChat",
    SET_FILTER: "setFilter",
    APPLY_FILTER: "applyFilter",
    SET_PAGE: "setPage",
    SET_LAST_PAGE: "setLastPage",
    GET_UNREAD_COUNT: "getUnreadCount",
    SET_SMS_USER: "setSmsUser",
    SET_SMS_POPUP_STATUS: "setSmsPopupStatus"
};

const SmsMutations = {
    SET_TEMPLATES: "setTemplates",
    SET_SMSES: "setSmses",
    SET_CURRENT_CHAT: "setCurrentChat",
    MUTATE_SMS_STATUS: "mutateSmsStatus",
    SET_FILTERS: "setFilters",
    RESET_CHATS: "resetChats",
    SET_PAGE: "setPage",
    SET_LAST_PAGE: "setLastPage",
    SET_UNREAD_COUNT: "setUnreadCount",
    SET_SMS_USER: "setSmsUser",
    SET_SMS_POPUP_STATUS: "setSmsPopupStatus"
};

const actions = {
    [SmsActions.FETCH_TEMPLATES]: async ({commit}) => {
        const res = await api
            .getSmsTemplates()
            .catch((e) => Promise.reject(e.response));

        commit(SmsMutations.SET_TEMPLATES, res.data.data.items);
    },
    [SmsActions.GET_SMSES]: async ({commit, state}) => {
        const {page, filters} = state;
        const res = await api.getSmses({grouped: true, page, filters});
        const {data, nextPageUrl} = res.data?.data;
        commit(SmsMutations.SET_SMSES, {
            data,
            page,
        });
        commit(SmsMutations.SET_LAST_PAGE, !nextPageUrl ? page : null);
    },
    [SmsActions.GET_UNREAD_COUNT]: async ({commit}) => {
        const res = await api.getUnreadCount();
        const {value} = res.data?.data;
        commit(SmsMutations.SET_UNREAD_COUNT, value);
    },
    [SmsActions.SEND_SMS]: async ({state, dispatch}, payload) => {
        await api.sendSms(payload);
        dispatch(SmsActions.APPLY_FILTER, state.filters);
    },
    [SmsActions.SET_CURRENT_CHAT]: async (
        {commit, state},
        {patientUserId, phone, name}
    ) => {
        try {
            const res = await api.getSmses({
                grouped: false,
                patientUserId,
                phone,
                page: 1,
                perPage: 999,
            });

            const {data} = res.data?.data;

            const chatObj = {
                phone,
                name,
                data,
                patientUserId,
            };
            state.chatsFull.set(phone, chatObj);
            commit(SmsMutations.SET_CURRENT_CHAT, chatObj);
        } catch (e) {
            commit(SmsMutations.SET_CURRENT_CHAT, null);
            throw e;
        }
    },
    [SmsActions.SET_SMS_STATUS]: async ({commit, dispatch}, payload) => {
        const action = Array.isArray(payload.id)
            ? api.setSmsesStatus
            : api.setSmsStatus;

        await action({
            id: payload.id,
            isRead: payload.isRead ?? true,
        });
        commit(SmsMutations.MUTATE_SMS_STATUS, payload);
        dispatch(SmsActions.GET_UNREAD_COUNT);
    },
    [SmsActions.SET_FILTER]: ({state, dispatch}, payload) => {
        const filters = [...state.filters];
        const index = filters.findIndex(
            (filter) => filter.type === payload.type
        );
        const indexS = filters.findIndex(
            (filter) => filter.value === 'in'
        );
        if (payload.value === null || indexS !== -1) {
            filters.splice(index, 1);
        } else {
            filters.splice(index, index === -1 ? 0 : 1, payload);
        }
        dispatch(SmsActions.APPLY_FILTER, filters);
    },
    [SmsActions.APPLY_FILTER]: ({commit, dispatch}, payload) => {
        commit(SmsMutations.RESET_CHATS);
        commit(SmsMutations.SET_FILTERS, payload);
        commit(SmsMutations.SET_LAST_PAGE, 1);
        commit(SmsMutations.SET_PAGE, 1);
        dispatch(SmsActions.GET_SMSES);
    },
    [SmsActions.SET_PAGE]: async ({commit}, payload) => {
        commit(SmsMutations.SET_PAGE, payload);
    },
    [SmsActions.SET_SMS_USER]: async ({commit}, payload) => {
        commit(SmsMutations.SET_SMS_USER, payload);
    },
    [SmsActions.SET_SMS_POPUP_STATUS]: async ({commit}, payload) => {
        commit(SmsMutations.SET_SMS_POPUP_STATUS, payload);
    },

};
const mutations = {
    [SmsMutations.SET_TEMPLATES](state, payload) {
        state.templates = payload;
    },
    [SmsMutations.SET_SMSES](state, {page, data}) {
        state.chats.set(page, data);
    },
    [SmsMutations.SET_CURRENT_CHAT](state, payload) {
        state.currentChat = payload;
    },
    [SmsMutations.MUTATE_SMS_STATUS](
        state,
        {page, isRead = true, phone, id}
    ) {
        const mutateMultiple = Array.isArray(id);

        const ids = mutateMultiple ? id : [id];

        ids.forEach((smsId) => {
            const list = state.chats.get(page);
            const targetChat = list?.find((chat) => chat.id === smsId);
            if (targetChat) {
                targetChat.isRead = isRead;
                targetChat.unreadInGroup = 0;
            }

            const chats = state.chatsFull.get(phone);
            if (chats && chats.data.length && isRead) {
                const smsList = chats.data;
                const targetSms = smsList?.find((chat) => chat.id === smsId);
                if (targetSms) targetSms.isRead = isRead;
            }
        });
    },
    [SmsMutations.RESET_CHATS](state) {
        state.chats = new Map();
    },
    [SmsMutations.SET_FILTERS]: (state, payload) => {
        state.filters = payload;
    },
    [SmsMutations.SET_PAGE]: (state, payload) => {
        state.page = payload;
    },
    [SmsMutations.SET_LAST_PAGE]: (state, payload) => {
        state.lastPage = payload;
    },
    [SmsMutations.SET_UNREAD_COUNT]: (state, payload) => {
        state.unreadCount = payload;
    },
    [SmsMutations.SET_SMS_USER]: (state, payload) => {
        state.currentSmsUser = payload;
    },
    [SmsMutations.SET_SMS_POPUP_STATUS]: (state, payload) => {
        state.smsPopupStatus = payload;
    },

};

const getters = {
    allTemplates: (state) => state.templates,
    filters: (state) => state.filters,
    chats: (state) => state.chats,
    chatsFull: (state) => state.chatsFull,
    currentChat: (state) => state.currentChat,
    page: (state) => state.page,
    lastPage: (state) => state.lastPage,
    unreadCount: (state) => state.unreadCount,
    currentUserForSms: (state) => state.currentSmsUser,
    smsPopupStatus: (state) => state.smsPopupStatus
};

const state = {
    templates: null,
    chats: new Map(),
    chatsFull: new Map(),
    currentChat: null,
    page: 1,
    lastPage: 1,
    unreadCount: 0,
    filters: [{type: "is_read", value: smsFilter.ALL}],
    currentChatId: null,
    currentSmsUser: null,
    smsPopupStatus: false
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
