<template>
    <router-link :to="item.path">
        <span
            class="absolute right-0 -top-0.5 h-5 w-[3px] rounded-sm bg-green-400"
            v-show="$route.path == item.path"
        ></span>

        <div
            :class="{
                'flex gap-3 items-center': true,
                'text-green-400 fill-green-400': isActive,
                'text-gray-500': !isActive,
            }"
        >
            <div v-tooltip.right="item.name">
                <custom-icon
                    class="mx-3 px-3 box-content"
                    :icon="item.icon"
                />
            </div>
            <span
                class="text-xs sm:text-base absolute left-16 whitespace-nowrap"
                >{{ item.name }}</span
            >
        </div>
    </router-link>
</template>
<script>
import CustomIcon from "@/elements/v2/CustomIcon";

export default {
    name: "NavigationItem",
    components: {
        CustomIcon,
    },
    data: () => ({
        submenu: HTMLElement,
        open: false,
    }),
    props: {
        item: {
            type: Object,
            require: true,
        },
        folded: Boolean,
    },
    computed: {
        isActive() {
            const routePath = this.$route.path;
            const itemPath = this.item.path;

            const routePathHasItemPath = routePath.indexOf(itemPath) + 1;
            const isHomepage = itemPath.length > 1 || routePath == "/";

            const active = routePathHasItemPath && isHomepage;
            this.submenu.classList?.toggle("submenu--hidden", !this.open);
            return active;
        },
    },
};
</script>
