import { route } from "../helpers";

export default [
    route("/login", "Login"),
    route("/lost-password", "LostPassword"),
    route("/resetuj-haslo/:email/:token", "ResetPassword", { props: true }),
].map((el) => ({
    ...el,
    meta: {
        ...el.meta,
        requiresAuth: false,
    },
}));
