<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13.441"
        height="14.011"
        viewBox="0 0 13.441 14.011"
    >
        <path
            d="M6.727 14.011H1.134c-.869 0-1.194-.357-1.126-1.216a4.744 4.744 0 0 1 4.606-4.312 77.51 77.51 0 0 1 4.231 0 4.76 4.76 0 0 1 4.578 4.243c.1.909-.236 1.284-1.151 1.284Zm5.621-1.077a7.393 7.393 0 0 0-.115-.741 3.663 3.663 0 0 0-3.341-2.658c-1.455-.043-2.919-.078-4.37.009a3.6 3.6 0 0 0-3.207 2.255 7.329 7.329 0 0 0-.3 1.132c3.833.003 7.539.003 11.333.003ZM3.145 3.629A3.492 3.492 0 0 1 6.745 0a3.622 3.622 0 0 1-.04 7.243 3.459 3.459 0 0 1-3.56-3.614ZM6.721 6.16a2.558 2.558 0 1 0-2.575-2.581A2.526 2.526 0 0 0 6.721 6.16Z"
            fill="currentColor"
        />
    </svg>
</template>
