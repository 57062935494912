<template>
    <svg
        fill="currentColor"
        width="9.527"
        height="5.597"
        viewBox="0 0 9.527 5.597"
    >
        <path
            data-name="Path 5886"
            d="m5.136 5.445 4.24-4.24a.522.522 0 0 0 0-.737L9.064.156a.522.522 0 0 0-.737 0l-3.56 3.56L1.202.152a.522.522 0 0 0-.737 0L.153.464a.522.522 0 0 0 0 .737l4.244 4.244a.526.526 0 0 0 .74 0Z"
        />
    </svg>
</template>
