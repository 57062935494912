import WebsocketConnector from "@/lib/websocket";

export default {
    getBroadcaster() {
        return WebsocketConnector.getInstance().echo;
    },
    getListener() {
        return this.getBroadcaster().private(this.DEFAULT_CHANNEL);
    },
    leave() {
        return this.getBroadcaster().leaveChannel(`private:${this.DEFAULT_CHANNEL}`);
    },
    CHANNELS: {
        PHONE_RINGING: 'TelephoneExchange\\PhoneRinging',
        CALL_INCOMING: 'TelephoneExchange\\CallIncoming',
        CALL_OUTGOING: 'TelephoneExchange\\CallOutgoing',
        CALL_ENDED: 'TelephoneExchange\\CallEnded',
        CALL_ANSWERED: 'TelephoneExchange\\CallAnswered',
        CALL_UPDATED: 'CallUpdated',
        CALL_CREATED: 'CallCreated',
        CALL_GROUP_UPDATED: 'CallGroupUpdated',
        USER_UPDATED: 'User\\UserUpdated',
        USER_CREATED: 'User\\UserCreated',
        USER_DESTROYED: 'User\\UserDestroyed',
        MANY_CALLS_UPDATED: 'CallsUpdated',
        MANY_CALLS_IDS_UPDATED: 'CallsIdsUpdated',
    },
    DEFAULT_CHANNEL: window?.tenant?.id ? `tenant.${window.tenant.id}` : 'default'
};
