<template>
    <component
        class="transform scale-75 origin-left sm:scale-100"
        v-if="icons[icon]"
        :is="icons[icon]"
        :style="`min-width: ${w}px; max-width: ${w}px; height: ${h}px`"
    />
</template>

<script>
import ChevronIcon from "@/elements/v1/icons/ChevronIcon";
import HomeIcon from "@/elements/v1/icons/HomeIcon";
import PencilIcon from "@/elements/v1/icons/PencilIcon";
import PencilSquareIcon from "@/elements/v1/icons/PencilSquareIcon";
import CalendarIcon from "@/elements/v1/icons/CalendarIcon";
import ClockIcon from "@/elements/v1/icons/ClockIcon";
import AttachmentIcon from "@/elements/v1/icons/AttachmentIcon";
import BellIcon from "@/elements/v1/icons/BellIcon";
import CheckmarkIcon from "@/elements/v1/icons/CheckmarkIcon";
import EnvelopeIcon from "@/elements/v1/icons/EnvelopeIcon";
import EnvelopeSolidIcon from "@/elements/v1/icons/EnvelopeSolidIcon";
import FileIcon from "@/elements/v1/icons/FileIcon";
import MenuIcon from "@/elements/v1/icons/MenuIcon";
import PersonIcon from "@/elements/v1/icons/PersonIcon";
import PhoneIcon from "@/elements/v1/icons/PhoneIcon";
import PhoneEmptyIcon from "@/elements/v1/icons/PhoneEmptyIcon";
import SearchIcon from "@/elements/v1/icons/SearchIcon";
import SettingsIcon from "@/elements/v1/icons/SettingsIcon";
import EyeIcon from "@/elements/v1/icons/EyeIcon";
import NotificationsIcon from "@/elements/v1/icons/NotificationsIcon";
import RecallIcon from "@/elements/v1/icons/RecallIcon";
import NoteIcon from "@/elements/v1/icons/NoteIcon";
import IncomingPhoneIcon from "@/elements/v1/icons/IncomingPhoneIcon";
import OutgoingPhoneIcon from "@/elements/v1/icons/OutgoingPhoneIcon";
import PauseIcon from "@/elements/v1/icons/PauseIcon";
import GridIcon from "@/elements/v1/icons/GridIcon";
import DotsIcon from "@/elements/v1/icons/DotsIcon";
import PlusIcon from "@/elements/v1/icons/PlusIcon";
import TrashIcon from "@/elements/v1/icons/TrashIcon";
import PhoneAnswer from "@/elements/v1/icons/PhoneAnswerIcon";
import PhoneReject from "@/elements/v1/icons/PhoneRejectIcon";
import FlagIcon from "@/elements/v1/icons/FlagIcon";

import PolandFlag from "@/elements/v1/flags/PolandFlag";
import EnglandFlag from "@/elements/v1/flags/EnglandFlag";

import LogoIcon from "@/elements/v1/icons/LogoIcon";

export default {
    name: "CustomIcon",
    props: {
        icon: {
            type: String,
            require: true,
        },
        w: {
            type: Number,
            require: false,
            default: 16,
        },
        h: {
            type: Number,
            require: false,
            default: 16,
        },
    },
    data: () => ({
        icons: {
            logo: LogoIcon,
            home: HomeIcon,
            chevron: ChevronIcon,
            pencil: PencilIcon,
            pencilSquare: PencilSquareIcon,
            calendar: CalendarIcon,
            clock: ClockIcon,
            attachment: AttachmentIcon,
            bell: BellIcon,
            checkmark: CheckmarkIcon,
            envelope: EnvelopeIcon,
            envelopeSolid: EnvelopeSolidIcon,
            file: FileIcon,
            menu: MenuIcon,
            person: PersonIcon,
            phone: PhoneIcon,
            phoneEmpty: PhoneEmptyIcon,
            search: SearchIcon,
            settings: SettingsIcon,
            eye: EyeIcon,
            notifications: NotificationsIcon,
            recall: RecallIcon,
            note: NoteIcon,
            incomingPhone: IncomingPhoneIcon,
            outgoingPhone: OutgoingPhoneIcon,
            pause: PauseIcon,
            grid: GridIcon,
            dots: DotsIcon,
            plus: PlusIcon,
            trash: TrashIcon,
            phoneAnswer: PhoneAnswer,
            phoneReject: PhoneReject,
            flagPoland: PolandFlag,
            flagEngland: EnglandFlag,
            flag: FlagIcon,
        },
    }),
};
</script>
