export const visitSources = [
    {
        label: "E-mail",
        value: "mail",
        id: 'mail'
    },
    { label: "Telefon", value: "phone", id: 'phone'},
    {
        label: "Rejestracja bezpośrednia",
        value: "directly",
        id: 'directly'
    },
    {
        label: "Strona internetowa",
        value: "www",
        id: 'www'
    },
];
