<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="98.274"
        height="78.104"
        viewBox="0 0 98.274 78.104"
    >
        <g fill="#c7a46d">
            <g data-name="Group 8100">
                <path
                    data-name="Path 5998"
                    d="M42.065 67.431a3.469 3.469 0 0 1 .115-1.075 1.013 1.013 0 0 1 .518-.507 1.937 1.937 0 0 1 .942-.286l.094-.008v-.61h-4.63v.618h.1a3.489 3.489 0 0 1 1.2.182 1.077 1.077 0 0 1 .624.464 2.629 2.629 0 0 1 .155 1.086v6.992l-7.04-9.3-.031-.04h-3.754v.618h.1c.791 0 .87.068.99.125a.8.8 0 0 1 .359.546 8.6 8.6 0 0 1 .127 1.854v6.468a6.768 6.768 0 0 1-.081 1.375.958.958 0 0 1-.5.493 2.085 2.085 0 0 1-1.059.306l-.095.007v.611h4.757v-.618h-.1a2.924 2.924 0 0 1-1.398-.27 1.082 1.082 0 0 1-.542-.554 5.728 5.728 0 0 1-.1-1.355v-8.161l8.474 11.231h.777Z"
                />
                <path
                    data-name="Path 5999"
                    d="M82.662 67.431a3.486 3.486 0 0 1 .115-1.075 1.015 1.015 0 0 1 .518-.507 1.938 1.938 0 0 1 .943-.286l.094-.008v-.61h-4.63v.618h.1a3.493 3.493 0 0 1 1.2.182 1.077 1.077 0 0 1 .623.464 2.627 2.627 0 0 1 .156 1.086v6.992l-7.04-9.3-.031-.04h-3.752v.618h.1c.791 0 .871.068.99.125a.8.8 0 0 1 .359.546 8.63 8.63 0 0 1 .127 1.854v6.468a6.72 6.72 0 0 1-.082 1.375.956.956 0 0 1-.5.493 2.085 2.085 0 0 1-1.058.306l-.1.007v.611h4.757v-.618h-.1a2.925 2.925 0 0 1-1.406-.265 1.082 1.082 0 0 1-.542-.554 5.738 5.738 0 0 1-.1-1.355v-8.161l8.474 11.231h.777Z"
                />
                <path
                    data-name="Path 6000"
                    d="M21.224 76.746c2.117 0 3.83-1.145 3.83-2.616v-7.006c0-.913-.221-1.131-.46-1.28a2.619 2.619 0 0 0-1.17-.28l-.1-.007v-.611h4.28v.611l-.1.007a2.115 2.115 0 0 0-1.154.343c-.247.191-.427.448-.427 1.437v7.2c0 1.681-2.263 3.027-5.063 3.027a6.61 6.61 0 0 1-2.858-.563 3.636 3.636 0 0 1-1.655-1.473 5.023 5.023 0 0 1-.494-2.4l.009-1.252v-3.8a12.337 12.337 0 0 0-.084-1.86.72.72 0 0 0-.311-.517 2.611 2.611 0 0 0-1.15-.147h-.381v-.618h5.773v.618h-.345a2.761 2.761 0 0 0-1.131.136.684.684 0 0 0-.322.487 11.463 11.463 0 0 0-.091 1.9v3.841a15.01 15.01 0 0 0 .181 2.812 2.389 2.389 0 0 0 1 1.4 3.684 3.684 0 0 0 2.215.606"
                />
                <path
                    data-name="Path 6001"
                    d="M46.336 65.562h.364a2.635 2.635 0 0 1 1.154.148.7.7 0 0 1 .311.516 13.161 13.161 0 0 1 .079 1.861v5.982a13.874 13.874 0 0 1-.079 1.928.8.8 0 0 1-.338.55 2.037 2.037 0 0 1-1.028.182h-.462v.618h5.782v-.618h-.462a1.957 1.957 0 0 1-1.06-.2.861.861 0 0 1-.319-.594 15.982 15.982 0 0 1-.066-1.87v-5.982a11.516 11.516 0 0 1 .091-1.9.683.683 0 0 1 .322-.487 2.772 2.772 0 0 1 1.131-.136h.363v-.618h-5.782Z"
                />
                <path
                    data-name="Path 6002"
                    d="M66.257 66.436a6.7 6.7 0 0 0-.948-.7l.728-1.4.078-.149h-1.494l-.507.976a7.862 7.862 0 0 0-2.687-.445 6.892 6.892 0 0 0-4.783 1.735 5.945 5.945 0 0 0-1.923 4.61 6.213 6.213 0 0 0 1.894 4.725 6.289 6.289 0 0 0 1.449 1.019l-.595 1.145-.078.149h1.494l.421-.81a8.012 8.012 0 0 0 2.146.28 6.8 6.8 0 0 0 4.888-1.782 6.19 6.19 0 0 0 1.863-4.662 5.971 5.971 0 0 0-1.946-4.691Zm-1.5 9.045a3.891 3.891 0 0 1-3.178 1.437 5.256 5.256 0 0 1-1.908-.326l5.076-9.772.026.029a6.915 6.915 0 0 1 1.156 4.3 6.817 6.817 0 0 1-1.172 4.332Zm-6.22.419a3.586 3.586 0 0 1-.4-.418 8.73 8.73 0 0 1 0-8.667 3.98 3.98 0 0 1 3.3-1.441 4.487 4.487 0 0 1 2.282.554Z"
                />
            </g>
            <g data-name="Group 8102">
                <path
                    data-name="Path 6003"
                    d="M28.122 48.563a3.49 3.49 0 0 1 .115-1.075 1.015 1.015 0 0 1 .518-.507 1.937 1.937 0 0 1 .942-.286l.094-.007v-.61h-4.63v.618h.1a3.477 3.477 0 0 1 1.2.183 1.074 1.074 0 0 1 .624.464 2.629 2.629 0 0 1 .156 1.086v6.992l-7.04-9.3-.031-.041h-3.753v.618h.1c.791 0 .871.068.99.125a.8.8 0 0 1 .359.547 8.633 8.633 0 0 1 .127 1.853v6.468a6.728 6.728 0 0 1-.081 1.375.957.957 0 0 1-.5.493 2.081 2.081 0 0 1-1.059.306l-.095.007v.611h4.757v-.618h-.1a2.92 2.92 0 0 1-1.406-.265 1.083 1.083 0 0 1-.542-.554 5.734 5.734 0 0 1-.1-1.355V47.53l8.474 11.231h.777Z"
                />
                <path
                    data-name="Path 6004"
                    d="M81.829 48.563a3.477 3.477 0 0 1 .115-1.075 1.013 1.013 0 0 1 .518-.507 1.937 1.937 0 0 1 .942-.286l.094-.007v-.61h-4.63v.618h.1a3.478 3.478 0 0 1 1.2.183 1.074 1.074 0 0 1 .624.464 2.63 2.63 0 0 1 .155 1.086v6.992l-7.04-9.3-.031-.041h-3.752v.618h.1c.791 0 .87.068.99.125a.8.8 0 0 1 .359.547 8.6 8.6 0 0 1 .127 1.853v6.468a6.761 6.761 0 0 1-.081 1.375.956.956 0 0 1-.5.493 2.081 2.081 0 0 1-1.059.306l-.095.007v.611h4.757v-.618h-.1a2.919 2.919 0 0 1-1.406-.265 1.083 1.083 0 0 1-.542-.554 5.726 5.726 0 0 1-.1-1.355V47.53l8.474 11.231h.777Z"
                />
                <path
                    data-name="Path 6005"
                    d="M7.284 57.877c2.117 0 3.83-1.145 3.83-2.616v-7.006c0-.914-.221-1.131-.46-1.281a2.624 2.624 0 0 0-1.175-.281l-.1-.006v-.612h4.28v.612l-.1.006a2.118 2.118 0 0 0-1.154.343c-.247.191-.427.448-.427 1.437v7.2c0 1.681-2.263 3.027-5.062 3.027a6.618 6.618 0 0 1-2.858-.563 3.634 3.634 0 0 1-1.655-1.473 5.021 5.021 0 0 1-.494-2.4l.01-1.252v-3.8a12.321 12.321 0 0 0-.084-1.86.722.722 0 0 0-.311-.517 2.618 2.618 0 0 0-1.15-.147h-.381v-.618h5.773v.618h-.345a2.765 2.765 0 0 0-1.13.136.684.684 0 0 0-.322.487 11.459 11.459 0 0 0-.091 1.9v3.841a15.016 15.016 0 0 0 .181 2.812 2.391 2.391 0 0 0 1 1.4 3.688 3.688 0 0 0 2.215.605"
                />
                <g data-name="Group 8101">
                    <path
                        data-name="Path 6006"
                        d="M33.421 44.383a1.009 1.009 0 0 0 .73-.3 1.031 1.031 0 1 0-1.758-.735 1.018 1.018 0 0 0 .3.723.975.975 0 0 0 .728.312Z"
                    />
                    <path
                        data-name="Path 6007"
                        d="M37.098 44.383a1.041 1.041 0 0 0 1.037-1.037 1 1 0 0 0-.3-.726 1.006 1.006 0 0 0-.735-.3 1.033 1.033 0 0 0-1.028 1.028 1.018 1.018 0 0 0 .3.723.975.975 0 0 0 .726.312Z"
                    />
                    <path
                        data-name="Path 6008"
                        d="M36.361 47.318a.684.684 0 0 1 .322-.487 2.773 2.773 0 0 1 1.131-.136h.363v-.618h-5.781v.618h.363a2.63 2.63 0 0 1 1.154.148.706.706 0 0 1 .311.515c.05.278.079 7.784.08 9.077a1.787 1.787 0 0 0 .631 1.6 2.392 2.392 0 0 0 1.457.517 3.018 3.018 0 0 0 1.919-.813l.393-.4-.508.232c-.318.145-1.215.448-1.524.1a1.109 1.109 0 0 1-.335-.608 16.232 16.232 0 0 1-.066-1.869v-5.982a11.438 11.438 0 0 1 .09-1.894Z"
                    />
                </g>
                <path
                    data-name="Path 6009"
                    d="M54.193 52.397a7.071 7.071 0 0 0-.754-3.317 5.492 5.492 0 0 0-2.3-2.287 9.094 9.094 0 0 0-4.286-.84c-.583 0-1.723.042-3.388.126l-2.1.1v.613h.462a2.668 2.668 0 0 1 1.15.144.716.716 0 0 1 .31.516 12.443 12.443 0 0 1 .084 1.864v5.881a13.077 13.077 0 0 1-.083 1.927.814.814 0 0 1-.343.551 2.037 2.037 0 0 1-1.028.181h-.606v.618h5.508a22.571 22.571 0 0 0 2.368-.095 6.746 6.746 0 0 0 1.795-.484 4.877 4.877 0 0 0 1.665-1.134 5.281 5.281 0 0 0 1.133-1.88 7.23 7.23 0 0 0 .413-2.484Zm-8.87 2.285v-8.054c.431-.026.863-.039 1.283-.039a8.186 8.186 0 0 1 2.3.27 3.8 3.8 0 0 1 1.611 1.008 4.907 4.907 0 0 1 1.123 1.935 8.734 8.734 0 0 1 .4 2.73 6.817 6.817 0 0 1-.676 3.222 3.521 3.521 0 0 1-1.664 1.672 6.655 6.655 0 0 1-2.578.429 3.04 3.04 0 0 1-1.371-.2.848.848 0 0 1-.358-.659c-.046-.343-.069-1.122-.069-2.317Z"
                />
                <path
                    data-name="Path 6010"
                    d="m67.362 54.588.008-.109h-.622l-.019.078a5.883 5.883 0 0 1-.87 2.082 2.75 2.75 0 0 1-1.138.982 4.245 4.245 0 0 1-1.545.24h-.909c-.968 0-1.306-.185-1.42-.34a5.8 5.8 0 0 1-.259-2.49v-2.786h1.238a4.074 4.074 0 0 1 1.08.1.721.721 0 0 1 .438.4 3.5 3.5 0 0 1 .16 1.317v.1h.628v-4.378h-.628v.1a4.158 4.158 0 0 1-.106 1.175.7.7 0 0 1-.413.4 3.443 3.443 0 0 1-1.206.145h-1.186V46.69h1.589a8.68 8.68 0 0 1 1.83.137 1.691 1.691 0 0 1 .937.638 4.678 4.678 0 0 1 .687 1.772l.018.079h.617l-.308-3.245h-8.912v.618h.1a2.289 2.289 0 0 1 1.1.159.77.77 0 0 1 .306.548 13.822 13.822 0 0 1 .075 1.818v5.982a14.23 14.23 0 0 1-.071 1.884.978.978 0 0 1-.306.56c-.108.1-.387.216-1.105.216h-.357v.618h10.3Z"
                />
                <path
                    data-name="Path 6011"
                    d="M98.21 46.177v-.1H86.174l-.068 3.137h.605l.014-.086a4.723 4.723 0 0 1 .53-1.689 1.332 1.332 0 0 1 .7-.635 7.083 7.083 0 0 1 1.556-.109h1.706v8.507a13.164 13.164 0 0 1-.083 1.927.816.816 0 0 1-.343.551 2.038 2.038 0 0 1-1.028.181h-.732v.618h6.286v-.618h-.7a1.957 1.957 0 0 1-1.055-.2.852.852 0 0 1-.324-.6 16.222 16.222 0 0 1-.066-1.866v-8.507h1.859a5.587 5.587 0 0 1 1.384.117 1.41 1.41 0 0 1 .718.615 4.614 4.614 0 0 1 .551 1.7l.015.085h.577Z"
                />
            </g>
            <g data-name="Group 8105">
                <g data-name="Group 8103">
                    <path
                        data-name="Path 6012"
                        d="M53.226 10.764a10.492 10.492 0 0 0 2.5-6.6A4.06 4.06 0 0 0 51.816 0a3.971 3.971 0 0 0-1.137.154 5.569 5.569 0 0 1-3.077 0A3.968 3.968 0 0 0 46.465 0a4.061 4.061 0 0 0-3.909 4.213 10.484 10.484 0 0 0 2.527 6.588 5.377 5.377 0 0 0 8.143-.037Z"
                        opacity=".99"
                    />
                    <path
                        data-name="Path 6013"
                        d="M45.049 22.462a10.487 10.487 0 0 0-2.5 6.6 4.06 4.06 0 0 0 3.91 4.165 3.956 3.956 0 0 0 1.137-.154 5.575 5.575 0 0 1 3.077 0 3.956 3.956 0 0 0 1.137.154 4.062 4.062 0 0 0 3.909-4.213 10.489 10.489 0 0 0-2.527-6.588 5.376 5.376 0 0 0-8.143.036Z"
                        opacity=".99"
                    />
                </g>
                <g data-name="Group 8104">
                    <path
                        data-name="Path 6014"
                        d="M54.986 20.702a10.488 10.488 0 0 0 6.6 2.5 4.06 4.06 0 0 0 4.165-3.91 3.957 3.957 0 0 0-.154-1.137 5.577 5.577 0 0 1 0-3.077 3.951 3.951 0 0 0 .154-1.137 4.062 4.062 0 0 0-4.213-3.909 10.491 10.491 0 0 0-6.588 2.527 5.377 5.377 0 0 0 .036 8.143Z"
                        opacity=".99"
                    />
                    <path
                        data-name="Path 6015"
                        d="M43.288 12.525a10.489 10.489 0 0 0-6.6-2.5 4.06 4.06 0 0 0-4.164 3.91 3.956 3.956 0 0 0 .154 1.137 5.569 5.569 0 0 1 0 3.077 3.951 3.951 0 0 0-.154 1.137 4.061 4.061 0 0 0 4.213 3.909 10.491 10.491 0 0 0 6.59-2.525 5.377 5.377 0 0 0-.039-8.145Z"
                        opacity=".99"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>
