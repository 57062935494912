<template>
    <nav
        class="bg-white px-12 lg:px-24 w-full flex items-center justify-between h-20"
    >
        <img v-if="this.logoPath" :src="logoPath" alt="" class="max-h-[70%]" />

        <router-link to="/login" class="text-blue-600 font-medium">
            Zaloguj się
        </router-link>
    </nav>
</template>

<script>
import CustomIcon from "@/elements/v2/CustomIcon";

export default {
    components: {
        CustomIcon,
    },
    data: () => ({
        logoPath: "",
    }),
    mounted() {
        this.logoPath = window.logo;
    },
};
</script>
