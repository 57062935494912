<template>
    <nav
        class="bg-white px-4 sm:px-10 rounded-xl w-full flex items-center justify-between h-12 sm:h-32"
    >
        <img
            v-if="logoPath"
            :src="logoPath"
            alt="logo"
            class="max-w-[100px] sm:max-w-[140px] py-2"
        />

        <div>
            <app-navbar-desktop @logout="logoutUser"></app-navbar-desktop>
            <!-- <app-navbar-mobile @logout="logoutUser" :items="items" /> -->
        </div>
    </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import AppNavbarMobile from "./AppNavbarMobile";
import AppNavbarDesktop from "./AppNavbarDesktop";

import CustomIcon from "@/elements/v1/CustomIcon";
import { getNavigation } from "@/mixins/navigation";
import { AuthActions } from "@/store/modules/auth";

export default {
    mixins: [
        {
            components: {
                // AppNavbarMobile,
                AppNavbarDesktop,
                CustomIcon,
            },
            data: () => ({
                logoPath: "",
            }),
            mounted() {
                this.logoPath = window.logo;
            },
            mixins: [
                {
                    name: "AppNavbar",
                    mixins: [getNavigation],
                    computed: {
                        ...mapGetters("auth", ["isAuth"]),
                    },
                    methods: {
                        ...mapActions("auth", { logout: AuthActions.LOGOUT }),
                        logoutUser() {
                            this.logout().then(() => {
                                location.href='/clinic/login';
                            });
                        },
                    },
                    watch: {
                        $route() {
                            this.menuActive = false;
                        },
                    },
                },
            ],
        },
    ],
};
</script>
