<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="5"
        viewBox="0 0 21 5"
    >
        <g
            data-name="Group 8143"
            transform="translate(-437 -675)"
            fill="currentColor"
        >
            <circle
                data-name="Ellipse 39"
                cx="2.5"
                cy="2.5"
                r="2.5"
                transform="translate(437 675)"
            />
            <circle
                data-name="Ellipse 40"
                cx="2.5"
                cy="2.5"
                r="2.5"
                transform="translate(445 675)"
            />
            <circle
                data-name="Ellipse 41"
                cx="2.5"
                cy="2.5"
                r="2.5"
                transform="translate(453 675)"
            />
        </g>
    </svg>
</template>
