import * as api from "@/api";

const VisitsActions = {
    FETCH_PATIENTS_STATS: "fetchPatientsStats",
};

const VisitsMutations = {
    SET_PATIENTS_STATS: "setPatientsStats",
    SET_NEW_PATIENTS_STATS: "setNewPatientsStats",
};

const actions = {
    [VisitsActions.FETCH_PATIENTS_STATS]: async ({commit}, timeSlot) => {
        const {data} = await api
            .getOverallPatients(timeSlot)
            .catch((e) => Promise.reject(e.response.data));

        const newPatients = await api
            .getOverallPatients(timeSlot, true)
            .catch((e) => Promise.reject(e.response.data));
        commit(VisitsMutations.SET_PATIENTS_STATS, {
            data: data.data.stats,
        });
        commit(VisitsMutations.SET_NEW_PATIENTS_STATS, {
            data: newPatients.data.data.stats,
        });
    },
};
const mutations = {
    [VisitsMutations.SET_PATIENTS_STATS](state, payload) {
        state.allPatientsStats = payload.data
    },
    [VisitsMutations.SET_NEW_PATIENTS_STATS](state, payload) {
        state.allNewPatientsStats = payload.data
    },
};

const getters = {
    allPatientsStats: (state) => state.allPatientsStats,
    allNewPatientsStats: (state) => state.allNewPatientsStats,
};

const state = {
    allPatientsStats: null,
    allNewPatientsStats: null,
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
