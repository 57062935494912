<template>
    <div
        v-show="active"
        ref="background"
        @click="close"
        class="fixed flex items-center justify-center top-0 left-0 w-full h-screen bg-blue-600 bg-opacity-50 z-40"
    >
        <div
            class="bg-white rounded-xl sm:rounded-3xl w-full max-w-4xl p-5 sm:p-7 lg:px-16 mx-2 sm:mx-4 max-h-[92vh] overflow-y-auto"
            :class="outerClass"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script>
import close from "@/mixins/close";

export default {
    name: "PopupWrapper",
    props: {
        active: {
            type: Boolean,
            require: true,
        },
        outerClass: {
            type: String,
            require: false,
        },
    },
    mixins: [close],
    methods: {
        close(e = false) {
            if (!e || e.target == this.$refs.background) this.$emit("close");
        },
    },
};
</script>
