<template>
    <div class="mt-4 space-y-2.5 max-h-[60vh] overflow-y-auto">
        <div
            v-for="(message, key) in messages"
            :key="key"
            @click="markAsRead(message.id)"
        >
            <router-link
                to="/messages/sms"
                class="cursor-pointer p-1 pr-3 rounded-xl group flex items-center w-full justify-between gap-2"
                :class="
                    !message.isRead
                        ? 'bg-gray-100 border border-transparent'
                        : 'border border-gray-200'
                "
            >
                <div class="flex items-center gap-2">
                    <div
                        class="min-w-[36px] h-9 rounded-lg flex justify-center items-center"
                        :class="
                            !message.isRead
                                ? 'bg-blue-600 text-white'
                                : 'bg-gray-300 text-blue-600 bg-opacity-50'
                        "
                    >
                        <custom-icon icon="envelope"></custom-icon>
                    </div>
                    <div>
                        <h5 class="text-xs truncate">
                            Otrzymano nową wiadomość od
                        </h5>
                        <strong class="font-bold">{{
                            message.patient
                                ? message.patient.name
                                : message.phone
                        }}</strong>
                    </div>
                </div>

                <div class="flex items-center gap-3">
                    <time class="font-light text-sm text-gray-500">
                        {{ formatDate(message.updatedAt) }}
                    </time>

                    <div
                        class="w-1.5 h-1.5 bg-red-500 rounded-full"
                        :class="!message.isRead ? '' : 'opacity-0'"
                    ></div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import * as api from "@/api/lib/sms";
import { mapActions } from "vuex";

import date, { formatDate } from "@/mixins/date";
import CustomIcon from "@/elements/v2/CustomIcon";
import BroadcastingService from "../../../services/BroadcastingService";

export default {
    mixins: [
        {
            name: "NotificationsSms",
            components: {
                CustomIcon,
            },
            mixins: [date],
            data: () => ({
                items: [],
            }),
            mounted() {
                this.fetchItems();
                this.watchNewSmses();
            },
            computed: {
                messages() {
                    return this.items.splice(0, 5);
                },
            },
            beforeUnmount() {
                BroadcastingService.getBroadcaster()
                    ?.private(`smsChannel.${window.tenant?.id}`)
                    .stopListening("SmsIncoming");
                    BroadcastingService.getBroadcaster().leaveChannel(`private:smsChannel.${window.tenant?.id}`);
            },
            methods: {
                ...mapActions("sms", ["setSmsStatus"]),
                watchNewSmses() {
                    BroadcastingService.getBroadcaster()
                        ?.private(`smsChannel.${window.tenant?.id}`)
                        .listen("SmsIncoming", () => {
                            this.fetchItems();
                        });
                },
                async markAsRead(id) {
                    await this.setSmsStatus({ id, isRead: true });
                    this.fetchItems();
                },
                async fetchItems() {
                    const res = await api
                        .getSmses({})
                        .catch((e) => Promise.reject(e.response.data));

                    if (res.status === 200 && res.data.data.data) {
                        const items = res.data.data.data;
                        this.items = items.filter((call) => call.type === "in");
                    }
                },
            },
        },
    ],
};
</script>
