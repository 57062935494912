<template>
    <svg
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        width="12.257"
        height="14.011"
        viewBox="0 0 12.257 14.011"
    >
        <path
            d="M0 7.014V1.891A1.765 1.765 0 0 1 1.875.004q4.246-.009 8.495 0a1.764 1.764 0 0 1 1.88 1.847q.014 5.159 0 10.32a1.736 1.736 0 0 1-1.878 1.838H1.877A1.746 1.746 0 0 1 0 12.134Zm6.15-3.531c-1.647 0-3.295.009-4.945-.006-.326 0-.409.092-.409.412.015 2.708.009 5.415.012 8.123 0 .876.286 1.177 1.153 1.177q4.177.009 8.35 0c.842 0 1.134-.3 1.134-1.159 0-2.72 0-5.44.006-8.163 0-.3-.089-.393-.393-.39-1.635.012-3.27.006-4.908.006Zm-.031-.811h4.469c.947 0 .947 0 .848-.95a.883.883 0 0 0-.854-.885 3.489 3.489 0 0 0-.473-.025H2.161a4.058 4.058 0 0 0-.51.031.864.864 0 0 0-.833.9c-.009.157.009.32-.009.476-.043.347.086.464.443.461 1.618-.018 3.241-.008 4.867-.008Z"
        />
        <path
            d="M6.092 6.107H3.203c-.393 0-.556-.129-.541-.424.015-.341.252-.406.535-.406h5.847c.387 0 .553.141.541.433-.012.353-.258.4-.535.4q-1.476-.005-2.958-.003Zm.049 1.809c.962 0 1.927-.006 2.889 0 .267 0 .587.006.55.4a.607.607 0 0 1-.261.369.734.734 0 0 1-.39.058H3.336a.735.735 0 0 1-.39-.055c-.123-.08-.27-.237-.27-.363a.576.576 0 0 1 .261-.369.763.763 0 0 1 .39-.046c.936.006 1.873.006 2.814.006Zm-1.276 2.646h1.607c.283 0 .489.092.489.409s-.209.412-.489.412H3.147c-.28 0-.479-.1-.486-.415-.006-.335.218-.4.486-.4.572-.006 1.147-.006 1.718-.006Z"
        />
    </svg>
</template>
