<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="12"
        viewBox="0 0 17 12"
    >
        <g data-name="Group 8097">
            <g
                data-name="Rectangle 12"
                fill="#fff"
                stroke="#707070"
                stroke-width=".5"
            >
                <path
                    d="M0 0h17v7H0z"
                    stroke="none"
                    transform="translate(-457 -635) translate(457 635)"
                />
                <path
                    fill="none"
                    d="M.25.25h16.5v6.5H.25z"
                    transform="translate(-457 -635) translate(457 635)"
                />
            </g>
            <path
                data-name="Rectangle 13"
                fill="#e51212"
                d="M457 641h17v6h-17z"
                transform="translate(-457 -635)"
            />
        </g>
    </svg>
</template>
