<template>
    <div>
        <button
            @click="menuActive = !menuActive"
            :class="{
                'hamburger relative flex xl:hidden flex-col gap-[3px] justify-center items-center w-10 h-10 px-[9px] transform translate-x-2 z-40': true,
                'hamburger--active': menuActive,
            }"
        >
            <span
                class="w-[22px] h-0.5 bg-blue-500 rounded-sm transition-transform"
            ></span>
            <span
                class="w-[17px] h-0.5 bg-blue-500 rounded-sm ml-auto transition-all"
            ></span>
        </button>
        <div
            id="menu-mobile"
            :class="{
                'menu-mobile xl:hidden flex flex-col justify-between absolute top-0 left-0 w-full h-screen bg-white  pt-16 sm:pt-40 overflow-hidden z-30': true,
                'menu-mobile--active': menuActive,
            }"
        >
            <ul class="mx-2.5 sm:mx-12 pt-8 sm:pt-10 mb-4 overflow-y-auto">
                <li class="relative" v-for="(item, key) in items" :key="key">
                    <navigation-item :item="item"></navigation-item>
                </li>

                <div class="my-6 w-full border-t border-gray"></div>

                <li class="pl-5">
                    <button
                        @click="$emit('logout')"
                        class="text-blue-500 font-medium text-xs"
                    >
                        Wyloguj się
                    </button>
                </li>
            </ul>

            <app-footer />
        </div>
    </div>
</template>

<script>
import AppFooter from "./AppFooter";
import NavigationItem from "./NavigationItem";

export default {
    name: "MenuMobile",
    components: { NavigationItem, AppFooter },
    data: () => ({
        menuActive: false,
    }),
    props: {
        items: Array,
    },
};
</script>
