import axiosClient from "../apiClient";

export function getMessageTemplates() {
    return axiosClient.get("/clinic/message-templates");
}

export function getMessages({ patientId, filters }) {
    const params = new URLSearchParams({});

    if (filters) {
        filters.forEach(({ value, type }) => {
            params.append(`filter[${type}]`, value);
        });
    }

    return axiosClient.get(
        `/clinic/patients/${patientId}/messages?${params.toString()}`
    );
}

export function sendMessage(payload) {
    const body = {
        ...payload.data,
    };
    if (payload.data.template) {
        body.message_template_id = +payload.data.template;
    }
    delete body.template;

    return axiosClient.post(`/clinic/patients/${payload.id}/messages`, body);
}
