// TODO
import * as pagesV1 from "./pages-v1";
import * as pagesV2 from "./pages-v2";

const isV2 = window.version === 2;
const pages = isV2 ? pagesV2 : pagesV1;
export const route = (path, name, opts) => {
   return {
    path,
    name,
    component: pages[name],
    ...opts,
}};
